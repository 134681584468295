import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

@Module
export default class ClientSettingsStore extends VuexModule {
  settingsMenuVisible: boolean = false;

  @Mutation
  setSettingsMenuVisible(settingsMenuVisible: boolean) {
    this.settingsMenuVisible = settingsMenuVisible;
  }
};
