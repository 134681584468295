





































































import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter, Mutation } from "vuex-class";
import SideBar from '../components/SideBar.vue';
import Login from "@/views/Login.vue";
import PUKForm from "@/components/PUKForm.vue";
import TwoFactorForm from "@/components/TwoFactorForm.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import Message from "@/model/Message";
import SystemInformation from "@/model/SystemInformation";
import axios from "axios";
import InstallGroupwareModal from "@/components/InstallGroupwareModal.vue";
import { BModal } from "bootstrap-vue";

@Component({
  components: {
    SideBar, Login, PUKForm, TwoFactorForm, LoadingButton, InstallGroupwareModal
  }
})
export default class Dashboard extends Vue {
  @Getter isLoggedIn!: boolean;
  @Getter loggedInIsSigning!: boolean;
  @Getter getSystem!: SystemInformation | null;
  @Getter getProgressMessages!: Message[];
  @Getter needsTwoFactorConfirm!: boolean;
  @Getter needsPUKConfirm!: boolean;
  @Getter getAdminMode!: boolean;
  @Getter getCustomWallpaper!: string | null;
  @Getter getAnnouncements!: Message[];
  @Getter tours!: any[];

  @Action UNSET_ADMIN_MODE: any;
  @Action GET_ADMIN_MODE: any;
  @Action GET_USER_CONFIG_VALUES!: () => Promise<any>;
  @Action GET_SUBSCRIPTIONS!: () => Promise<any[]>;
  @Action GET_SYSTEM_INFORMATION!: () => Promise<SystemInformation>;
  @Action LOGOUT_REQUEST!: () => any;

  options: any = {};
  $refs!: {
    installGroupwareModal: InstallGroupwareModal;
    activateGroupwareModal: BModal;
  };

  marked = require('marked');

  mounted() {
    this.GET_ADMIN_MODE();

    /*TODO const tour = this.$shepherd({
      useModalOverlay: false
    });

    this.tours[0].forEach((step: any) => { tour.addStep(step); });

    tour.start();*/
  }

  get announcement(): Message | null {
    if (this.getAnnouncements && this.getAnnouncements.length > 0) {
      const announcement: Message = this.getAnnouncements[0];
      if (announcement.indicatorId === 'groupware-update-available') {
        if (this.groupwareIsEnabled) {
          return null; //Do not show anything if the groupware is enabled
        } else {
          return this.groupwareInfo;
        }
      } else {
        return announcement;
      }
    } else {
      return null;
    }
  }

  get announcementText(): string | null {
    let announcement: Message | null = this.announcement;
    if (announcement && !!announcement.text) {
      return this.marked(announcement.text);
    } else {
      return null;
    }
  }

  get announcementBgClass(): string | null {
    let announcement: Message | null = this.announcement;
    if (announcement && announcement.status === 'OK') {
      return 'bg-success';
    } else if (announcement && announcement.status === 'WARNING') {
      return 'bg-warning';
    } else if (announcement && announcement.status === 'ERROR') {
      return 'bg-danger';
    } else if (announcement && announcement.status === 'INFO') {
      return 'bg-info';
    } else {
      return null;
    }
  }

  openGroupwareActivationModal() {
    this.$refs.activateGroupwareModal.show();
  }

  get groupwareIsReady(): boolean {
    let progress: Message | undefined = this.getProgressMessages.find(m => m.indicatorId === 'installGroupware' && m.status !== 'ERROR');
    if (progress) {
      return progress.progress === 100;
    } else {
      return false;
    }
  }

  enableGroupware() {
    return new Promise((resolve, reject) => {
      const options = {
        enable: true
      };
      axios.post('/api/v1/groupwareconfig/options', options).then(() => {
        this.$snotify.info(this.$gettext('Sie werden jetzt ausgeloggt und die Seite wird neu geladen.'));
        window.setTimeout(() => {
          this.LOGOUT_REQUEST();
          window.setTimeout(() => {
            window.location.reload();
          }, 1000);
        }, 1000);
        resolve();
      }).catch((e: any) => {
        this.$snotify.error(this.$gettext('Die neue Oberfläche konnte nicht aktiviert werden.'));
        reject(e);
      });
    });
  }

  get groupwareIsEnabled(): boolean {
    const system = this.getSystem;
    return Boolean(system && system.hasOwnProperty('groupware') && system.groupware !== null && system.groupware);
  }

  get groupwareInfo(): Message | null {
    let progress: Message | undefined = this.getProgressMessages.find(m => m.indicatorId === 'installGroupware' && m.status !== 'ERROR');
    if (progress) {
      const installGroupware: Message = new Message();
      installGroupware.type = 'ANNOUNCEMENT';
      installGroupware.status = 'INFO';
      if ((progress.progress || 0) < 100) {
        installGroupware.text = progress.text || 'Uniki Collaboration Plattform wird installiert...';
      } else {
        installGroupware.text = 'Installation der Uniki Collaboration Plattform abgeschlossen. Sie können die neue Oberfläche jetzt aktivieren!';
      }
      installGroupware.id = 'installGroupware';
      return installGroupware;
    } else if (this.loggedInIsSigning && this.getSystem && !this.getSystem.groupware) {
      const groupwareAvailable: Message = new Message();
      groupwareAvailable.type = 'ANNOUNCEMENT';
      groupwareAvailable.status = 'INFO';
      groupwareAvailable.text = 'Update auf die Uniki Collaboration Plattform (ELLY 3.0) ist bereit zur Installation.';
      groupwareAvailable.id = 'groupwareAvailable';
      return groupwareAvailable;
    } else {
      return null;
    }
  }

  openInstallGroupwareModal(): Promise<any> {
    return axios.get('/api/v1/groupwareconfig/options').then((response) => {
      this.$refs.installGroupwareModal.show(response.data);
    });
  }

  get needsRAMUpgrade(): boolean {
    return Boolean(this.getSystem && (this.getSystem.totalRAMInKBytes || 0) > 0 && (this.getSystem.totalRAMInKBytes || 0) < 6000000);
  }

  get wallpaperName(): string {
    if (this.getCustomWallpaper) {
      return "/api/v1/wallpapers/current?name=" + this.getCustomWallpaper;
    } else {
      return "/assets/images/bg.jpg";
    }
  }

  exitAdminMode(): Promise<any> {
    return this.UNSET_ADMIN_MODE().then(() => {
      if (this.$route.path.startsWith("/settings")) {
        this.$router.push("/apps");
      }
    });
  }

  created() {
    this.GET_USER_CONFIG_VALUES();
    this.GET_SUBSCRIPTIONS();
    if (this.loggedInIsSigning) {
      this.GET_SYSTEM_INFORMATION();
    }
  }
};
