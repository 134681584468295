import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import Port from "@/model/Port";

const url: string = '/api/v1/ports';

@Module
export default class FirewallStore extends VuexModule {
  rules: Port[] = [];
  loading: boolean = false;

  get getFirewallRules () : Port[] {
    return this.rules;
  }

  @Mutation
  setFirewallRules (rules: Port[]) {
    this.rules = rules;
  }

  @Mutation setFirewallRulesAreLoading (loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  addOrReplaceFirewallRule (newRule: Port) {
    let oldRule = this.rules.find((rule: Port) => {
      return rule.number === newRule.number;
    });

    if (oldRule !== undefined) {
      this.rules = this.rules.map((rule: Port) => {
        if (rule.number === newRule.number) {
          return newRule;
        } else {
          return rule;
        }
      });
    } else {
      this.rules.push(newRule);
    }
  }

  @Mutation
  deleteFirewallRule (deletedPortNumber: number) {
    let toDelete = this.rules.find((rule: Port) => {
      return rule.number === deletedPortNumber;
    });

    if (toDelete !== undefined) {
      let index = this.rules.indexOf(toDelete);
      if (index > -1) {
        this.rules.splice(index, 1);
      }
    }
  }

  @Action({ commit: 'setFirewallRules', rawError: true })
  GET_FIREWALL_RULES () {
    this.context.commit("setFirewallRulesAreLoading", true);
    return axios.get(url).then((response) => {
      this.context.commit("setFirewallRulesAreLoading", false);
      return response.data ? response.data.map((port: Port) => Object.assign(new Port(), port)) : [];
    });
  }

  @Action({ commit: 'addOrReplaceFirewallRule', rawError: true })
  CREATE_FIREWALL_RULE (rule: Port) {
    return axios.post(url, rule).then((response) => {
      //Add to state immediately and dispatch update from backend to ensure integrity
      this.context.dispatch('GET_FIREWALL_RULES');
      return response.data;
    });
  }

  @Action({ commit: 'addOrReplaceFirewallRule', rawError: true })
  REPLACE_FIREWALL_RULE (rule: Port) {
    return axios.put(url, rule).then((response) => {
      //Add to state immediately and dispatch update from backend to ensure integrity
      this.context.dispatch('GET_FIREWALL_RULES');
      return response.data;
    });
  }

  @Action({ commit: 'deleteFirewallRule', rawError: true })
  DELETE_FIREWALL_RULE (portNumber: number) {
    return axios.delete(url + '/' + portNumber).then((response) => {
      //Add to state immediately and dispatch update from backend to ensure integrity
      this.context.dispatch('GET_FIREWALL_RULES');
      return portNumber;
    });
  }
};
