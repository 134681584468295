import StoreApp from './StoreApp';
import AppStats from './AppStats';

export default class App {
  instanceId!: string | null;
  storeId!: string | null;
  host!: string | null;
  userGroup!: string | null;
  title!: string | null;
  description!: string | null;
  icon!: string | null;
  status!: string | null;
  progress!: number | null;
  databaseNames!: string[] | null;
  alternativeDomains!: string[] | null;
  ports!: any | null;
  appVersion!: string | null;
  storeApp!: StoreApp | null;
  stats!: AppStats | null;
  isCustom!: boolean | null;
  isExtern!: boolean | null;

  constructor() {
    this.instanceId = null;
    this.storeId = null;
    this.host = null;
    this.userGroup = null;
    this.title = null;
    this.description = null;
    this.icon = null;
    this.status = null;
    this.progress = null;
    this.databaseNames = null;
    this.alternativeDomains = null;
    this.ports = null;
    this.appVersion = null;
    this.storeApp = null;
    this.stats = null;
    this.isCustom = null;
    this.isExtern = null;
  }

}
