





































import { Vue, Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import LoadingButton from "@/components/LoadingButton.vue";

@Component({
  components: {
    LoadingButton
  }
})
export default class Maintenance extends Vue {
  @Getter getMaintenanceMode!: boolean;
  @Getter getMaintenanceLog!: string;
  @Action GET_MAINTENANCE_MODE!: () => Promise<any>;
  @Action ENABLE_MAINTENANCE_MODE!: () => Promise<any>;
  @Action DISABLE_MAINTENANCE_MODE!: () => Promise<any>;

  get maintenanceButtonText(): string {
    if (this.getMaintenanceMode) {
      return this.$pgettext('button', 'Disable access for Uniki');
    } else {
      return this.$pgettext('button', 'Enable access for Uniki');
    }
  }

  toggleMaintenanceMode (): Promise<any> {
    if (this.getMaintenanceMode) {
      return this.DISABLE_MAINTENANCE_MODE().then(() => {
          this.$snotify.success(this.$pgettext("notification", "Maintenance mode disabled"));
      }).catch((error) => {
          this.$snotify.error(error.response.data.message,
            this.$pgettext("notification", "Could not disable maintenance mode. An error occurred."));
        }).finally(() => {
        this.GET_MAINTENANCE_MODE();
      });
    } else {
      return this.ENABLE_MAINTENANCE_MODE().then(() => {
          this.$snotify.success(this.$pgettext("notification", "Maintenance mode enabled"));
        }).catch((error) => {
          this.$snotify.error(error.response.data.message,
            this.$pgettext("notification", "Could not enable maintenance mode. An error occurred."));
        }).finally(() => {
        this.GET_MAINTENANCE_MODE();
      });
    }
  }

  created() {
    this.GET_MAINTENANCE_MODE().catch((error) => {
      this.$snotify.error(error.response.data.message,
        this.$pgettext("notification", "Could not loead maintenance mode state. An error occurred."));
    });
  }
}
