





















import { Vue, Component, Prop } from 'vue-property-decorator';
import USBStoragePartition from "@/model/backup/USBStoragePartition";
import BackupUtil from "@/util/BackupUtil";
import USBStorageDrive from "@/model/backup/USBStorageDrive";

@Component
export default class USBStorageDeviceGridItem extends Vue {
  @Prop(Object) usbStorageDrive!: USBStorageDrive;

  //TODO: Display (size of) disk and partitions separately or display
  get overAllStorageInGB(): number {
    return BackupUtil.overAllStorageInGB(this.usbStorageDrive);
  }

  get isUsed(): boolean {
    return BackupUtil.isUsedForBackup(this.usbStorageDrive);
  }

  get getUsedText(): string {
    if (BackupUtil.isUsedForBackup(this.usbStorageDrive)) {
      return this.getUsedSpaceText;
    } else if (BackupUtil.canBeUsedForBackups(this.usbStorageDrive)) {
      return this.overAllStorageInGB + "GB, " + this.$gettext('but is not used for backups');
    } else {
      return this.$gettext('Not suitable for backups.');
    }
  }

  get getUsedPercent(): number {
    return 100 - BackupUtil.getAvailableStoragePercent(this.usbStorageDrive);
  }

  get getUsedSpaceText(): string {
    const used : number | null = BackupUtil.getUsedStorageInGB(this.usbStorageDrive);
    const overall: number = BackupUtil.overAllStorageInGB(this.usbStorageDrive);
    if (used === null) {
      return this.$gettext("Could not determine used space for device");
    } else {
      return used + " GB " + this.$gettext("of") + " " + overall + " GB " + "(" + this.getUsedPercent + "%) " + this.$gettext("used for backups");
    }
  }

  get getStatusCSS(): string {
    let available: number = BackupUtil.getAvailableStoragePercent(this.usbStorageDrive);
    if (available >= 50) {
      return 'success';
    } else if (available < 50 && available >= 20) {
      return 'warning';
    } else if (available < 20) {
      return 'danger';
    } else {
      return '';
    }
  }

  get getModelName(): string {
    let name = this.usbStorageDrive.model;
    if (!name) {
      name = this.$gettext('USB disk');
    }
    return name;
  }
};
