import { Module, VuexModule, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import FeedbackModel from "@/model/FeedbackModel";

const url: string = '/api/v1/feedback';

@Module
export default class FeedbackStore extends VuexModule {

  @Action({ rawError: true })
  SEND_FEEDBACK(feedback: FeedbackModel) {
    return axios.post(url, feedback);
  }
}
