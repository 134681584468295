






























































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter, Mutation } from "vuex-class";
import LoadingButton from "../components/LoadingButton.vue";
import SystemInformation from '../model/SystemInformation';
import Backup from "@/model/backup/Backup";
import BackupGridItem from "./BackupGridItem.vue";
import dayjs from "@/util/dayjs";

@Component({
  components: { LoadingButton, BackupGridItem }
})
export default class Repair extends Vue {
  @Action GET_SYSTEM_INFORMATION!: () => Promise<any>
  @Action REPAIR_SYSTEM!: (request: any) => Promise<any>
  @Action GET_BACKUPS!: () => Promise<Backup[]>;
  @Getter getSystem!: SystemInformation;
  @Getter getBackups!: Backup[];
  @Getter loggedInIsAdmin!: boolean;

  loading: boolean = true;

  get sortedBackups (): Backup[] {
    const backups: Backup[] = this.getBackups;
    let sorted = backups.slice(0); //Fast copy array
    sorted.sort((a: Backup, b: Backup) => {
      if (a.date && b.date) { //dayjs function expects a non-null string
        const dateA = dayjs(a.date).valueOf();
        const dateB = dayjs(b.date).valueOf();
        return dateB - dateA;
      } else {
        return -1;
      }
    });
    return sorted;
  };

  chartOptions: any = {
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: '16px',
            color: undefined,
            offsetY: 120
          },
          value: {
            offsetY: 76,
            fontSize: '22px',
            color: undefined,
            formatter: function (val: any) {
              return val + "%";
            }
          }
        }
      }
    },
    stroke: {
      dashArray: 4
    }
  };

  get cpuLoadCurrent (): number[] {
    let system: SystemInformation | null = this.getSystem;
    if (system != null && system.cpuLoadCurrent != null) {
      return [ system.cpuLoadCurrent ];
    } else {
      return [ 0 ];
    }
  }

  get cpuLoadAverage (): number[] {
    let system = this.getSystem;
    if (system != null && system.cpuLoadAverage != null) {
      return [ system.cpuLoadAverage ];
    } else {
      return [ 0 ];
    }
  }

  get usedRam (): number[] {
    let system = this.getSystem;
    if (system != null && system.freeRAMInKBytes != null && system.totalRAMInKBytes != null) {
      return [ 100 - Math.round(100 * system.freeRAMInKBytes / system.totalRAMInKBytes) ];
    } else {
      return [ 0 ];
    }
  }

  get usedSystemdisk (): number[] {
    let system = this.getSystem;
    if (system != null) {
      return [ 0 ];
      //return [ 100 - Math.round(100 * system.systemFreeSpaceInKBytes / system.systemTotalSpaceInKBytes) ];
    } else {
      return [ 0 ];
    }
  }

  get usedDatadisk (): number[] {
    let system = this.getSystem;
    if (system != null && system.freeSpaceInKBytes != null && system.totalSpaceInKBytes != null) {
      return [ 100 - Math.round(100 * system.freeSpaceInKBytes / system.totalSpaceInKBytes) ];
    } else {
      return [ 0 ];
    }
  }

  restartDevice(): Promise<any> {
    return this.sendRequest({ action: "RESTART_DEVICE" });
  }

  restartAdminpanel(): Promise<any> {
    return this.sendRequest({ action: "RESTART_ADMINPANEL" });
  }

  restartMySql(): Promise<any> {
    return this.sendRequest({ action: "RESTART_MYSQL" });
  }

  reinstallMySql(): Promise<any> {
    return this.sendRequest({ action: "REINSTALL_MYSQL" });
  }

  restartLdap(): Promise<any> {
    return this.sendRequest({ action: "RESTART_LDAP" });
  }

  reinstallLdap(): Promise<any> {
    return this.sendRequest({ action: "REINSTALL_LDAP" });
  }

  sendRequest(request: { action: string }): Promise<any> {
    return this.REPAIR_SYSTEM(request).then(() => {
      this.$snotify.success(this.$pgettext("notification", "Request completed"));
    }).catch(error => {
      this.$snotify.error(this.$pgettext("server_message", error.response.data.message),
          this.$pgettext("notification", "Could not send request"));
    }).finally(() => {
      this.refreshInfo();
    });
  }

  async refreshInfo(): Promise<any> {
    this.loading = true;
    await this.GET_SYSTEM_INFORMATION().then(() => {
      this.loading = false;
    });
    if (this.loggedInIsAdmin) {
      await this.GET_BACKUPS();
    }
  }

  mounted(): void {
    this.refreshInfo();
  }
};
