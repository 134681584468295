



































import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import { Action, Getter } from "vuex-class";
import Group from "@/model/Group";
import LoadingImage from "@/components/LoadingImage.vue";
import { BModal } from "bootstrap-vue";
import User from "@/model/User";
import SelectableList from "@/components/SelectableList.vue";
import UpgradeAppWizard from "@/components/UpgradeAppWizard.vue";

@Component({
  components: {
    UpgradeAppWizard,
    AnimatedInput,
    LoadingButton,
    LoadingImage,
    SelectableList
  }
})
export default class SimpleGroupEditModal extends Vue {

  @Getter getGroups!: Group[];
  @Getter getUsers!: User[];

  @Action UPDATE_GROUP!: ({ cn, group }: { cn: string, group: Group }) => Promise<Group>;
  @Action CREATE_GROUP!: (group: Group) => Promise<Group>;

  @Prop(Boolean)
  isLoading!: boolean;

  @Prop(Object)
  group!: Group;

  @Prop(String)
  title!: string;

  systemGroupDescriptions = [
    {
      dn: 'cn=admin,ou=Groups,dc=uniki,dc=de',
      cn: "admin",
      name: this.$gettext("Administrator"),
      description: this.$gettext("May configure the system, install and uninstall apps.")
    },
    {
      dn: 'cn=signing,ou=Groups,dc=uniki,dc=de',
      cn: "signing",
      name: this.$gettext("Manager"),
      description: this.$gettext("May obtain subscriptions for services, install apps and edit app groups.")
    }
  ];

  editedUsers: string[] = (this.group && this.group.uniqueMember) ? [...this.group.uniqueMember] : [];

  $refs!: {
    editModal: BModal;
  };

  get name() {
    if (this.group) {
      let description = this.systemGroupDescriptions.find(description => Boolean(this.group && this.group.cn === description.cn));
      return description ? description.name : this.group.description ? this.group.description : this.group.cn;
    } else {
      return '';
    }
  }

  get uniqueMember() {
    return (this.group && this.group.uniqueMember) ? this.group.uniqueMember : [];
  }

  show() {
    this.$refs.editModal.show();
  }

  isNewAdmin(user: User): boolean {
    return Boolean(user.dn && this.group && this.group.cn === 'admin' && this.editedUsers.indexOf(user.dn) >= 0 && this.uniqueMember.indexOf(user.dn) < 0);
  }

  saveGroupToServer(): Promise<void> {
    if (this.group && this.group.cn) {
      //We only update members, so payload is simple:
      let data = { ...this.group };
      data.uniqueMember = this.editedUsers;
      if (this.group.dn) {
        return this.UPDATE_GROUP({ cn: this.group.cn, group: data }).then(() => {
          this.$snotify.success(this.$pgettext("notification", "Group updated."));
          this.$refs.editModal.hide();
        }).catch(error => {
          this.$snotify.error(error.response.data.message, this.$pgettext("notification", "Could not update group"));
        });
      } else {
        return this.CREATE_GROUP(data).then(() => {
          this.$snotify.success(this.$pgettext("notification", "Group created."));
          this.$refs.editModal.hide();
        }).catch(error => {
          this.$snotify.error(error.response.data.message, this.$pgettext("notification", "Could not create group"));
        });
      }
    } else {
      this.$snotify.error(this.$pgettext("notification", "Could not load group."));
      return Promise.resolve();
    }
  }

  mounted() {
    //Must be set after props are set
    this.editedUsers = (this.group && this.group.uniqueMember) ? [ ...this.group.uniqueMember ] : [];
  }
}
