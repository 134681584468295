import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import SeafileLibrary from "@/model/SeafileLibrary";
import SyncJob from "@/model/SyncJob";
import App from "@/model/App";

const url: string = '/api/v1/seafile';

@Module
export default class SeafileStore extends VuexModule {
  libraries: SeafileLibrary[] = [];
  syncJobs: SyncJob[] = [];
  librariesLoading: boolean = false;
  librariesError: boolean = false;
  syncJobsLoading: boolean = false;
  syncJobsError: boolean = false;

  get getLibraries () : SeafileLibrary[] {
    return this.libraries;
  }

  get getSyncJobs () : SyncJob[] {
    return this.syncJobs;
  }

  @Mutation
  setLibraries (libraries: SeafileLibrary[]) {
    this.libraries = libraries;
  }

  @Mutation
  setSyncJobs (syncJobs: SyncJob[]) {
    this.syncJobs = syncJobs;
  }

  @Mutation
  addSyncJob (syncJob: SyncJob) {
    this.syncJobs.push(syncJob);
  }

  @Mutation
  setLibrariesState ({ loading, error }: { loading: boolean, error: boolean }) {
    this.librariesLoading = loading;
    this.librariesError = error;
  }

  @Mutation
  setSyncJobState ({ loading, error }: { loading: boolean, error: boolean }) {
    this.syncJobsLoading = loading;
    this.syncJobsError = error;
  }

  @Mutation
  removeSyncJob (id: number) {
    let toDelete = this.syncJobs.find((syncJob: SyncJob) => {
      return syncJob.id === id;
    });

    if (toDelete !== undefined) {
      let index = this.syncJobs.indexOf(toDelete);
      if (index > -1) {
        this.syncJobs.splice(index, 1);
      }
    }
  }

  @Action({ commit: 'setLibraries', rawError: true })
  GET_SEAFILE_LIBRARIES() {
    this.context.commit("setLibrariesState", { loading: true, error: false });
    return axios.get(url + '/libraries').then((response) => {
      this.context.commit("setLibrariesState", { loading: false, error: false });
      return response.data ? response.data.map((library: SeafileLibrary) => Object.assign(new SeafileLibrary(), library)) : [];
    }).catch(() => {
      this.context.commit("setLibrariesState", { loading: false, error: true });
    });
  }

  @Action({ rawError: true })
  GET_REMOTE_LIBRARIES({ remoteUrl, user, password }: { remoteUrl: string, user: string, password: string }) {
    const params = 'url=' + encodeURIComponent(remoteUrl) + '&user=' + encodeURIComponent(user) + '&password=' + encodeURIComponent(password);
    return axios.get(url + '/libraries?' + params).then((response) => {
      return response.data ? response.data.map((library: SeafileLibrary) => Object.assign(new SeafileLibrary(), library)) : [];
    });
  }

  @Action({ commit: 'setSyncJobs', rawError: true })
  GET_SYNC_JOBS() {
    this.context.commit("setSyncJobState", { loading: true, error: false });
    return axios.get(url + '/syncjobs').then((response) => {
      this.context.commit("setSyncJobState", { loading: false, error: false });
      return response.data ? response.data.map((syncJob: SyncJob) => Object.assign(new SyncJob(), syncJob)) : [];
    }).catch(() => {
      this.context.commit("setSyncJobState", { loading: false, error: true });
    });
  }

  @Action({ commit: 'addSyncJob', rawError: true })
  ADD_SYNC_JOB(syncJob: SyncJob) {
    return axios.post(url + '/syncjobs', syncJob).then((response) => {
      return Object.assign(new SyncJob(), response.data);
    });
  }

  @Action({ commit: 'removeSyncJob', rawError: true })
  DELETE_SYNC_JOB(id: number) {
    return axios.delete(url + '/syncjobs/' + id).then(() => { return id; });
  }
}
