








































import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";
import LoadingButton from "../components/LoadingButton.vue";
import FeedbackModel from "../model/FeedbackModel";
import AnimatedInput from "@/components/AnimatedInput.vue";

//const getJquery = () => import("jquery");

@Component({
  components: {
    AnimatedInput,
    LoadingButton
  }
})
export default class Feedback extends Vue {
  @Action SEND_FEEDBACK!: (feedback: FeedbackModel) => Promise<any>;
  message: string = "";
  subject: string = "";
  placeHolder: string = this.$pgettext("placeholder", "Your feedback");

  sendFeedback () {
    if (this.message) {
      let feedback = new FeedbackModel();
      feedback.message = this.message;
      feedback.subject = this.subject;
      return this.SEND_FEEDBACK(feedback).then(() => {
        this.$snotify.success(this.$pgettext("notification", "Feedback send"));
      },
        error => {
          this.$snotify.error(error.response.data.message,
            this.$pgettext("notification", "Could not send feedback"));
        }
      );
    } else {
      this.$snotify.error(this.$pgettext("notification", "Feedback cannot be empty"));
    }
  }

  mounted() {

    //Basically this needs to be done to use the zammad js. But doesn't work everytime...
    /*
    //Load Jquery:
    getJquery().then((jquery: any) => {
      //Set Jquery up:
      window.$ = jquery.default; // eslint-disable-line
      window.jQuery = jquery.default; // eslint-disable-line

      //Load Zammad form script:
      let zammadScript = document.createElement('script');
      zammadScript.setAttribute('src', 'https://zammad.uniki.de/assets/form/form.js');
      zammadScript.setAttribute('id', 'zammad_form_script');
      document.head.appendChild(zammadScript);

      $('#zammad_feedback_container').ZammadForm({
        messageTitle: 'Feedback Formular',
        messageSubmit: 'Übermitteln',
        messageThankYou: 'Vielen Dank für Ihre Anfrage (#%s). Wir melden uns umgehend!'
      });
    }); */
  }
};
