
















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";
import PasswordGridItem from "../components/PasswordGridItem.vue";
import Password from '../model/Password';

@Component({
  components: { PasswordGridItem }
})
export default class Passwords extends Vue {
  @Getter getPasswords!: Password[];
  @Action GET_PASSWORDS!: () => Promise<any>;

  beforeMount() {
    this.GET_PASSWORDS();
  }
};
