import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import Message from "@/model/Message";
import Vue from 'vue';
import progressTypeToAppStatusMap from "@/util/progressTypeToAppStatusMap";

const url: string = '/api/v1/messages';

@Module
export default class MessageStore extends VuexModule {
  messages: Message[] = [];
  progressMessages: Message[] = [];
  notifications: Message[] = [];
  announcements: Message[] = [];
  displayedNotifications: Message[] = [];
  enabled: boolean = false;

  get getMessages () : Message[] {
    return this.messages;
  }

  get getProgressMessages () : Message[] {
    return this.progressMessages;
  }

  get getNotifications () : Message[] {
    return this.notifications;
  }

  get getAnnouncements () : Message[] {
    return this.announcements;
  }

  get getDisplayedNotifications () : Message[] {
    return this.notifications;
  }

  get getCurrentlyRunningBackups (): { text: string, progress: number }[] {
    let result = [];

    for (let msg of this.progressMessages) {
      if (msg.progressType === "CREATING_BACKUP") {
        result.push({
          progress: msg.progress || 0,
          text: msg.text || ""
        });
      }
    }

    return result;
  }

  get getCurrentlyRunningReplicas (): { id: string, progress: number }[] {
    let result = [];

    for (let msg of this.progressMessages) {
      if (msg.progressType === "REPLICATION") {
        result.push({
          progress: msg.progress || 0,
          id: msg.indicatorId || ""
        });
      }
    }

    return result;
  }

  get getCurrentlyRunningImports (): { text: string, progress: number }[] {
    let result = null;

    for (let msg of this.progressMessages) {
      if (msg.progressType === "DATA_IMPORT" && (msg.progress || 0) < 100) {
        if (!result) {
          result = msg.text;
        } else {
          result += ', ' + msg.text;
        }
      }
    }

    return result ? [ { text: result, progress: 0 } ] : [];
  }

  get isBackupCurrentlyRunning() : (backupId: string) => { result: boolean, progress: number } {
    return (backupId) => {

      let result = {
        progress: 0,
        result: false
      };

      for (let msg of this.progressMessages) {
        if (msg.indicatorId === backupId) {
          result.progress = msg.progress || 0;
          result.result = true;
        }
      }

      return result;
    };
  }

  @Mutation
  addDisplayedNotification(newMessage: Message) {
    let oldMessage = this.displayedNotifications.find((message: Message) => {
      return newMessage.id === message.id;
    });

    if (!oldMessage) {
      this.displayedNotifications.push(newMessage);
    }
  }

  @Mutation
  setMessages (messages: Message[]) {
    Vue.set(this, "messages", messages);
  }

  @Mutation
  setProgressMessages (progressMessages: Message[]) {
    Vue.set(this, "progressMessages", progressMessages);
  }

  @Mutation
  setNotifications (notifications: Message[]) {
    Vue.set(this, "notifications", notifications);
  }

  @Mutation
  setAnnouncements (announcements: Message[]) {
    Vue.set(this, "announcements", announcements);
  }

  @Mutation
  addMessage (message: Message) {
    this.messages.push(message);
  }

  @Mutation
  deleteMessage (id: string) {
    let toDelete = this.messages.find((message: Message) => {
      return message.id === id;
    });

    if (toDelete !== undefined) {
      let index = this.messages.indexOf(toDelete);
      if (index > -1) {
        this.messages.splice(index, 1);
      }
    }
  }

  @Mutation
  addOrReplaceMessage (newMessage: Message) {
    let oldRule = this.messages.find((value: Message) => {
      return value.id === newMessage.id;
    });

    if (oldRule !== undefined) {
      this.messages = this.messages.map((value: Message) => {
        if (value.id === newMessage.id) {
          return newMessage;
        } else {
          return value;
        }
      });
    } else {
      this.messages.push(newMessage);
    }
  }

  @Mutation
  setMessageRefreshEnabled (enabled: boolean) {
    this.enabled = enabled;
  }

  @Action({ rawError: true })
  GET_MESSAGES() {
    if (this.enabled) {
      return axios.get(url).then((response) => {
        let messages: Message[] = response.data ? response.data.map((message: Message) => Object.assign(new Message(), message)) : [];
        this.context.dispatch('PROCESS_MESSAGES', messages); // After getting, dispatch processing!
      }).catch(() => {
        return this.messages; //Do not change anything, if something bad happens
      });
    } else {
      return Promise.resolve(this.messages);
    }
  }

  @Action({ rawError: true })
  GET_FILTERED_MESSAGES(type: string, progressType: string, indicatorId: string) {
    let thisUrl = url;
    if (type) {
      thisUrl += '?type=' + encodeURIComponent(type);
    }
    if (progressType) {
      thisUrl += (type ? '&' : '?') + 'progressType=' + encodeURIComponent(progressType);
    }
    if (indicatorId) {
      thisUrl += ((type || progressType) ? '&' : '?') + 'indicatorId=' + encodeURIComponent(indicatorId);
    }

    return axios.get(thisUrl).then((response) => {
      return response.data.map((message: Message) => Object.assign(new Message(), message));
    }); //TODO: Commit to state?
  }

  @Action({ commit: 'addOrReplaceMessage', rawError: true })
  GET_MESSAGE(id: string) {
    const encodedId = encodeURIComponent(id);
    return axios.get(url + '/' + encodedId).then((response) => {
      return Object.assign(new Message(), response.data);
    });
  }

  @Action({ commit: 'addMessage', rawError: true })
  POST_MESSAGE(message: Message) {
    return axios.post(url, message).then((response) => {
      this.context.dispatch('GET_MESSAGES');
      return Object.assign(new Message(), response.data);
    });
  }

  @Action({ commit: 'deleteMessage', rawError: true })
  DELETE_MESSAGE(id: string) {
    const encodedId = encodeURIComponent(id);
    return axios.delete(url + '/' + encodedId).then((response) => {
      this.context.dispatch('GET_MESSAGES');
      return id;
    });
  }

  //This Action takes care of all message processing. it is instant and only triggers mutations.
  @Action({ rawError: true })
  PROCESS_MESSAGES(messages: Message[]) {
    let progressMessages: Message[] = [];
    let notifications: Message[] = [];
    let announcements: Message[] = [];

    for (let message of messages) {
      if (message.type === "PROGRESS") {
        progressMessages.push(message);
      } else if (message.type === "NOTIFICATION") {
        notifications.push(message);
      } else if (message.type === "ANNOUNCEMENT") {
        announcements.push(message);
      }
    }

    const sortFunction: (a: Message, b: Message) => number = (a: Message, b: Message) => {
      return (b.messageTime || '').localeCompare((a.messageTime || ''));
    };

    //Insert all messages into the store
    this.context.commit("setMessages", messages.sort(sortFunction));
    this.context.commit("setProgressMessages", progressMessages.sort(sortFunction));
    this.context.commit("setNotifications", notifications.sort(sortFunction));
    this.context.commit("setAnnouncements", announcements.sort(sortFunction));

    //Get App progress messages:
    let appProgressMessages: Message[] = [];
    let installedInstances: string[] = this.context.getters.getInstalledInstanceIds;
    let hasImportJob: boolean = false;
    progressMessages.forEach((msg) => {
      if (Object.keys(progressTypeToAppStatusMap).includes(msg.progressType)) {
        appProgressMessages.push(msg);
      } else if (msg.progressType === 'DATA_IMPORT') {
        hasImportJob = true;
      }
    });

    if (hasImportJob) {
      this.context.dispatch("GET_IMPORT_JOBS");
    }

    //Now, update apps of process messages:
    //TODO: Process error (display as progress or reload apps?)
    let hasUnknownApp: boolean = !!appProgressMessages.find((msg: Message) => {
      return Boolean(msg.progressType !== 'DOCKER_BUILD' && msg.indicatorId && !installedInstances.includes(msg.indicatorId));
    });
    if (hasUnknownApp) {
      this.context.dispatch("GET_INSTALLED_APPS").finally(() => {
        this.context.dispatch('PROCESS_APP_MESSAGES', { appProgressMessages, installedInstances });
      });
    } else {
      this.context.dispatch('PROCESS_APP_MESSAGES', { appProgressMessages, installedInstances });
    }
  }

  @Action({ rawError: true })
  PROCESS_APP_MESSAGES({ appProgressMessages, installedInstances }: { appProgressMessages: Message[], installedInstances: string[] }) {
    appProgressMessages.forEach((progressMessage /*, index, array */) => {
      if (progressMessage.indicatorId && progressMessage.progressType === 'DOCKER_BUILD') {
        this.context.commit("setAppStatusByStoreIdAndVersion", {
          storeId: progressMessage.indicatorId.split(':')[0],
          version: progressMessage.indicatorId.split(':')[1],
          progress: progressMessage.progress || 100,
          progressType: progressMessage.progressType
        });
      } else if (progressMessage.indicatorId && installedInstances.includes(progressMessage.indicatorId)) {
        this.context.commit("setAppStatus", {
          appId: progressMessage.indicatorId,
          progress: progressMessage.progress || 100,
          progressType: progressMessage.progressType
        });
      }
    });
  }
}
