import AttributeMapping from '@/model/AttributeMapping';

export default class LDAPConfig {
  enabled!: boolean | null;
  ssl!: boolean | null;
  startTls!: boolean | null;
  sslProtocol!: string | null;
  host!: string | null;
  port!: number | null;
  searchDn!: string | null;
  searchPassword!: string | null;
  userFilter!: string | null;
  userBaseDn!: string | null;
  groupFilter!: string | null;
  groupBaseDn!: string | null;
  getMembershipFromUser!: boolean | null;
  adminFilter!: string | null;
  adminAttribute!: string | null;
  membershipAttribute!: string | null;
  onlySyncUsersWithGroups!: boolean | null;
  syncExternalMailToInternalMail!: boolean | null;
  groupMapping!: AttributeMapping[] | null;
  userAttributeMapping!: AttributeMapping[] | null;
  groupAttributeMapping!: AttributeMapping[] | null;

  constructor() {
    this.enabled = null;
    this.ssl = null;
    this.startTls = null;
    this.sslProtocol = null;
    this.host = null;
    this.port = null;
    this.searchDn = null;
    this.searchPassword = null;
    this.userFilter = null;
    this.userBaseDn = null;
    this.groupFilter = null;
    this.groupBaseDn = null;
    this.getMembershipFromUser = null;
    this.adminFilter = null;
    this.adminAttribute = null;
    this.membershipAttribute = null;
    this.onlySyncUsersWithGroups = null;
    this.syncExternalMailToInternalMail = null;
    this.groupMapping = null;
    this.userAttributeMapping = null;
    this.groupAttributeMapping = null;
  }
}
