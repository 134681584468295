



















































import { Vue, Component, Prop } from 'vue-property-decorator';
import User from "../model/User";
import { Action, Getter } from "vuex-class";
import LoadingImage from "../components/LoadingImage.vue";
import LoadingButton from "@/components/LoadingButton.vue";

@Component({
  components: { LoadingImage, LoadingButton }
})
export default class UserGridItem extends Vue {
  @Prop(Object) user!: User;
  @Getter loggedInUserId!: String | boolean;
  @Getter onlyOneAdminLeft!: boolean;
  @Action DELETE_USER!: (uid: string) => Promise<any>;
  userRoles = [
    { value: "user", label: this.$gettext("Normaler Benutzer") },
    { value: "admin", label: this.$gettext("Administrator") }
  ];

  get userRole () {
    const role = this.userRoles.find(role => role.value === this.user.posixGroupName);
    return role ? role.label : "";
  }

  goToUserEdit (uid: string) {
    this.$router.push('/settings/users/' + uid);
  }

  canDelete (user: User) {
    if (this.loggedInUserId !== user.uid) {
      if (!this.onlyOneAdminLeft || user.posixGroupName !== "admin") {
        return true;
      }
    }
    return false;
  }

  async deleteUser () {
    if (!this.user || !this.user.uid) return;
    return this.DELETE_USER(this.user.uid).then(() => {
      this.$snotify.success(this.$pgettext("notification", "User deleted."));
    }).catch((error) => {
      this.$snotify.error(error.response.data.message,
        this.$pgettext("notification", "Could not delete user."));
    }).finally(() => {
      this.$bvModal.hide('deleteUserModal-' + this.user.uid);
    });
  }
};
