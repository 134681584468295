







































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from "vuex-class";
import Backup from "@/model/backup/Backup";
import LoadingButton from "@/components/LoadingButton.vue";
import dayjs from "@/util/dayjs";
import BackupUtil from "@/util/BackupUtil";
import USBStorageDrive from "@/model/backup/USBStorageDrive";
import Formats from "@/util/formats";

@Component({
  components: { LoadingButton }
})
export default class BackupGridItem extends Vue {
  @Prop(Object) backup!: Backup;
  @Prop(Number) index!: number;
  @Prop(Array) backupDevices!: USBStorageDrive[];

  @Action RESTORE_BACKUP!: (backupId: string) => Promise<any>;
  @Action DELETE_BACKUP!: (backupId: string) => Promise<any>;
  @Action GET_BACKUPS!: () => Promise<Backup[]>;

  get availableText (): string {
    return this.backup.available ? this.$gettext('Available') : this.$gettext('Unavailable');
  }

  get getTypeIfNotExternalDisk (): string | null {
    let deviceId: string = this.backup.deviceId || '';
    if (deviceId === "snapper" || deviceId === "uniki-internal-snapshot") {
      return this.$gettext('Snapshot');
    } else if (deviceId.startsWith("cloud://")) {
      return this.$gettext('Cloud-Backup');
    } else if (deviceId.startsWith("smb://") || deviceId.startsWith("sftp://") || deviceId.startsWith("ftp://")) {
      return this.$gettext('Network Backup');
    } else {
      return null;
    }
  }

  get getDeviceName(): string | null{
    if (this.backup.deviceId) {
      return this.$gettext(BackupUtil.getDeviceName(this.backup.deviceId, this.backupDevices, this));
    } else {
      return null;
    }
  }

  get icon (): string {
    let deviceId: string = this.backup.deviceId || '';
    if (deviceId === "snapper" || deviceId === "uniki-internal-snapshot") {
      return 'fa-camera';
    } else if (deviceId.startsWith("cloud://")) {
      return 'fa-cloud';
    } else if (deviceId.startsWith("smb://") || deviceId.startsWith("sftp://") || deviceId.startsWith("ftp://")) {
      return 'fa-ethernet';
    } else {
      return 'fa-hdd';
    }
  }

  get getTimeAsInteger (): number {
    let m = dayjs(this.backup.date || 0); //Should we deal with non-existing date differently?
    return m.valueOf();
  };

  get getTime (): string {
    let m = dayjs(this.backup.date || 0);
    return m.format("HH:mm");
  };

  get getSize (): string | null {
    if (this.backup.sizeInKBytes) {
      return Formats.formatBytes(this.backup.sizeInKBytes * 1000);
    } else {
      return null;
    }
  };

  get getDate (): string {
    let m = dayjs(this.backup.date || 0);
    return m.format('dddd, DD MMMM YYYY');
  };

  restoreBackup(): Promise<any> {
    return this.RESTORE_BACKUP(this.backup.id).catch((error) => {
      this.$snotify.error(error.response.data.message);
    }).finally(() => {
      this.$bvModal.hide('restoreBackupModal-' + this.backup.id);
    });
  }

  deleteBackup(): Promise<any> {
    return this.DELETE_BACKUP(this.backup.id).then(() => {
      this.GET_BACKUPS();
    }).catch((error) => {
      this.$snotify.error(error.response.data.message);
    }).finally(() => {
      this.$bvModal.hide('deleteBackupModal-' + this.backup.id);
    });
  }
};
