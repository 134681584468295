import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import Product from "@/model/invoice/Product";

const url: string = '/api/v1/products';

@Module
export default class ProductStore extends VuexModule {
  products: Product[] = [];
  loading: boolean = false;

  get getProducts () : Product[] {
    return this.products;
  }

  @Mutation
  setProducts (products: Product[]) {
    this.products = products;
  }

  @Mutation
  setProductsAreLoading(loading: boolean) {
    this.loading = loading;
  }

  @Action({ commit: 'setProducts', rawError: true })
  GET_PRODUCTS() {
    this.context.commit("setProductsAreLoading", true);
    return axios.get(url).then((response) => {
      this.context.commit("setProductsAreLoading", false);
      return response.data ? response.data.map((p: Product) => Object.assign(new Product(), p)) : [];
    });
  }
};
