export default class LibraryProgress {
  name!: string | null;
  repoId!: string | null;
  state!: string | null;
  filesTotal!: number | null;
  filesDone!: number | null;
  progress!: number | null;
  speedInKBytePerSec!: number | null;
  errors!: string[] | null;

  constructor() {
    this.name = null;
    this.repoId = null;
    this.state = null;
    this.filesTotal = null;
    this.filesDone = null;
    this.progress = null;
    this.speedInKBytePerSec = null;
    this.errors = null;
  }
}
