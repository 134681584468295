































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter, Mutation } from "vuex-class";
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import User from '../model/User';

@Component({
  components: {}
})
export default class ConfirmResetPin extends Vue {
  @Getter getStatus!: any | null;

  get loading() {
    return !this.getStatus;
  }

  get resetting() {
    return this.getStatus && this.getStatus.status === 'RESETTING';
  }

  get offline() {
    return this.getStatus && this.getStatus.status === 'OFFLINE';
  }

  get starting() {
    return this.getStatus && this.getStatus.status === 'STARTING';
  }

  get updating() {
    return this.getStatus && this.getStatus.status === 'UPDATING';
  }

  get decrypting() {
    return this.getStatus && this.getStatus.status === 'DECRYPTING_DATA';
  }

  get restoring() {
    return this.getStatus && this.getStatus.status === 'RESTORING_BACKUP';
  }

  get initialSetup() {
    return this.getStatus && this.getStatus.status === 'INITIAL_SETUP';
  }
};
