export default class Message {
  id!: string | null;
  indicatorId!: string | null;
  type!: any | null;
  progressType!: any | null;
  status!: any | null;
  text!: string | null;
  progress!: number | null;
  recipients!: string[] | null;
  messageTime!: string | null;

  constructor() {
    this.id = null;
    this.indicatorId = null;
    this.type = null;
    this.progressType = null;
    this.status = null;
    this.text = null;
    this.progress = null;
    this.recipients = null;
    this.messageTime = null;
  }
}
