

















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from "vuex-class";
import Password from "../model/Password";

class SettingsLink {
  route!: string;
  icon!: string;
  descr!: string;
}

@Component({
  components: {}
})
export default class SettingsMenuEntry extends Vue {
  @Prop(Object) link!: SettingsLink;
};
