export default class Password {
  id!: string | null;
  name!: string | null;
  description!: string | null;
  password!: string | null;

  constructor() {
    this.id = null;
    this.name = null;
    this.description = null;
    this.password = null;
  }
}
