

























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";
import LoadingButton from "@/components/LoadingButton.vue";
import User from "../model/User";

@Component({
  components: { LoadingButton }
})
export default class AliasGridItem extends Vue {
  @Prop(Array) recipients!: string[];
  @Prop(String) name!: string;
  @Getter getUsers!: User[];
  @Action GET_USERS!: () => Promise<any>;

  addresses: string[] = this.recipients.slice();

  @Action ADD_ALIAS!: ({ alias, recipients }:
                       { alias: string, recipients: string[] }) => Promise<any>;
  @Action DELETE_ALIAS!: (alias: string) => Promise<any>;

  get getUserMails(): string[] {
    var userMails: string[] = [];
    for (let user of this.getUsers as User[]) {
      if (user.internEmail) userMails.push(user.internEmail);
    }
    return userMails;
  }

  get canUpdate(): boolean {
    var current: string[] = this.addresses.slice();
    var previous: string[] = this.recipients.slice();
    if (!current || !previous) return false;
    if (current.length !== previous.length) return true;

    current.sort();
    previous.sort();
    let canUpdate: boolean = false;

    current.forEach((addr, idx) => {
      if (previous[idx] !== addr) canUpdate = true;
    });

    return canUpdate;
  }

  updateAlias(): Promise<any> {
    return this.ADD_ALIAS({ alias: this.name, recipients: this.addresses }).then(() => {
      this.$snotify.success(this.$pgettext("notification", "Alias updated"));
    },
    error => {
      this.$snotify.error(this.$pgettext("server_message", error.response.data.message),
        this.$pgettext("notification", "Could not update alias. An error occurred."));
    });
  }

  deleteAlias(): Promise<any> {
    return this.DELETE_ALIAS(this.name).then(() => {
      this.$snotify.success(this.$pgettext("notification", "Alias deleted"));
    }).catch((/* error */) => { //TODO is there a reason not to use error parameter??
      this.$snotify.error(
        this.$pgettext("notification", "Could not delete alias. An error occurred."));
    }).finally(() => {
      this.$bvModal.hide('deleteAliasModal-' + name);
    });
  }
};
