import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from 'axios';
import { store, clear } from "@/store";
import User from "@/model/User";
import Timers from "@/util/timers";

@Module
export default class SessionStore extends VuexModule {
  authToken: string | null = null;
  loggedInUser: User | null = null;
  twoFactorConfirmed: boolean = false;
  targetRoute: string | null = null;

  get getTargetRoute() {
    return this.targetRoute;
  }

  get isLoggedIn(): boolean {
    return Boolean(this.authToken && this.loggedInUser);
  }

  get needsTwoFactorConfirm(): boolean {
    return Boolean(this.loggedInUser && this.loggedInUser.twoFactorEnabled && !this.twoFactorConfirmed);
  }

  get needsPUKConfirm(): boolean {
    return Boolean(this.loggedInUser && this.loggedInUser.passwordResetPin);
  }

  get getUser(): User | null {
    return this.loggedInUser;
  }

  get loggedInUserHasSecretary(): boolean {
    return Boolean(this.loggedInUser && typeof this.loggedInUser.secretary === "string" && this.loggedInUser.secretary !== "");
  }

  get loggedInUsername(): string | null {
    if (this.loggedInUser) {
      return this.loggedInUser.cn;
    } else {
      return null;
    }
  }

  get loggedInUserId(): string | null {
    if (this.loggedInUser) {
      return this.loggedInUser.uid;
    } else {
      return null;
    }
  }

  get loggedInRole(): string | null {
    if (this.loggedInUser) {
      return this.loggedInUser.posixGroupName;
    } else {
      return null;
    }
  }

  get loggedInIsAdmin(): boolean {
    let role: string | null = this.loggedInRole;
    if (role) return role === "admin";
    return false;
  }

  get loggedInIsSigning(): boolean {
    if (this.loggedInUser && this.loggedInUser.memberOf) {
      return !!this.loggedInUser.memberOf.find((groupDn) => {
        return groupDn.startsWith('cn=signing,');
      });
    }
    return false;
  }

  @Mutation
  setTargetRoute(route: string) {
    this.targetRoute = route;
  }

  @Mutation
  receiveAuthToken (token: string) {
    this.authToken = token;
  }

  @Mutation
  receiveSessionUser (user: User) {
    this.loggedInUser = user;
  }

  @Mutation
  removePasswordResetPin() {
    if (this.loggedInUser) this.loggedInUser.passwordResetPin = null;
  }

  @Mutation
  setTwoFactorConfirmed() {
    this.twoFactorConfirmed = true;
  }

  @Mutation
  clearSession () {
    this.authToken = null;
    this.loggedInUser = null;
    clear({
      status: store.state.status
    });
  }

  @Action({ rawError: true })
  LOGIN_REQUEST ({ username, password }: any) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.set('username', username);
      formData.set('password', password);
      axios({
        method: 'post',
        url: '/api/v1/login',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((response: any) => {
        const authToken = response.headers['x-auth-token'];
        const user: User = Object.assign(new User(), response.data);
        //Set user before token => Otherwise needsTwoFactorConfirm might return false incorrectly
        this.context.commit('receiveSessionUser', user);
        this.context.commit('receiveAuthToken', authToken);
        if (authToken) document.cookie = 'USESSIONID=' + authToken + ';Path=/';
        resolve();
      }).catch((error: any) => {
        this.context.commit('clearSession');
        reject(error);
      });
    });
  }

  @Action({ rawError: true })
  LOGOUT_REQUEST () {
    this.context.commit('clearSession');
    this.context.commit('setMessageRefreshEnabled', false);
    Timers.clearInterval(Timers.MESSAGES);
    return new Promise((resolve, reject) => {
      axios.post("/api/v1/logout").then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
