export default class ReplicaConfig {
  id!: string;
  serial!: string | null;
  host!: string | null;
  port!: string | null;
  user!: string | null;
  password!: string | null;
  source!: string | null;
  target!: string | null;
  cron!: string | null;
  lastReplicationTime!: number | null;

  constructor() {
    this.serial = null;
    this.host = null;
    this.port = null;
    this.user = null;
    this.password = null;
    this.source = null;
    this.target = null;
    this.cron = null;
    this.lastReplicationTime = null;
  }
}
