










import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from "vuex-class";

@Component
export default class FirewallRule extends Vue {
  @Prop(Number) number!: number;
  @Prop(String) rule!: string;

  importantPorts: number[] = [ 53, 80, 443, 465, 993 ];

  @Action DELETE_FIREWALL_RULE!: (portNumber: number) => Promise<any>;

  get deletionIsAllowed() {
    return !this.importantPorts.includes(this.number);
  }

  deleteRule (portNumber: number) {
    this.DELETE_FIREWALL_RULE(portNumber).then(() => {
      this.$snotify.success(this.$pgettext("notification", "Rule was deleted."));
    }).catch((error) => {
      this.$snotify.error(error.response.data.message,
        this.$pgettext("notification", "Could not delete rule."));
    });
  }
};
