import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import { store, clear } from "@/store";
import AdminMode from "@/model/AdminMode";

const url: string = '/api/v1/status';

@Module
export default class StatusStore extends VuexModule {

  status: any | null = null;
  failCount: number = 0;

  get getStatus () : any | null {
    if (this.status) {
      return Object.assign(this.status, { failCount: this.failCount });
    } else {
      return { status: null, failCount: this.failCount };
    }
  }

  get getMailDomains () : string[] {
    if (this.status && this.status.mailDomains && this.status.mailDomains.length) {
      return this.status.mailDomains;
    } else if (this.status && this.status.domain) {
      return [ this.status.domain ];
    }
    return [];
  }

  get isSambaSharesDisabled(): boolean {
    if (this.status.disabledFeatures) {
      return this.status.disabledFeatures.indexOf("smbshares") > -1;
    } else {
      return false;
    }
  }

  @Mutation
  setStatus (status: any) {
    if (!status) return;

    //If status switches to recovery or waiting for setup, clear the state!
    if (((!this.status || this.status.status !== 'RECOVERY') && status.status === 'RECOVERY') &&
        ((!this.status || this.status.status !== 'WAITING_FOR_SETUP') && status.status === 'WAITING_FOR_SETUP') &&
        ((!this.status || this.status.status !== 'DATA_LOCKED') && status.status === 'DATA_LOCKED')) {
      clear({
        status: {
          status: status,
          failCount: this.failCount
        }
      });
    }

    if (this.status && this.status.status === 'UPDATING' && status.status !== 'UPDATING') {
      window.location.reload();
    }

    //this.status = { status: "UPDATING" };
    this.status = status;
  }

  @Mutation
  setFailCount (failCount: number) {
    this.failCount = failCount;
  }

  @Action({ commit: 'setStatus', rawError: true })
  GET_STATUS() {
    return axios.get(url).then((response) => {
      this.context.commit('setFailCount', 0);

      //Check if loggedIn, then update adminMode from state:
      if (this.context.rootState.session.authToken && this.context.rootState.session.loggedInUser) {
        this.context.commit('setAdminMode', Object.assign(new AdminMode(), { enabled: !!response.data.adminMode }));
      }

      return response.data;
    }).catch(() => {
      if (this.failCount <= 2) {
        this.context.commit('setFailCount', this.failCount + 1);
        return this.status; //Do not change anything
      } else if (this.status.status === 'UPDATING' || this.status.status === "RESETTING") {
        return this.status;
      } else {
        return { status: 'OFFLINE' };
      }
    });
  }
};
