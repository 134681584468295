
























































































import { Vue, Component } from 'vue-property-decorator';
import StoreApp from "../model/StoreApp";
import { Action, Getter, Mutation } from "vuex-class";
import App from "../model/App";
import LoadingImage from "../components/LoadingImage.vue";
import AnimatedInput from "../components/AnimatedInput.vue";
import VC from '../util/VersionComparator';

@Component({
  components: { AnimatedInput, LoadingImage }
})
export default class Store extends Vue {

  @Getter getApps!: App[];
  @Getter getStoreApps!: StoreApp[];
  @Mutation setStoreApps!: (storeApps: StoreApp[]) => void;
  @Action GET_INSTALLED_APPS!: any;
  @Action GET_STORE_APPS!: any;

  wordfilterPlaceholder: string = this.$pgettext("label", "Click to Filter apps");

  //tagsAvailable: string[] = [];
  //tagsSelected: string[] = [];
  wordFilter: string = "";
  showInstalledAppsFirst: boolean = false;

  get getFilteredStoreApps(): StoreApp[] {
    let filteredApps: StoreApp[] = [];
    let lowerCaseFilter: string = this.wordFilter.toLowerCase();
    for (let app of this.getStoreApps as StoreApp[]) {
      if (app.dontShowInAppList) {
        continue;
      }

      if (app.id && (!lowerCaseFilter || (app.title as string).toLowerCase().includes(lowerCaseFilter) ||
                                         (app.tagline as string).toLowerCase().includes(lowerCaseFilter))) {
        filteredApps.push(app);
      }
    }
    return filteredApps.sort((a: StoreApp, b: StoreApp) => {
      let titleA: string = '';
      let titleB: string = '';

      if (a.title) titleA = a.title.toUpperCase();
      if (b.title) titleB = b.title.toUpperCase();

      let installedA: boolean = false;
      let installedB: boolean = false;

      if (a.id) installedA = this.isInstalled(a.id);
      if (b.id) installedB = this.isInstalled(b.id);

      let needsUpdateA: boolean = this.needsUpdate(a);
      let needsUpdateB: boolean = this.needsUpdate(b);

      let compare: number = 0;
      if (titleA > titleB) compare += 1;
      else if (titleA < titleB) compare -= 1;
      if (this.showInstalledAppsFirst && installedA && !installedB) compare -= 2;
      else if (this.showInstalledAppsFirst && !installedA && installedB) compare += 2;
      if (needsUpdateA && !needsUpdateB) compare -= 2;
      else if (!needsUpdateA && needsUpdateB) compare += 2;

      return compare;
    });
  }

  goToStoreApp(storeAppId: string): void {
    this.$router.push('/store/' + storeAppId);
  }

  isInstalled(storeAppId: string): boolean {
    return !!this.findAppByStoreAppId(storeAppId);
  }

  needsUpdate(storeApp: StoreApp): boolean {
    if (!this.isInstalled(storeApp.id as string)) return false;
    let app = this.findAppByStoreAppId(storeApp.id as string);
    if (!app) return false;
    let installedVersion = app.appVersion as string;
    let newestVersion = storeApp.version as string;
    let result = VC.compareVersions(newestVersion, installedVersion);
    return result === 1;
  }

  findAppByStoreAppId(storeAppId: string): App | null {
    let app = this.getApps.find((value: App) => {
      return value.storeId === storeAppId;
    });
    if (app) return app;
    return null;
  }

  beforeMount () {
    this.GET_INSTALLED_APPS();
    this.GET_STORE_APPS().then(() => {
      /*
      this.tagsAvailable = [];
      for (let app of this.getStoreApps) {
        if (app.tags) {
          for (let tag of app.tags) {
            if (this.tagsAvailable.indexOf(tag) < 0) this.tagsAvailable.push(tag);
          }
        }
      } */
    });
  }
};
