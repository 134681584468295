import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import SystemInformation from "@/model/SystemInformation";
import FactoryResetRequest from "@/model/FactoryResetRequest";
import UnikiSystem from "@/model/UnikiSystem";
import LogContainer from "@/model/LogContainer";
import { store, clear } from "@/store";

const url: string = '/api/v1/system';

@Module
export default class SystemStore extends VuexModule {
  system: SystemInformation | null = null;
  changelog: string = '';
  licenses: string = '';
  logs: LogContainer | null = null;
  loading: boolean = false;

  get getSystem() : SystemInformation | null {
    return this.system;
  }

  get getSystemLicenses() : String | null {
    return this.licenses;
  }

  get getSystemChangeLogs(): String | null {
    return this.changelog;
  }

  @Mutation
  setSystem(system: SystemInformation) {
    this.system = system;
  }

  @Mutation
  setSystemLogs(logs: LogContainer) {
    this.logs = logs;
  }

  @Mutation
  setSystemChangelogs(changelog: string) {
    this.changelog = changelog;
  }

  @Mutation
  setSystemLicenses(licenses: string) {
    this.licenses = licenses;
  }

  @Mutation
  setSystemIsLoading(loading: boolean) {
    this.loading = loading;
  }

  @Action({ commit: 'setSystem', rawError: true })
  GET_SYSTEM_INFORMATION() {
    this.context.commit("setSystemIsLoading", true);
    return axios.get(url).then((response) => {
      this.context.commit("setSystemIsLoading", false);
      return Object.assign(new SystemInformation(), response.data);
    });
  }

  @Action({ commit: 'setSystemLogs', rawError: true })
  GET_SYSTEM_LOGS() {
    return axios.get(url + '/log').then((response) => {
      return Object.assign(new LogContainer(), response.data);
    });
  }

  @Action({ commit: 'setSystemChangelogs', rawError: true })
  GET_SYSTEM_CHANGELOGS() {
    return axios.get(url + '/changelog').then((response) => {
      return response.data;
    });
  }

  @Action({ commit: 'setSystemLicenses', rawError: true })
  GET_SYSTEM_LICENSES() {
    return axios.get(url + '/licenses').then((response) => {
      return response.data;
    });
  }

  @Action({ rawError: true })
  SHUTDOWN_SYSTEM() {
    return axios.post(url + '/shutdown', {});
  }

  @Action({ rawError: true })
  REBOOT_SYSTEM() {
    return axios.post(url + '/reboot', {});
  }

  @Action({ rawError: true })
  FACTORY_RESET(request: FactoryResetRequest) {
    return axios.post(url + '/reset', request).then(() => {
      clear({
        status: {
          status: { status: "RESETTING" },
          enabled: true,
          failCount: 0
        }
      });
    });
  }

  @Action({ rawError: true })
  UPDATE_SYSTEM(system: UnikiSystem) {
    return axios.put(url, system);
  }

  @Action({ rawError: true })
  REPAIR_SYSTEM(request: any) {
    return axios.post(url, request);
  }
};
