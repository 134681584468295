




import { Vue, Component } from 'vue-property-decorator';
import App from "@/model/App";
import { Getter } from "vuex-class";

@Component
export default class AppFrame extends Vue {
  @Getter getApps!: App[];

  get getAppUrl () {
    let thisApp: App | undefined = this.getApps.find((app: App) => { return app.instanceId === this.$route.params.id; });
    if (thisApp) {
      return 'https://' + thisApp.host;
    } else {
      this.$snotify.error(this.$pgettext("notification", "The app you requested could not be opened"));
      this.$router.push('/');
      return '';
    }
  }
};
