import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import ConfigValue from "@/model/ConfigValue";
import LDAPConfig from "@/model/LDAPConfig";

const url: string = '/api/v1/config/system';

@Module
export default class ConfigValueStore extends VuexModule {
  systemConfigValues: ConfigValue[] = [];

  get getSystemConfigValues () : ConfigValue[] {
    return this.systemConfigValues;
  }

  @Mutation
  addOrReplaceConfigValues (configValues: ConfigValue[]) {
    for (let newConfig of configValues) {
      let oldValue = this.systemConfigValues.find((value: ConfigValue) => {
        return value.id === newConfig.id && value.context === newConfig.context;
      });

      if (oldValue !== undefined) {
        this.systemConfigValues = this.systemConfigValues.map((value: ConfigValue) => {
          if (value.id === newConfig.id && value.context === newConfig.context) {
            return newConfig;
          } else {
            return value;
          }
        });
      } else {
        this.systemConfigValues.push(newConfig);
      }
    }
  }

  @Mutation
  deleteConfigValue(configValue: ConfigValue) {
    let toDelete = this.systemConfigValues.find((value: ConfigValue) => {
      return value.id === configValue.id && value.context === configValue.context;
    });

    if (toDelete !== undefined) {
      let index = this.systemConfigValues.indexOf(toDelete);
      if (index > -1) {
        this.systemConfigValues.splice(index, 1);
      }
    }
  }

  @Action({ commit: 'addOrReplaceConfigValues', rawError: true })
  GET_SYSTEM_CONFIG_VALUES(context: string) {
    return axios.get(url + '/' + encodeURIComponent(context)).then((response) => {
      return response.data ? response.data.map((value: ConfigValue) => Object.assign(new ConfigValue(), value)) : [];
    });
  }

  @Action({ commit: 'addOrReplaceConfigValues', rawError: true })
  SET_SYSTEM_CONFIG_VALUES({ context, values }: { context: string, values: ConfigValue[] }) {
    return axios.post(url + '/' + encodeURIComponent(context), values).then((response) => {
      this.context.dispatch('GET_SYSTEM_CONFIG_VALUES', context);
      return response.data ? response.data.map((value: ConfigValue) => Object.assign(new ConfigValue(), value)) : [];
    });
  }

  @Action({ rawError: true })
  TEST_LDAP_CONFIG_VALUE(config: LDAPConfig) {
    return axios.post(url + '/external.authentication/test', { value: config }).then(response => response.data);
  }

  @Action({ commit: 'deleteConfigValue', rawError: true })
  DELETE_SYSTEM_CONFIG_VALUE(configValue: ConfigValue) {
    const encodedId = encodeURIComponent(configValue.id || '');
    const encodedContext = encodeURIComponent(configValue.context || '');
    return axios.delete(url + '/' + encodedContext + '/' + encodedId).then((response) => {
      this.context.dispatch('GET_SYSTEM_CONFIG_VALUES', configValue.context);
      return configValue;
    });
  }
};
