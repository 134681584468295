import SyncConfig from '@/model/SyncConfig';
import LibraryProgress from '@/model/LibraryProgress';

export default class SyncJob {
  id!: number | null;
  source!: SyncConfig | null;
  target!: SyncConfig | null;
  state!: LibraryProgress | null;

  constructor() {
    this.id = null;
    this.source = null;
    this.target = null;
    this.state = null;
  }
}
