





















import { Vue, Component, Prop } from 'vue-property-decorator';
import User from "../model/User";
import { Action, Getter } from "vuex-class";
import LoadingImage from "../components/LoadingImage.vue";
const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;

@Component({
  components: { LoadingImage }
})
export default class DropBox extends Vue {
  @Prop(Array) acceptedTypes!: String[];
  @Action DELETE_USER!: (uid: string) => Promise<any>;
  @Action SET_PROFILE_PICTURE!: ({ userId, file }: { userId: string, file: File }) => Promise<any>;
  uploadedFile: File | any;
  uploadError!: null;
  currentStatus!: number;
  uploadFieldName!: 'photo';

  get isInitial () {
    return this.currentStatus === STATUS_INITIAL;
  }
  get isSaving () {
    return this.currentStatus === STATUS_SAVING;
  }
  get isSuccess () {
    return this.currentStatus === STATUS_SUCCESS;
  }
  get isFailed () {
    return this.currentStatus === STATUS_FAILED;
  }

  checkFiles (file: File[]) {
      this.uploadedFile = file[0];
      this.currentStatus = STATUS_SUCCESS;
      this.uploadedFile = null;
      if (file && (this.acceptedTypes.indexOf(file[0]['type']) > -1)) {
        this.uploadedFile = file[0];
        this.$emit('got-file', this.uploadedFile);
      }
  }

  reset () {
    // reset form to initial state
    this.currentStatus = STATUS_INITIAL;
    this.uploadedFile = null;
    this.uploadError = null;
  }

  beforeMount () {
    this.reset();
  }
};
