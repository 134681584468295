import App from "@/model/App";
import StoreApp from "@/model/StoreApp";
import Group from "@/model/Group";
import SelectableGroup from "@/util/SelectableGroup";

export default class StoreAppUtils {

  static appSupportsGroups(app: App | undefined | null): boolean {
    return Boolean(app && app.userGroup && app.storeApp && this.storeAppSupportsGroups(app.storeApp));
  }

  static storeAppSupportsGroups(app: StoreApp | undefined | null): boolean {
    return Boolean(app && app.addons && app.addons.ldap && app.addons.ldap.groupSupport);
  }

  static getAddons(storeApp: StoreApp | null, $gettext: any): { id: string, title: string, description: string }[] {
    let addons: { id: string, title: string, description: string }[] = [];
    if (storeApp && storeApp.addons) {
      for (const addon in storeApp.addons) {
        if (storeApp.addons.hasOwnProperty(addon)) {
          let title: string | null = null;
          let description: string | null = null;
          switch (addon) {
            case 'mysql':
              title = $gettext('Databases (MySQL)');
              description = $gettext('The app can request MySQL databases, which will be configured and provided by the system.');
              break;
            case 'postgresql':
              title = $gettext('Databases (PostgreSQL)');
              description = $gettext('The app can request PostgreSQL databases, which will be configured and provided by the system.');
              break;
            case 'mongodb':
              title = $gettext('Databases (MongoDB)');
              description = $gettext('The app can request MongoDB databases, which will be configured and provided by the system.');
              break;
            case 'memcached':
              title = $gettext('In-Memory Cache (Memcached)');
              description = $gettext('The app can request a Memcached server, which will be configured and provided by the system.');
              break;
            case 'redis':
              title = $gettext('In-Memory Cache (Redis)');
              description = $gettext('The app can request a Redis server, which will be configured and provided by the system.');
              break;
          }
          if (title && description) {
            addons.push({ id: addon, title: title, description: description });
          }
        }
      }
    }
    return addons;
  }

  static getStorageClasses(storeApp: StoreApp | null, $gettext: any): { id: string, title: string, description: string, required: boolean }[] {
    let addons: { id: string, title: string, description: string, required: true }[] = [];
    if (storeApp && storeApp.addons) {
      for (const addon in storeApp.addons) {
        if (storeApp.addons.hasOwnProperty(addon)) {
          let title: string | null = null;
          let description: string | null = null;
          switch (addon) {
            case 'localstorage':
              title = $gettext('Local Storage (Hybrid RAID+SSD)');
              description = $gettext('Local storage on the hybrid RAID+SSD-pool.');
              break;
            case 'objectstorage':
              title = $gettext('Cloud Storage (Standard)');
              description = $gettext('Fast Cloud Object Storage for frequent access.');
              break;
          }
          if (title && description) {
            addons.push({ id: addon, title: title, description: description, required: true });
          }
        }
      }
    }
    if (addons.length === 0) {
      addons.push({
        id: 'localstorage',
        title: $gettext('Local Storage (Hybrid RAID+SSD)'),
        description: $gettext('Local storage on the hybrid RAID+SSD-pool.'),
        required: true
      });
    }
    return addons;
  }

  static getPermissions(storeApp: StoreApp | null, $gettext: any): { id: string, title: string, description: string }[] {
    let permissions: { id: string, title: string, description: string }[] = [];
    if (storeApp && storeApp.addons) {
      for (const addon in storeApp.addons) {
        if (storeApp.addons.hasOwnProperty(addon)) {
          let title: string | null = null;
          let description: string | null = null;
          switch (addon) {
            case 'ldap':
              title = $gettext('User directory and authentication (LDAP)');
              description = $gettext('The app can read information about users and groups on the system.');
              break;
            case 'onlyoffice':
              title = $gettext('Online Office (OnlyOffice)');
              description = $gettext('The app can send documents to the OnlyOffice server for collaborative editing.');
              break;
            case 'collabora':
              title = $gettext('Online Office (Collabora)');
              description = $gettext('The app can send documents to the Collabora server for collaborative editing.');
              break;
            case 'jitsi':
              title = $gettext('Video Conferences (Jitsi)');
              description = $gettext('The app can create conference rooms and participation links.');
              break;
            case 'sendmail':
              title = $gettext('Outgoing Mail (SMTP)');
              description = $gettext('The app can send email.');
              break;
            case 'recvmail':
              title = $gettext('Incoming Mail (IMAP)');
              description = $gettext('The app can read the users\'s inboxes with the users\' permission.');
              break;
            case 'hostnet':
              title = $gettext('Local Networking');
              description = $gettext('The app can access the local network and any networking resources provided by other apps.');
              break;
          }
          if (title && description) {
            permissions.push({ id: addon, title: title, description: description });
          }
        }
      }
    }
    if (storeApp && storeApp.services) {
      for (const service in storeApp.services) {
        if (storeApp.services.hasOwnProperty(service)) {
          let title: string | null = null;
          let description: string | null = null;
          switch (service) {
            case 'mailsink':
              title = $gettext('Mail BCC');
              description = $gettext('The app will receive a copy of all incoming and outgiong mail.');
              break;
          }
          if (title && description) {
            permissions.push({ id: service, title: title, description: description });
          }
        }
      }
    }
    return permissions;
  }

  static appHasSecondaryGroups(app: App | null): boolean {
    return Boolean(app && this.storeAppHasSecondaryGroups(app.storeApp));
  }

  static storeAppHasSecondaryGroups(storeApp: StoreApp | null): boolean {
    return Boolean(storeApp && storeApp.addons && storeApp.addons.ldap && storeApp.addons.ldap.groups);
  }

  static getSecondaryAppGroupsOfInstalledApp(app: App | null, groups: Group[]): SelectableGroup[] | null {
    if (app && app.storeApp && app.storeApp.addons && app.storeApp.addons.ldap && app.storeApp.addons.ldap.groups) {
      const appGroups: SelectableGroup[] = [];
      if (Array.isArray(app.storeApp.addons.ldap.groups)) {
        for (const groupId of app.storeApp.addons.ldap.groups) {
          const group: Group | undefined = groups.find(g => g.cn === (app.instanceId + '_' + groupId.toLowerCase()));
          if (group) {
            const selectableGroup: SelectableGroup = Object.assign(new SelectableGroup(groupId), { group: group });
            selectableGroup.displayName = groupId;
            appGroups.push(selectableGroup);
          }
        }
      } else {
        for (const groupId in app.storeApp.addons.ldap.groups) {
          if (app.storeApp.addons.ldap.groups.hasOwnProperty(groupId)) {
            const group: Group | undefined = groups.find(g => g.cn === (app.instanceId + '_' + groupId.toLowerCase()));
            if (group) {
              const selectableGroup: SelectableGroup = Object.assign(new SelectableGroup(groupId), app.storeApp.addons.ldap.groups[groupId]);
              selectableGroup.group = group;
              if (!selectableGroup.displayName) {
                selectableGroup.displayName = groupId;
              }
              appGroups.push(selectableGroup);
            }
          }
        }
      }
      return appGroups;
    } else {
      return null;
    }
  }

  static getSecondaryAppGroupsOfStoreApp(storeApp: StoreApp, app: App | null, groups: Group[] | null): SelectableGroup[] | null {
    if (storeApp && storeApp.addons && storeApp.addons.ldap && storeApp.addons.ldap.groups) {
      const appGroups: SelectableGroup[] = [];
      if (Array.isArray(storeApp.addons.ldap.groups)) {
        for (const groupId of storeApp.addons.ldap.groups) {
          const group: Group | undefined = (groups && app) ? groups.find(g => g.cn === (app.instanceId + '_' + groupId.toLowerCase())) : undefined;
          const selectableGroup: SelectableGroup = new SelectableGroup(groupId);
          selectableGroup.displayName = groupId;
          if (group) {
            appGroups.push(Object.assign(selectableGroup, { group: group }));
          } else {
            appGroups.push(selectableGroup);
          }
        }
      } else {
        for (const groupId in storeApp.addons.ldap.groups) {
          if (storeApp.addons.ldap.groups.hasOwnProperty(groupId)) {
            const selectableGroup: SelectableGroup = Object.assign(new SelectableGroup(groupId), storeApp.addons.ldap.groups[groupId]);
            const group: Group | undefined = (groups && app) ? groups.find(g => g.cn === (app.instanceId + '_' + groupId.toLowerCase())) : undefined;
            if (group) {
              selectableGroup.group = group;
            }
            if (!selectableGroup.displayName) {
              selectableGroup.displayName = groupId;
            }
            appGroups.push(selectableGroup);
          }
        }
      }
      return appGroups;
    } else {
      return null;
    }
  }
}
