export default class BackupJob {
  id!: string;
  fsUUID!: string;
  cronExpression!: string | null;
  cron!: boolean | null;
  udev!: boolean | null;

  constructor() {
    this.cronExpression = null;
    this.cron = null;
    this.udev = null;
  }
}
