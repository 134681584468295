


























import { Vue, Component, Ref } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import AnimatedInput from "../components/AnimatedInput.vue";
import AliasGridItem from "../components/AliasGridItem.vue";
import User from "@/model/User";
import MailAlias from "@/model/MailAlias";
import MailAliasesNew from "@/views/MailAliasesNew.vue";
import { BModal } from "bootstrap-vue";

@Component({
  components: {
    MailAliasesNew,
    AnimatedInput,
    AliasGridItem
  }
})
export default class MailAliases extends Vue {
  @Getter getUsers!: User[];
  @Getter getAliases!: MailAlias[];
  @Action GET_USERS!: () => Promise<any>;
  @Action GET_ALIASES!: () => Promise<any>;
  @Action DELETE_ALIAS!: (alias: string) => Promise<any>;

  @Ref('addAliasModal') addAliasModal!: BModal;

  created() {
    this.GET_USERS();
    this.GET_ALIASES();
  }
}
