export default class ArrayCompare {

  static equals(a: any[] | null | undefined, b: any[] | null | undefined): boolean {
    if (a === b) return true;
    if (a === null || b === null || a === undefined || b === undefined) return false;
    if (a.length !== b.length) return false;

    const aSorted = [...a].sort();
    const bSorted = [...b].sort();

    for (let i = 0; i < aSorted.length; ++i) {
      if (aSorted[i] !== bSorted[i]) return false;
    }

    return true;
  }
}
