import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import UserConfigValue from "@/model/UserConfigValue";

const url: string = '/api/v1/config';

@Module
export default class UserConfigValueStore extends VuexModule {
  userConfigValues: UserConfigValue[] = [];

  get getUserConfigValues () : UserConfigValue[] {
    return this.userConfigValues;
  }

  get getCustomWallpaper() : string | null {
    let value: UserConfigValue | undefined = this.userConfigValues.find((value: UserConfigValue) => {
      return value.id === 'wallpaper';
    });
    return value ? value.value : null;
  }

  @Mutation
  setUserConfigValues (userConfigValues: UserConfigValue[]) {
    this.userConfigValues = userConfigValues;
  }

  @Mutation
  addOrReplaceUserConfigValue (newValue: UserConfigValue) {
    let oldValue = this.userConfigValues.find((value: UserConfigValue) => {
      return value.id === newValue.id;
    });

    if (oldValue !== undefined) {
      this.userConfigValues = this.userConfigValues.map((value: UserConfigValue) => {
        if (value.id === newValue.id) {
          return newValue;
        } else {
          return value;
        }
      });
    } else {
      this.userConfigValues.push(newValue);
    }
  }

  @Mutation
  deleteUserConfigValue(id: string) {
    let toDelete = this.userConfigValues.find((value: UserConfigValue) => {
      return value.id === id;
    });

    if (toDelete !== undefined) {
      let index = this.userConfigValues.indexOf(toDelete);
      if (index > -1) {
        this.userConfigValues.splice(index, 1);
      }
    }
  }

  @Action({ commit: 'setUserConfigValues', rawError: true })
  GET_USER_CONFIG_VALUES() {
    return axios.get(url).then((response) => {
      return response.data ? response.data.map((value: UserConfigValue) => Object.assign(new UserConfigValue(), value)) : [];
    });
  }

  @Action({ rawError: true })
  GET_USER_CONFIG_VALUE(id: string) {
    const encodedId = encodeURIComponent(id);
    return axios.get(url + '/' + encodedId);
  }

  @Action({ commit: 'addOrReplaceUserConfigValue', rawError: true })
  SET_USER_CONFIG_VALUE(value: UserConfigValue) {
    return axios.post(url, value).then((response) => {
      this.context.dispatch('GET_USER_CONFIG_VALUES');
      return Object.assign(new UserConfigValue(), response.data);
    });
  }

  @Action({ commit: 'deleteUserConfigValue', rawError: true })
  DELETE_USER_CONFIG_VALUE(id: string) {
    const encodedId = encodeURIComponent(id);
    return axios.delete(url + '/' + encodedId).then((response) => {
      this.context.dispatch('GET_USER_CONFIG_VALUES');
      return id;
    });
  }
};
