
























































































































































import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";
import SystemInformation from "../model/SystemInformation";
import VersionComparator from "@/util/VersionComparator";
import Timers from "@/util/timers";
import Formats from "@/util/formats";

@Component
export default class SystemInfo extends Vue {

  @Getter getSystem!: SystemInformation | null;
  @Getter getSystemVersionInStore!: string | null;
  @Getter getMaintenanceMode!: boolean;

  @Action GET_MAINTENANCE_MODE!: () => Promise<boolean>;
  @Action DISABLE_MAINTENANCE_MODE!: () => Promise<boolean>;
  @Action ENABLE_MAINTENANCE_MODE!: () => Promise<boolean>;
  @Action GET_SYSTEM_INFORMATION!: () => Promise<SystemInformation>;
  @Action SHUTDOWN_SYSTEM!: () => Promise<void>;
  @Action REBOOT_SYSTEM!: () => Promise<void>;

  chartOptions: any = {
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: '16px',
            color: undefined,
            offsetY: 0
          },
          value: {
            offsetY: 10,
            fontSize: '22px',
            color: undefined,
            formatter: function (val: any) {
              return val + "%";
            }
          }
        }
      }
    },
    stroke: {
      dashArray: 4
    }
  };

  get newVersionAvailable(): boolean {
    if (!this.getSystemVersionInStore || !this.getSystem || !this.getSystem.backendVersion) {
      return false;
    } else if (this.getSystem && this.getSystem.backendVersion) {
      return VersionComparator.compareVersions(this.getSystemVersionInStore as string, this.getSystem.backendVersion) > 0;
    } else {
      return false;
    }
  }

  get cpuLoadCurrent (): number[] {
    let system: SystemInformation | null = this.getSystem;
    if (system != null && system.cpuLoadCurrent != null) {
      return [ system.cpuLoadCurrent ];
    } else {
      return [ 0 ];
    }
  }

  get cpuLoadAverage (): number[] {
    let system = this.getSystem;
    if (system != null && system.cpuLoadAverage != null) {
      return [ system.cpuLoadAverage ];
    } else {
      return [ 0 ];
    }
  }

  get usedRamString(): string {
    let system = this.getSystem;
    if (system && system.freeRAMInKBytes && system.totalRAMInKBytes) {
      return Formats.formatBytes((system.totalRAMInKBytes - system.freeRAMInKBytes) * 1000);
    } else {
      return '';
    }
  }

  get totalRamString(): string {
    let system = this.getSystem;
    if (system && system.totalRAMInKBytes) {
      return Formats.formatBytes(system.totalRAMInKBytes * 1000);
    } else {
      return '';
    }
  }

  get usedSystemString(): string {
    let system = this.getSystem;
    if (system && system.systemFreeSpaceInKBytes && system.systemTotalSpaceInKBytes) {
      return Formats.formatBytes((system.systemTotalSpaceInKBytes - system.systemFreeSpaceInKBytes) * 1000);
    } else {
      return '';
    }
  }

  get totalSystemString(): string {
    let system = this.getSystem;
    if (system && system.systemTotalSpaceInKBytes) {
      return Formats.formatBytes(system.systemTotalSpaceInKBytes * 1000);
    } else {
      return '';
    }
  }

  get usedDataString(): string {
    let system = this.getSystem;
    if (system && system.dataSizeInKBytes) {
      return Formats.formatBytes(system.dataSizeInKBytes * 1000);
    } else {
      return '';
    }
  }

  get totalDataString(): string {
    let system = this.getSystem;
    if (system && system.totalSpaceInKBytes) {
      return Formats.formatBytes(system.totalSpaceInKBytes * 1000);
    } else {
      return '';
    }
  }

  get usedRam (): number[] {
    let system = this.getSystem;
    if (system != null && system.freeRAMInKBytes != null && system.totalRAMInKBytes != null) {
      return [ 100 - Math.round(100 * system.freeRAMInKBytes / system.totalRAMInKBytes) ];
    } else {
      return [ 0 ];
    }
  }

  get usedSystemdisk (): number[] {
    let system = this.getSystem;
    if (system != null && system.systemFreeSpaceInKBytes != null && system.systemTotalSpaceInKBytes != null) {
      return [ 100 - Math.round(100 * system.systemFreeSpaceInKBytes / system.systemTotalSpaceInKBytes) ];
    } else {
      return [ 0 ];
    }
  }

  get usedDatadisk (): number[] {
    let system = this.getSystem;
    if (system != null && system.dataSizeInKBytes != null && system.totalSpaceInKBytes != null) {
      return [ Math.round(100 * system.dataSizeInKBytes / system.totalSpaceInKBytes) ];
    } else {
      return [ 0 ];
    }
  }

  get diskState (): boolean {
    let system = this.getSystem;
    if (system != null && system.hardDrives != null) {
      for (let i = 0; i < system.hardDrives.length; i++) {
        if (system.hardDrives[i].status !== 'GOOD') return false;
      }
    }
    return true;
  }

  getTextColorClass(smartStatus: string) {
    switch (smartStatus) {
      case 'NEVER':
        return 'text-success';
      case 'IN_THE_PAST':
        return 'text-warning';
      case 'FAILING_NOW':
        return 'text-danger';
      default:
        return '';
    }
  }

  toggleMaintenanceMode (): void {
    if (this.getMaintenanceMode) {
      this.DISABLE_MAINTENANCE_MODE().catch((error) => {
        this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not disable maintenance mode"));
      });
    } else {
      this.ENABLE_MAINTENANCE_MODE().catch((error) => {
        this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not enable maintenance mode"));
      });
    }
  }

  restartDevice (): void {
    this.REBOOT_SYSTEM(); //We don't really need to wait. This will prob. never return.
    this.$snotify.success(this.$pgettext("notification", "Restarting your ELLY..."));

  }

  shutdownDevice (): void {
    this.SHUTDOWN_SYSTEM(); //We don't really need to wait. This will prob. never return.
    this.$snotify.success(this.$pgettext("notification", "Shutting down your ELLY..."));
  }

  refresh (): void {
    this.GET_SYSTEM_INFORMATION().finally(() => {
      Timers.setInterval(Timers.SYSTEM, () => {
        this.GET_SYSTEM_INFORMATION();
      }, 1000);
    });
  }

  beforeDestroy () {
    Timers.clearInterval(Timers.SYSTEM);
  }

  beforeMount () {
    this.GET_MAINTENANCE_MODE().catch((error) => {
      this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not get maintenance mode state"));
    });
    this.refresh();
  }
};
