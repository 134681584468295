



















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from "vuex-class";
import Message from "../model/Message";
import dayjs from "@/util/dayjs";

@Component({
  components: {}
})
export default class MessageGridItem extends Vue {
  @Prop(Object) mssg!: Message;
  @Action DELETE_MESSAGE!: (id: string) => Promise<any>;

  datespecified: string = this.$gettext('at');

  get getDateFormated(): string {
    if (this.mssg.messageTime) {
      return dayjs(this.mssg.messageTime).format('DD.MM.YYYY [' + this.datespecified + '] HH:mm');
    }
    return "";
  }

  get getVariant(): string {
      let variant: string = "primary";
      switch (this.mssg.status) {
          case "OK":
              variant = "success";
              break;
          case "ERROR":
              variant = "danger";
              break;
      }
      return "bg-" + variant;
  }

  get getIcon(): string {
    let icon: string = "info-circle";
    if (this.mssg.status === "ERROR") {
      icon = "exclamation-circle";
    }
    return "fas fa-" + icon;
  }

  deleteMssg(): Promise<any> | void {
    return this.DELETE_MESSAGE(this.mssg.id as string).then(() => {
        this.$snotify.success(this.$pgettext("notification", "Message deleted"));
    },
    error => {
          this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not delete message"));
    });
  }
};
