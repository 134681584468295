





















































































































































import { Vue, Component, Ref } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import SeafileLibrary from "@/model/SeafileLibrary";
import SelectableList from "@/components/SelectableList.vue";
import LoadingImage from "@/components/LoadingImage.vue";
import User from "@/model/User";
import { FormWizard } from "vue-form-wizard";
import { ValidationObserver } from "vee-validate";
import App from "@/model/App";
import StoreApp from "@/model/StoreApp";
import ImportableData from "@/model/ImportableData";
import SyncJob from "@/model/SyncJob";
import SyncConfig from "@/model/SyncConfig";
import LibraryProgress from "@/model/LibraryProgress";
import Timers from "@/util/timers";

@Component({
  components: {
    LoadingButton,
    AnimatedInput,
    SelectableList,
    LoadingImage
  }
})
export default class SyncJobs extends Vue {

  remoteDomainLabel: string = this.$pgettext("SyncJob", "Domain");
  remoteUserLabel: string = this.$pgettext("SyncJob", "Username");
  remotePasswordLabel: string = this.$pgettext("SyncJob", "Password");

  @Action GET_SYNC_JOBS!: () => Promise<SyncJob[]>;
  @Action ADD_SYNC_JOB!: (syncJob: SyncJob) => Promise<SyncJob>;
  @Action DELETE_SYNC_JOB!: (id: string) => Promise<any>;
  @Action GET_SEAFILE_LIBRARIES!: () => Promise<SeafileLibrary[]>;
  @Action GET_REMOTE_LIBRARIES!: ({ remoteUrl, user, password }: { remoteUrl: string, user: string, password: string }) => Promise<SeafileLibrary[]>;
  @Action GET_INSTALLED_APPS!: () => Promise<App[]>;
  @Action GET_STORE_APPS!: any;

  @Getter getApps!: App[];
  @Getter getLibraries!: SeafileLibrary[];
  @Getter getSyncJobs!: SyncJob[];
  @Getter getStoreApps!: StoreApp[];

  modalTitle: string = this.$gettext('Synchronize libraries with remote server');

  step: number = 0;
  setStep(current: number, next: number) {
    this.step = next;
  }

  remoteDomain: string | null = null;
  remoteUser: string | null = null;
  remotePassword: string | null = null;
  remoteLibrary: SeafileLibrary | null = null;
  localLibrary: SeafileLibrary | null = null;

  remoteLibraries: SeafileLibrary[] | null = null;
  loginError: boolean = false;

  @Ref('addSyncJobWizard') addSyncJobWizard!: FormWizard;

  get seafileInstalled(): boolean {
    return Boolean(this.getApps && this.getApps.find(app => {
      return Boolean(app.storeId && (app.storeId.startsWith('de.uniki.seafile') || app.storeId.startsWith('de.uniki.files')));
    }));
  }

  goToSeafileInStore(): void {
    if (this.getStoreApps) {
      let storeApp: StoreApp | undefined = this.getStoreApps.find((app: StoreApp) => {
        return Boolean(app.id && app.id === 'de.uniki.seafile');
      });
      if (storeApp) {
        this.$router.push('/store/' + storeApp.id);
      }
    }
  }

  get hasSyncJobsConfigured(): boolean {
    return !!this.getSyncJobs && this.getSyncJobs.length > 0;
  }

  openAddSyncJobModal() {
    this.modalTitle = this.$gettext('Synchronize libraries with remote server');
    this.$bvModal.show('addSyncJobModal');
    this.$nextTick(() => {
      this.addSyncJobWizard.reset();
    });
  }

  goToSecondPage(props: any): Promise<any> {
    let remoteUrl: string = this.remoteDomain || '';
    if (!remoteUrl.startsWith('https://') && !remoteUrl.startsWith('http://')) {
      remoteUrl = 'https://' + remoteUrl;
    }
    let user: string = this.remoteUser || '';
    let password: string = this.remotePassword || '';
    return this.GET_REMOTE_LIBRARIES({ remoteUrl, user, password }).then((data: SeafileLibrary[]) => {
      this.loginError = false;
      this.remoteLibraries = data.map((library: SeafileLibrary) => Object.assign(new SeafileLibrary(), library));
      props.nextTab();
    }).catch(error => {
      this.loginError = true;
      this.$snotify.error(this.$pgettext("server_message", error.response.data.message),
        this.$pgettext("notification", "Failed to get remote libraries. Please check the domain, user name and password."));
    });
  }

  addSyncJob(): Promise<any> {
    if (this.remoteLibrary && this.localLibrary) {
      let syncJob: SyncJob = new SyncJob();
      let localConfig: SyncConfig = new SyncConfig();
      localConfig.libraryId = this.localLibrary.id;
      let remoteConfig: SyncConfig = new SyncConfig();
      remoteConfig.libraryId = this.remoteLibrary.id;
      remoteConfig.url = this.remoteDomain;
      if (remoteConfig.url && !remoteConfig.url.startsWith('https://') && !remoteConfig.url.startsWith('http://')) {
        remoteConfig.url = 'https://' + remoteConfig.url;
      }
      remoteConfig.user = this.remoteUser;
      remoteConfig.password = this.remotePassword;
      syncJob.source = localConfig;
      syncJob.target = remoteConfig;

      return this.ADD_SYNC_JOB(syncJob).then(() => {
        //Only reset if it goes well, otherwise let the user try again.
        this.remoteDomain = null;
        this.remoteUser = null;
        this.remotePassword = null;
        this.remoteLibrary = null;
        this.localLibrary = null;
        this.$bvModal.hide('addSyncJobModal');
        this.$snotify.success(this.$pgettext("notification", "The synchronization was started."));
        this.GET_SYNC_JOBS();
      }).catch(error => {
        this.$snotify.error(this.$pgettext("server_message", error.response.data.message),
          this.$pgettext("notification", "Could start synchronization. An error occurred."));
      });
    } else {
      //Should not happen because button is disabled.
      return Promise.resolve();
    }
  }

  deleteSyncJob(id: string): Promise<any> {
    return this.DELETE_SYNC_JOB(id).then(() => {
      this.$snotify.success(this.$pgettext("notification", "The synchronization was stopped."));
    }).catch(error => {
      this.$snotify.error(this.$pgettext("server_message", error.response.data.message),
        this.$pgettext("notification", "Could not stop synchronization. An error occurred."));
    }).finally(() => {
      this.$bvModal.hide('deleteSyncJobModal-' + id);
    });
  }

  getSourceLibraryName(job: SyncJob): string | null {
    if (job.source) {
      return job.source.state ? job.source.state.name : job.source.libraryId;
    } else {
      return null;
    }
  }

  getTargetLibraryName(job: SyncJob): string | null {
    if (job.target) {
      return job.target.state ? job.target.state.name : job.target.libraryId;
    } else {
      return null;
    }
  }

  getStateTextForProgress(progress: LibraryProgress | null, url: string | null): string | null {
    if (progress) {
      let text: string = '';
      let domain: string = url ? url.replace('https://', '') : '';
      if (progress.state === 'uploading') {
        text += this.$gettext('Uploading to') + ' ' + domain + '/' + progress.name;
      } else if (progress.state === 'downloading') {
        text += this.$gettext('Downloading from') + ' ' + domain + '/' + progress.name;
      } else if (progress.state === 'committing') {
        text += this.$gettext('Committing') + ' ' + domain + '/' + progress.name;
      } else if (progress.state === 'waiting for sync') {
        text += this.$gettext('Preparing') + ' ' + domain + '/' + progress.name;
      } else if (progress.state === 'error') {
        text += this.$gettext('Error synchronizing') + ' ' + domain + '/' + progress.name;
      }
      if (progress.errors != null && progress.errors.length > 0) {
        text += '(' + progress.errors.join(', ') + ')';
      } else if (progress.progress != null && progress.speedInKBytePerSec != null) {
        text += ' (' + progress.progress + '%, ' + progress.speedInKBytePerSec + 'KB/s)';
      } else if (progress.progress != null) {
        text += ' (' + progress.progress + '%)';
      } else if (progress.speedInKBytePerSec != null) {
        text += ' (' + progress.speedInKBytePerSec + 'KB/s)';
      }
      return text;
    } else {
      return null;
    }
  }

  isSynchronized(job: SyncJob): boolean {
    return (!job.source || this.isSynchronizedOrNone(job.source.state)) && (!job.target || this.isSynchronizedOrNone(job.target.state));
  }

  isSynchronizedOrNone(progress: LibraryProgress | null | undefined): boolean {
    return Boolean(!progress || (progress.state === 'synchronized' || progress.progress === 100));
  }

  inProgressOrError(progress: LibraryProgress | null | undefined): boolean {
    return Boolean(progress && !this.isSynchronizedOrNone(progress) && (progress.state || progress.speedInKBytePerSec != null || progress.progress != null));
  }

  created() {
    this.GET_SYNC_JOBS().finally(() => {
      Timers.setInterval(Timers.SYNC_JOBS, () => {
        this.GET_SYNC_JOBS();
      }, 1000);
    });
    this.GET_INSTALLED_APPS().finally(() => {
      if (this.seafileInstalled) {
        this.GET_SEAFILE_LIBRARIES();
      } else {
        this.GET_STORE_APPS();
      }
    });
  }

  beforeDestroy () {
    Timers.clearInterval(Timers.SYNC_JOBS);
  }
}
