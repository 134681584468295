import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators';
import axios from "axios/index";

const url: string = '/api/v1/maintenance';

@Module
export default class MaintenanceModeStore extends VuexModule {
  enabled: boolean = false;
  log: string = '';
  loading: boolean = false;

  get getMaintenanceMode() {
    return this.enabled;
  }

  get getMaintenanceLog() {
    return this.log;
  }

  @Mutation setMaintenanceMode (enabled: boolean) {
    this.enabled = enabled;
  }

  @Mutation setMaintenanceModeIsLoading (loading: boolean) {
    this.loading = loading;
  }

  @Mutation setMaintenanceModeLog (log: string) {
    this.log = log;
  }

  @Action({ commit: 'setMaintenanceMode', rawError: true })
  GET_MAINTENANCE_MODE () {
    this.context.commit("setMaintenanceModeIsLoading", true);
    return axios.get(url).then((response) => {
      this.context.commit("setMaintenanceModeIsLoading", false);
      this.context.commit("setMaintenanceModeLog", response.data.log);
      return response.data.enabled;
    });
  }

  @Action({ commit: 'setMaintenanceMode', rawError: true })
  ENABLE_MAINTENANCE_MODE () {
    return axios.post(url).then((response) => {
      return true;
    });
  }

  @Action({ commit: 'setMaintenanceMode', rawError: true })
  DISABLE_MAINTENANCE_MODE () {
    return axios.delete(url).then((response) => {
      return false;
    });
  }
};
