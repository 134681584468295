import HardDrive from '@/model/HardDrive';
import NetworkInterface from "@/model/NetworkInterface";

export default class SystemInformation {
  localIp!: string | null;
  macAddress!: string | null;
  totalSpaceInKBytes!: number | null;
  dataSizeInKBytes!: number | null;
  freeSpaceInKBytes!: number | null;
  systemFreeSpaceInKBytes!: number | null;
  systemTotalSpaceInKBytes!: number | null;
  totalRAMInKBytes!: number | null;
  freeRAMInKBytes!: number | null;
  cpuLoadCurrent!: number | null;
  cpuLoadAverage!: number | null;
  version!: string | null;
  groupware!: boolean | null;
  backendVersion!: string | null;
  resourcesVersion!: string | null;
  frontendVersion!: string | null;
  databaseVersion!: string | null;
  proxyVersion!: string | null;
  acmeClientVersion!: string | null;
  systemStatus!: string | null;
  proxyStatus!: string | null;
  vpnStatus!: string | null;
  mysqlStatus!: any | null;
  postgresqlStatus!: any | null;
  redisStatus!: any | null;
  ldapStatus!: any | null;
  sendmailStatus!: any | null;
  proxyConfigs!: string[] | null;
  dockerImages!: string[] | null;
  hardDrives!: HardDrive[] | null;
  networkInterfaces!: NetworkInterface[] | null;

  constructor() {
    this.localIp = null;
    this.macAddress = null;
    this.totalSpaceInKBytes = null;
    this.dataSizeInKBytes = null;
    this.freeSpaceInKBytes = null;
    this.systemFreeSpaceInKBytes = null;
    this.systemTotalSpaceInKBytes = null;
    this.totalRAMInKBytes = null;
    this.freeRAMInKBytes = null;
    this.cpuLoadCurrent = null;
    this.cpuLoadAverage = null;
    this.version = null;
    this.groupware = null;
    this.backendVersion = null;
    this.resourcesVersion = null;
    this.frontendVersion = null;
    this.databaseVersion = null;
    this.proxyVersion = null;
    this.acmeClientVersion = null;
    this.systemStatus = null;
    this.proxyStatus = null;
    this.vpnStatus = null;
    this.mysqlStatus = null;
    this.postgresqlStatus = null;
    this.redisStatus = null;
    this.ldapStatus = null;
    this.sendmailStatus = null;
    this.proxyConfigs = null;
    this.dockerImages = null;
    this.hardDrives = null;
    this.networkInterfaces = null;
  }
}
