import { render, staticRenderFns } from "./SecretaryDisplay.vue?vue&type=template&id=23615af2&scoped=true&"
import script from "./SecretaryDisplay.vue?vue&type=script&lang=ts&"
export * from "./SecretaryDisplay.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23615af2",
  null
  
)

export default component.exports