import LibraryProgress from "@/model/LibraryProgress";

export default class NetworkShare {
  id!: string;
  name!: string | null;
  types!: string[] | null;
  acl!: string[] | null;
  guestOk!: boolean | null;
  libraryId!: string | null;
  libraryProgress!: LibraryProgress | null;

  constructor() {
    this.name = null;
    this.types = null;
    this.acl = null;
    this.guestOk = null;
    this.libraryId = null;
    this.libraryProgress = null;
  }
}
