export default class MailConfig {
  incomingServer!: string | null;
  incomingPort!: string | null;
  incomingType!: string | null;
  incomingMailAddress!: string | null;
  incomingUsername!: string | null;
  incomingPassword!: string | null;
  incomingEncryption!: string | null;
  outgoingServer!: string | null;
  outgoingPort!: string | null;
  outgoingMailAddress!: string | null;
  outgoingUsername!: string | null;
  outgoingPassword!: string | null;
  outgoingEncryption!: string | null;
  folderNames!: string[] | null;
  keepOnServer!: boolean | null;
  active!: boolean | null;

  constructor() {
    this.incomingServer = null;
    this.incomingPort = null;
    this.incomingType = null;
    this.incomingMailAddress = null;
    this.incomingUsername = null;
    this.incomingPassword = null;
    this.incomingEncryption = null;
    this.outgoingServer = null;
    this.outgoingPort = null;
    this.outgoingMailAddress = null;
    this.outgoingUsername = null;
    this.outgoingPassword = null;
    this.outgoingEncryption = null;
    this.folderNames = null;
    this.keepOnServer = null;
    this.active = null;
  }
}
