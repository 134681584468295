




























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from "vuex-class";
import App from "../model/App";
import LoadingButton from "../components/LoadingButton.vue";
import AnimatedInput from "@/components/AnimatedInput.vue";

@Component({
  components: { LoadingButton, AnimatedInput }
})
export default class HostedAppGridItem extends Vue {
  @Prop(Object) app!: App;
  @Action UNINSTALL_APP!: ({ instanceId, removeData, password }: { instanceId: string, removeData: boolean, password: string | null }) => Promise<any>;

  removeData: boolean = false;
  password: string = "";

  passwordPlaceholder: string = this.$pgettext("placeholder", "Password");

  get appUrl(): string {
    return "https://" + this.app.host;
  }

  get ports(): { host: string, container: string }[] | null {
    if (this.app && this.app.ports) {
      let ports: { host: string, container: string }[] = [];
      for (let port in this.app.ports) { //"in" iterates properties, "of" iterates list entries
        if (this.app.ports.hasOwnProperty(port)) { //Useless double check to satisfy IDE
          ports.push({ host: port, container: String(this.app.ports[port]) });
        }
      }
      return ports.length > 0 ? ports : null;
    } else {
      return null;
    }
  }

  get hasProgress(): boolean {
    let progress: string | null = this.app.status;
    return (progress === "INSTALLING" || progress === "UPDATING" || progress === "UNINSTALLING" || progress === "STARTING" || progress === "BUILDING_IMAGE");
  }

  get progressText(): string {
    let progress: string | null = this.app.status;
    switch (progress) {
      case 'INSTALLING':
        return this.$pgettext("app_state", "Installing app");
      case 'UPDATING':
        return this.$pgettext("app_state", "Updating app");
      case 'UNINSTALLING':
        return this.$pgettext("app_state", "Removing app");
      case 'STARTING':
        return this.$pgettext("app_state", "Starting app");
      case 'BUILDING_IMAGE':
        return this.$pgettext("app_state", "Building image");
      default:
        return this.$pgettext("app_state", "Please wait");
    }
  }

  deleteApp(): Promise<any> {
      return this.UNINSTALL_APP({
          instanceId: this.app.instanceId as string,
          removeData: this.removeData,
          password: this.password
      }).then(() => {
          this.$snotify.success(this.$pgettext("notification", "App deleted"));
      },
      error => {
          this.$snotify.error(this.$pgettext("server_message", error.response.data.message),
            this.$pgettext("notification", "Could not delete app"));
      });
  }
};
