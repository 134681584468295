





























import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from "vuex-class";
import LoadingButton from "@/components/LoadingButton.vue";
import App from "@/model/App";

@Component({
  components: { LoadingButton }
})
export default class DomainChip extends Vue {
  @Prop(Object) app!: App;
  @Prop(String) domain!: string;
  @Action SET_DOMAIN_CONFIG!: ({ instanceId, alternativeDomains }: { instanceId: string, alternativeDomains: string[] }) => Promise<any>;
  @Action GET_INSTALLED_APPS!: () => Promise<any>;

  deleteDomain(): Promise<any> {
    var alternatives = (this.app.alternativeDomains as string[]).slice();
    alternatives.splice(alternatives.indexOf(this.domain), 1);

    return this.updateDomains(alternatives, this.$pgettext("notification", "Domain deleted"));
  }

  updateDomains(alternatives: string[], successMessage: string): Promise<any> {
    return this.SET_DOMAIN_CONFIG({
      instanceId: this.app.instanceId as string,
      alternativeDomains: alternatives
    }).then(() => {
      this.$snotify.success(successMessage);
      this.$bvModal.hide('confirmDeleteModal-' + this.domain);
      this.GET_INSTALLED_APPS();
    },
    error => {
      this.$snotify.error(this.$pgettext("server_message", error.response.data.message),
        this.$pgettext("notification", "Could not update domains. An error occurred."));
    });
  }
};
