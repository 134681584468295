






































import { Vue, Component, Ref } from 'vue-property-decorator';
import UserGridItem from "../components/UserGridItem.vue";
import { Action, Getter } from "vuex-class";
import User from "../model/User";
import AnimatedInput from "../components/AnimatedInput.vue";
import UserNew from "@/components/UserNew.vue";
import { BModal } from "bootstrap-vue";
import ConfigValue from "@/model/ConfigValue";
import Group from "@/model/Group";
import LoadingButton from "@/components/LoadingButton.vue";

@Component({
  components: {
    UserNew,
    UserGridItem,
    AnimatedInput,
    LoadingButton
  }
})
export default class Users extends Vue {
  @Getter getUsers!: User[];
  @Action GET_GROUPS!: () => Promise<Group[]>;
  @Action GET_USERS!: () => Promise<User[]>;
  @Action GET_SUBSCRIPTIONS!: () => Promise<any[]>;
  @Action GET_PAYMENT_OPTIONS!: () => Promise<any>;
  @Action GET_INSTALLED_APPS!: () => Promise<any>;
  @Action GET_STORE_APPS!: () => Promise<any>;
  @Action SET_ADMIN_MODE!: (password: string) => Promise<any>;
  @Action GET_SYSTEM_CONFIG_VALUES!: (context: string) => Promise<ConfigValue[]>;

  @Ref('addUserModal') addUserModal!: BModal;

  nameFilterPlaceholder: string = this.$pgettext("label", "Filter users by name");
  nameFilter: string = "";

  get getFilteredUsers(): User[] {
    let filteredUsers: User[] = [];
    if (this.nameFilter && this.nameFilter !== "") {
      let lowerCaseFilter: string = this.nameFilter.toLowerCase();
      for (let user of this.getUsers as User[]) {
        if ((user.givenname + ' ' + user.surname).toLowerCase().includes(lowerCaseFilter) ||
            ((user.givenname as string) + user.surname).toLowerCase().includes(lowerCaseFilter) ||
            (user.uid as string).toLowerCase().includes(lowerCaseFilter)) {
          filteredUsers.push(user);
        }
      }
    } else {
      filteredUsers = this.getUsers;
    }
    return filteredUsers;
  }

  showNewUserModal(): Promise<any> {
    return Promise.all([
      this.GET_STORE_APPS(),
      this.GET_INSTALLED_APPS(),
      this.GET_PAYMENT_OPTIONS(),
      this.GET_GROUPS(),
      this.GET_USERS(),
      this.GET_SUBSCRIPTIONS()
    ]).finally(() => {
      this.$bvModal.show('addUserModal');
    });
  }

  beforeMount () {
    this.GET_USERS();
    this.GET_SYSTEM_CONFIG_VALUES('external.authentication');
  }
};
