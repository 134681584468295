export default class PasswordResetRequest {
  token!: string | null;
  pin!: string | null;
  newPassword!: string | null;
  newPasswordRepeat!: string | null;

  constructor() {
    this.token = null;
    this.pin = null;
    this.newPassword = null;
    this.newPasswordRepeat = null;
  }
}
