import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import StoreApp from "@/model/StoreApp";

const url: string = '/api/v1/store';

@Module
export default class StoreAppStore extends VuexModule {
  apps: StoreApp[] = [];
  systemVersionInStore: string | null = null;
  storeAppsLoading: boolean = false;
  systemVersionLoading: boolean = false;

  get getStoreApps () : StoreApp[] {
    return this.apps;
  }

  get getSystemVersionInStore(): string | null {
    return this.systemVersionInStore;
  }

  @Mutation
  setStoreApps (apps: StoreApp[]) {
    this.apps = apps;
  }

  @Mutation
  setSystemVersionInStore(version: string) {
    this.systemVersionInStore = version;
  }

  @Mutation
  setStoreAppsAreLoading(loading: boolean) {
    this.storeAppsLoading = loading;
  }

  @Mutation
  setSystemVersionLoading(loading: boolean) {
    this.systemVersionLoading = loading;
  }

  @Action({ commit: 'setStoreApps', rawError: true })
  GET_STORE_APPS() {
    this.context.commit("setStoreAppsAreLoading", true);
    return axios.get(url + '/apps').then((response) => {
      return response.data ? response.data.map((app: StoreApp) => Object.assign(new StoreApp(), app)) : [];
    }).finally(() => {
      this.context.commit("setStoreAppsAreLoading", false);
    });
  }

  @Action({ commit: 'setSystemVersionInStore', rawError: true })
  GET_SYSTEM_VERSION() {
    this.context.commit("setSystemVersionLoading", true);
    return axios.get(url + '/system').then((response) => {
      return response.data.version;
    }).finally(() => {
      this.context.commit("setSystemVersionLoading", false);
    });
  }
}
