


































import { Vue, Component } from 'vue-property-decorator';
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import { Action, Getter } from "vuex-class";
import Group from "@/model/Group";
import LoadingImage from "@/components/LoadingImage.vue";
import GroupOverviewItem from "@/components/GroupOverviewItem.vue";
import SimpleGroupOverviewItem from "@/components/SimpleGroupOverviewItem.vue";
import User from "@/model/User";
import appUtils from "@/util/StoreAppUtils";
import ConfigValue from "@/model/ConfigValue";
import App from "@/model/App";
import SimpleGroupEditModal from "@/components/SimpleGroupEditModal.vue";
import { BModal } from "bootstrap-vue";

@Component({
  components: {
    GroupOverviewItem,
    SimpleGroupOverviewItem,
    AnimatedInput,
    LoadingButton,
    LoadingImage,
    SimpleGroupEditModal
  }
})
export default class Groups extends Vue {
  @Getter getGroups!: Group[];
  @Getter getApps!: App[];
  @Getter loggedInIsAdmin!: boolean;
  @Action GET_GROUPS!: () => Promise<Group[]>;
  @Action GET_INSTALLED_APPS!: () => Promise<App[]>;
  @Action GET_STORE_APPS!: any;
  @Action GET_SYSTEM_CONFIG_VALUES!: (context: string) => Promise<ConfigValue[]>;
  @Action GET_USERS!: () => Promise<User[]>;

  isLoadingStoreApps: boolean = true;
  isLoadingGroups: boolean = true;
  systemGroupCNs = [ 'admin', 'signing' ];

  newGroup: Group = new Group();

  $refs!: {
    editModal: BModal;
  };

  get groupsWithApps(): Group[] {
    return this.getGroups.filter((group: Group) => {
      const app: App | undefined = this.correspondingApp(group);
      return app && appUtils.appSupportsGroups(app);
    });
  }

  correspondingApp(group: Group): App | undefined {
    return this.getApps.find(app => app.userGroup === group.dn);
  }

  get systemGroups(): Group[] {
    return this.getGroups.filter(group => this.systemGroupCNs.indexOf(group.cn || '') >= 0);
  }

  get customGroups(): Group[] {
    const groupsWithApps = this.groupsWithApps;
    const systemGroups = this.systemGroups;
    return this.getGroups.filter(group => group.cn && group.cn !== 'user' &&
        !group.cn.startsWith('de_uniki_') && !group.cn.startsWith('io_') &&
        groupsWithApps.indexOf(group) < 0 && systemGroups.indexOf(group) < 0);
  }

  showNewGroupModal(): Promise<any> {
    return this.GET_USERS().finally(() => {
      this.newGroup = new Group();
      this.$refs.editModal.show();
    });
  }

  mounted() {
    this.GET_GROUPS().finally(() => {
      this.isLoadingGroups = false;
    });
    Promise.all([
      this.GET_INSTALLED_APPS(),
      this.GET_STORE_APPS(),
      this.GET_SYSTEM_CONFIG_VALUES('external.authentication')
    ]).finally(() => {
      this.isLoadingStoreApps = false;
    });
  }

}
