












































































































































































import { Vue, Component, Ref } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";
import LoadingButton from "../components/LoadingButton.vue";
import LoadingImage from "../components/LoadingImage.vue";
import AnimatedInput from "../components/AnimatedInput.vue";
import StoreApp from "../model/StoreApp";
import App from "../model/App";
import SystemInformation from '../model/SystemInformation';
import VC from '../util/VersionComparator';
import { BModal } from "bootstrap-vue";
import Group from "@/model/Group";
import User from "@/model/User";
import SelectableList from "@/components/SelectableList.vue";
import InvoiceContact from "@/model/InvoiceContact";
import UpgradeAppWizard from "@/components/UpgradeAppWizard.vue";
import SubscriptionUtils from "@/util/SubscriptionUtils";
import Subscription from "@/model/invoice/Subscription";
import ProductMapping from "@/model/invoice/ProductMapping";
import StoreAppUtils from "@/util/StoreAppUtils";

@Component({
  components: { UpgradeAppWizard, LoadingImage, AnimatedInput, LoadingButton, SelectableList }
})
export default class StoreAppView extends Vue {
  marked = require('marked');

  @Getter getSystem!: SystemInformation;
  @Getter getApps!: App[];
  @Getter getStoreApps!: StoreApp[];
  @Getter getGroups!: Group[];
  @Getter getUsers!: User[];
  @Getter getInvoiceContact!: InvoiceContact | null;
  @Getter getPaymentInterval!: number | null;
  @Action PURCHASE_SERVICE!:({ planId, userCount }: { planId: string, userCount: number }) => Promise<any>;
  @Action GET_PAYMENT_OPTIONS!: () => Promise<any>;
  @Action GET_SUBSCRIPTIONS!: () => Promise<any[]>;
  @Action GET_SYSTEM_INFORMATION!: () => Promise<any>;
  @Action GET_INSTALLED_APPS!: () => Promise<any>;
  @Action GET_STORE_APPS!: () => Promise<any>;
  @Action INSTALL_APP!: ({ storeId, version, options }: { storeId: string, version: string | null, options: { users: string[] | null }}) => Promise<any>;
  @Action UPDATE_APP!: ({ instanceId, version, options }: { instanceId: string, version: string | null, options: { users: string[] | null }}) => Promise<any>;
  @Action UNINSTALL_APP!: ({ instanceId, removeData, password }: { instanceId: string, removeData: boolean, password: string | null }) => Promise<any>;
  @Action UPGRADE_SERVICE!:({ subscriptionId, planId, userCount }: { subscriptionId: number, planId: string, userCount: number }) => Promise<any>;
  @Action GET_GROUPS!: () => Promise<Group[]>;
  @Action GET_USERS!: () => Promise<User[]>;

  @Ref('upgradewizard') upgradeWizard!: BModal;

  subsUtil: SubscriptionUtils = new SubscriptionUtils();

  passwordPlaceholder: string = this.$pgettext("placeholder", "Password");
  password: string = "";
  removeData: boolean = false;

  storeAppId!: string;

  //Loading while the app is not available
  get isLoading(): boolean {
    return !this.storeAppFromStoreOrInstalled;
  }

  get storeAppFromStore(): StoreApp | null {
    return this.getStoreApps.find((value: StoreApp) => {
      return value.id === this.storeAppId;
    }) || null;
  }

  get storeAppFromStoreOrInstalled(): StoreApp | null {
    let storeApp: StoreApp | null = this.storeAppFromStore;
    if (!storeApp) {
      let installedApp: App | null = this.installedApp;
      if (installedApp) {
        storeApp = installedApp.storeApp;
      }
    }
    return storeApp;
  }

  get modalTitle(): string {
    let storeApp: StoreApp | null = this.storeAppFromStoreOrInstalled;
    if (storeApp) {
      let translated = this.$gettext('Uninstall app %{a}?');
      return this.$gettextInterpolate(translated, { a: storeApp.title });
    } else {
      return this.$pgettext("title", "Uninstall app");
    }
  }

  get installedApp(): App | null {
    return this.getApps.find((value: App) => {
      return value.storeId === this.storeAppId;
    }) || null;
  }

  get isInstalled(): boolean {
    return !!this.installedApp;
  }

  get isFree(): boolean {
    let storeApp: StoreApp | null = this.storeAppFromStore;
    return !this.subsUtil.isPaidApp(storeApp, null);
  }

  get hasActiveSeats(): boolean {
    let storeApp: StoreApp | null = this.storeAppFromStore;
    return Boolean(storeApp && this.subsUtil.seatsActive(storeApp));
  }

  get trialDays(): number {
    return this.subsUtil.trialDays(this.storeAppFromStore);
  }

  get priceRangeString(): string {
    const price = this.subsUtil.price(this.storeAppFromStore, this.getPaymentInterval) || 0;
    return this.subsUtil.currencyFormatDE(price / 100);
  }

  get canBeInstalled(): boolean {
    let storeApp: StoreApp | null = this.storeAppFromStore;
    if (!storeApp) return false;
    let freeRAM = this.getSystem.freeSpaceInKBytes as number;
    let neededRAM = storeApp.memoryLimit as number / 1000;
    let overshoot = 0.1;

    return freeRAM * (1 + overshoot) >= neededRAM;
  }

  get needsUpdate(): boolean {
    let storeApp: StoreApp | null = this.storeAppFromStore;
    if (!this.installedApp || !storeApp) {
      return false;
    }
    let installedVersion = this.installedApp.appVersion as string;
    let newestVersion = storeApp.version as string;
    let result = VC.compareVersions(newestVersion, installedVersion);
    return result === 1;
  }

  get showUninstallButton(): boolean {
    if (this.installedApp) {
      let progress: string | null = this.installedApp.status;
      return !progress || progress === 'STARTING' || progress === 'RUNNING' || progress === 'NOT_RUNNING';
    } else {
      return false;
    }
  }

  get canUninstall(): boolean {
    return Boolean(this.installedApp && !(this.removeData && !this.password));
  }

  get progressText(): string | false {
    if (this.installedApp) {
      let progress: string | null = this.installedApp.status;
      switch (progress) {
        case 'INSTALLING':
          return this.$pgettext("app_state", "Installing app");
        case 'UPDATING':
          return this.$pgettext("app_state", "Updating app");
        case 'UNINSTALLING':
          return this.$pgettext("app_state", "Removing app");
        case 'STARTING':
          return this.$pgettext("app_state", "Starting app");
        default:
          return false;
      }
    } else {
      return false;
    }
  }

  get compiledMarkdown() {
    let storeApp: StoreApp | null = this.storeAppFromStoreOrInstalled;
    if (storeApp && storeApp.description) {
      return this.marked(storeApp.description);
    }
    return "";
  }

  openBuyModal(): Promise<any> {
    return Promise.all([
      this.GET_STORE_APPS(),
      this.GET_INSTALLED_APPS()
    ]).finally(() => {
      if (this.needsUpdate && !this.progressText) {
        return this.updateAppOrOpenUserSelectModal();
      } else if (!this.isInstalled && this.canBeInstalled) {
        return this.installAppOrOpenUserSelectModal();
      }
    });
  }

  installAppOrOpenUserSelectModal(): Promise<any> {
    let storeApp: StoreApp | null = this.storeAppFromStore;
    if (storeApp && StoreAppUtils.storeAppSupportsGroups(storeApp)) {
      return Promise.all([
        this.GET_PAYMENT_OPTIONS(),
        this.GET_GROUPS(),
        this.GET_USERS(),
        this.GET_SUBSCRIPTIONS()
      ]).finally(() => {
        this.upgradeWizard.updateGroupMemberships();
        this.upgradeWizard.show();
      });
    } else if (storeApp) {
      this.upgradeWizard.updateGroupMemberships();
      return this.upgradeWizard.installApp();
    } else {
      return Promise.resolve();
    }
  }

  updateAppOrOpenUserSelectModal(): Promise<any> {
    //Installed app does not support groups, but new version supports groups => Select users.
    let storeApp: StoreApp | null = this.storeAppFromStore;
    if ((!this.isFree && !this.hasActiveSeats && StoreAppUtils.storeAppSupportsGroups(storeApp)) ||
        (!StoreAppUtils.appSupportsGroups(this.installedApp) && StoreAppUtils.storeAppSupportsGroups(storeApp)) ||
        (!StoreAppUtils.appHasSecondaryGroups(this.installedApp) && StoreAppUtils.storeAppHasSecondaryGroups(storeApp))) {
      return Promise.all([
        this.GET_PAYMENT_OPTIONS(),
        this.GET_GROUPS(),
        this.GET_USERS(),
        this.GET_SUBSCRIPTIONS()
      ]).finally(() => {
        this.upgradeWizard.updateGroupMemberships();
        this.upgradeWizard.show();
      });
    } else if (storeApp) {
      this.upgradeWizard.updateGroupMemberships();
      return this.upgradeWizard.updateApp(true);
    } else {
      return Promise.resolve();
    }
  }

  updateSubscriptionThenUninstallApp(): Promise<any> {
    if (!this.installedApp) {
      return Promise.resolve();
    }

    let app: StoreApp | null = this.storeAppFromStore;

    let subId: number | null = null;
    let planId: string | null = null;
    let subscription: Subscription | null = this.subsUtil.getActiveSubscription(app);
    if (subscription && app) {
      subId = subscription.id;
      if (subscription.products) {
        let mapping: ProductMapping | undefined = this.subsUtil.getProductForSubscriptionAndApp(subscription, app);
        if (mapping && mapping.product) planId = mapping.product.id;
      }
    }

    if (subId && planId && app) {
      return this.UPGRADE_SERVICE({ subscriptionId: subId, planId: planId, userCount: 0 }).then(() => {
        this.$snotify.success(this.$pgettext("notification", "Updated subscription."));
        return this.uninstallApp();
      }).catch(() => {
        this.$snotify.error(this.$pgettext("notification", "Subscription was not updated."));
      }).finally(() => {
        this.GET_STORE_APPS();
      });
    } else {
      return this.uninstallApp();
    }
  }

  uninstallApp(): Promise<any> {
    if (!this.installedApp) {
      return Promise.resolve();
    }

    return this.UNINSTALL_APP({
      instanceId: this.installedApp.instanceId as string,
      removeData: this.removeData,
      password: this.password
    }).then(() => {
      this.$snotify.success(this.$pgettext("notification", "Uninstalling app"));
      this.$bvModal.hide('uninstallAppModal');
      if (!this.storeAppFromStoreOrInstalled) {
        this.$router.push('/store');
      }
    }).catch(error => {
      this.$snotify.error(error.response.data.message,
        this.$pgettext("notification", "Could not uninstall app"));
      this.$bvModal.hide('uninstallAppModal');
    }).finally(() => {
      this.removeData = false;
      this.password = "";
    });
  }

  beforeMount() {
    this.storeAppId = this.$router.currentRoute.params.id;
    this.GET_SYSTEM_INFORMATION();
    this.GET_INSTALLED_APPS();
    this.GET_USERS();
    this.GET_GROUPS();
    this.GET_STORE_APPS().then((storeApps: StoreApp[]) => {
      let storeApp: StoreApp | null = this.storeAppFromStoreOrInstalled;
      if (!storeApp && !storeApps.find(app => app.id === this.storeAppId)) {
        this.$router.push('/store');
      }
    });
  }
};
