export default class UnikiSystem {
  version!: string | null;
  forceUpdate!: boolean | null;
  rootMigrationRequired!: boolean | null;
  minMigrationVersion!: string | null;
  minResourcesVersion!: string | null;
  md5Hash!: string | null;
  sha1Hash!: string | null;

  constructor() {
    this.version = null;
    this.forceUpdate = null;
    this.rootMigrationRequired = null;
    this.minMigrationVersion = null;
    this.minResourcesVersion = null;
    this.md5Hash = null;
    this.sha1Hash = null;
  }
}
