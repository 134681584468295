



























































































import { Vue, Component, Ref } from 'vue-property-decorator';
import { Getter, Action, State, namespace } from "vuex-class";
import Backup from "@/model/backup/Backup";
import BackupJob from "@/model/backup/BackupJob";
import BackupDevice from "@/model/backup/BackupDevice";
import BackupGridItem from "@/components/BackupGridItem.vue";
import USBStorageDeviceGridItem from "@/components/USBStorageDeviceGridItem.vue";
import BackupJobGridItem from "@/components/BackupJobGridItem.vue";
import BackupUtil from "@/util/BackupUtil";
import BackupStore from "@/store/BackupStore";
import dayjs from "@/util/dayjs";
import BackupJobNew from "@/components/BackupJobNew.vue";
import { BModal } from "bootstrap-vue";
import Subscription from "@/model/invoice/Subscription";
import Product from "@/model/invoice/Product";
import BackupJobStore from "@/store/BackupJobStore";
import BackupDeviceStore from "@/store/BackupDeviceStore";
const backupStore = namespace('backup');

@Component({
  components: { BackupJobNew, BackupGridItem, USBStorageDeviceGridItem, BackupJobGridItem }
})
export default class BackupSettings extends Vue {

  @State("backup") backupState!: BackupStore;
  @State("backupJob") backupJobState!: BackupJobStore;
  @State("backupDevice") backupDeviceState!: BackupDeviceStore;

  @Getter getBackupDevices!: BackupDevice[];
  @Getter getBackupJobs!: BackupJob[];
  @Getter getBackups!: Backup[];
  @Getter getSubscriptions!: Subscription[];

  @Action GET_PAYMENT_OPTIONS!: () => Promise<any>;
  @Action GET_BACKUP_DEVICES!: () => Promise< BackupDevice[]>;
  @Action GET_BACKUP_JOBS!: () => Promise< BackupJob[]>;
  @Action GET_BACKUPS!: () => Promise<Backup[]>;
  @Action GET_SUBSCRIPTIONS!: () => Promise<Subscription[]>;

  @Ref('addBackupJobModal') addBackupJobModal!: BModal;

  showSnapshotsInBackupList: boolean = false;

  get sortedBackups (): Backup[] {
    const backups: Backup[] = this.getBackups;
    let sorted = backups.slice(0); //Fast copy array
    sorted.sort((a: Backup, b: Backup) => {
      if (a.date && b.date) { //dayjs function expects a non-null string
        const dateA = dayjs(a.date).valueOf();
        const dateB = dayjs(b.date).valueOf();
        return dateB - dateA;
      } else {
        return -1;
      }
    });
    return sorted.filter((value: Backup) => {
      let deviceId: string = value.deviceId || '';
      if (deviceId === "snapper" || deviceId === "uniki-internal-snapshot") {
        return this.showSnapshotsInBackupList;
      } else {
        return true;
      }
    });
  };

  //Only returns USBStorageDevices
  get availableDevices(): BackupDevice[] {
    return this.getBackupDevices.filter((backupDevice: BackupDevice) => {
      return BackupUtil.canBeUsedForBackups(backupDevice);
    });
  };

  get cloudBackupBooked (): boolean {
    return BackupUtil.cloudBackupBooked(this.getSubscriptions);
  }

  get cloudBackupEnabled (): boolean {
    return BackupUtil.cloudBackupEnabled(this.getBackupJobs);
  }

  get cloudBackupCSS(): string {
    if (this.cloudBackupEnabled) {
      return 'success';
    } else if (this.cloudBackupBooked) {
      return 'danger';
    } else {
      return 'danger';
    }
  }

  get cloudBackupText(): string {
    if (this.cloudBackupEnabled) {
      return this.$gettext('booked and enabled');
    } else if (this.cloudBackupBooked) {
      return this.$gettext('booked, but disabled');
    } else {
      return this.$gettext('not booked');
    }
  }

  created () {
    this.GET_BACKUP_DEVICES().catch((error) => {
      if (error.response.status !== 412) {
        this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not get backup devices"));
      }
    });
    this.GET_BACKUP_JOBS().catch((error) => {
      if (error.response.status !== 412) {
        this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not get backup jobs"));
      }
    });
    this.GET_BACKUPS().catch((error) => {
      if (error.response.status !== 412) {
        this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not get backups"));
      }
    });
    this.GET_PAYMENT_OPTIONS();
    this.GET_SUBSCRIPTIONS();
  }
};
