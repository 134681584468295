import Group from "@/model/Group";

export default class SelectableGroup {
    id: string;
    displayName: string | null = null;
    productId: string | null = null;
    required: boolean = false;
    requires: { groups: string[] | null, apps: string[] | null } | null = null;
    preselect: string | null = null;
    group: Group | null = null;

    constructor(id: string) {
      this.id = id;
    }
}
