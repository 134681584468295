import Product from '@/model/invoice/Product';
import Subscription from '@/model/invoice/Subscription';

export default class StoreApp {
  id!: string | null;
  title!: string | null;
  author!: string | null;
  description!: string | null;
  tagline!: string | null;
  version!: string | null;
  healthCheckPath!: string | null;
  httpPort!: number | null;
  httpPorts!: number[] | null;
  addons!: any | null;
  manifestVersion!: number | null;
  website!: string | null;
  contactEmail!: string | null;
  icon!: string | null;
  tags!: string[] | null;
  mediaLinks!: string[] | null;
  changelog!: string | null;
  configurePath!: string | null;
  developmentMode!: boolean | null;
  memoryLimit!: number | null;
  maxBoxVersion!: string | null;
  minBoxVersion!: string | null;
  targetBoxVersion!: string | null;
  postInstallMessage!: string | null;
  singleUser!: boolean | null;
  tcpPorts!: any | null;
  dontShowInAppList!: boolean | null;
  noWebUI!: boolean | null;
  customAuth!: boolean | null;
  portalCommunicationError!: boolean | null;
  products!: Product[] | null;
  subscriptions!: Subscription[] | null;
  services!: any | null;
  containerHostName!: string | null;
  md5Hash!: string | null;
  sha1Hash!: string | null;

  constructor() {
    this.id = null;
    this.title = null;
    this.author = null;
    this.description = null;
    this.tagline = null;
    this.version = null;
    this.healthCheckPath = null;
    this.httpPort = null;
    this.httpPorts = null;
    this.addons = null;
    this.manifestVersion = null;
    this.website = null;
    this.contactEmail = null;
    this.icon = null;
    this.tags = null;
    this.mediaLinks = null;
    this.changelog = null;
    this.configurePath = null;
    this.developmentMode = null;
    this.memoryLimit = null;
    this.maxBoxVersion = null;
    this.minBoxVersion = null;
    this.targetBoxVersion = null;
    this.postInstallMessage = null;
    this.singleUser = null;
    this.tcpPorts = null;
    this.dontShowInAppList = null;
    this.noWebUI = null;
    this.customAuth = null;
    this.portalCommunicationError = null;
    this.products = null;
    this.subscriptions = null;
    this.services = null;
    this.containerHostName = null;
    this.md5Hash = null;
    this.sha1Hash = null;
  }

}
