

























import { Vue, Component } from 'vue-property-decorator';
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import AdminMode from "../model/AdminMode";
import { Action, Getter } from "vuex-class";

@Component({
  components: {
    AnimatedInput,
    LoadingButton
  }
})
export default class Settings extends Vue {

  @Getter getAdminMode!: AdminMode;
  @Getter loggedInIsAdmin!: boolean;
  @Action GET_ADMIN_MODE!: () => Promise<any> ;

  password: string = "";
  loading: boolean = true;

  async validateThenLogin () {
    if (this.password !== "") {
      this.loading = true;
      return this.login();
    } else {
      this.$snotify.error(
        this.$gettext('Login failed.')
      );
    }
  }

  login () {
    return this.$store.dispatch("SET_ADMIN_MODE", this.password).then(() => {
        this.loading = false;
        this.password = "";
      },
      error => {
        this.loading = false;
        this.password = "";
        const code = error.response.status;
        switch (code) {
          case 400:
          case 422:
            this.$snotify.error(this.$pgettext('notification', 'Wrong username or password.'));
            break;
          default:
            this.$snotify.error(this.$pgettext('notification', 'Login failed.'));
        }
      }
    );
  }

  //Workaround for a problem with the mobile collapse menu: On phones, the menu does not collapse when a
  //menu link is clicked, which results in weird scrolling behaviour and the user having to scroll down to
  //actually see the admin mode input.
  get isMobile (): boolean {
    return window.innerWidth < 768;
  }
};
