import Vue from 'vue';
import Router from 'vue-router';
import { store } from './store';
import Dashboard from './views/Dashboard.vue';
import Home from './views/Home.vue';
import Settings from './views/Settings.vue';
import Reset from './views/Reset.vue';
import Store from './views/Store.vue';
import StoreApp from './views/StoreApp.vue';
import Firewall from "./views/Firewall.vue";
import UserEdit from "./views/UserEdit.vue";
import UserNew from "./components/UserNew.vue";
import Feedback from "./views/Feedback.vue";
import Users from "./views/Users.vue";
import AppFrame from "./views/AppFrame.vue";
import SystemInfo from "./views/SystemInfo.vue";
import Licenses from "@/views/Licenses.vue";
import SystemUpdate from "@/views/SystemUpdate.vue";
import BackupSettings from "@/views/BackupSettings.vue";
import BackupJobNew from "@/components/BackupJobNew.vue";
import MailAliases from "./views/MailAliases.vue";
import MailAliasesNew from "./views/MailAliasesNew.vue";
import MailDomains from "./views/MailDomains.vue";
import MailSettings from "./views/MailSettings.vue";
import Maintenance from "./views/Maintenance.vue";
import Domains from "./views/Domains.vue";
import Hosting from "./views/Hosting.vue";
import PSTUpload from "./views/PSTUpload.vue";
import Passwords from "./views/Passwords.vue";
import Account from "./views/Account.vue";
import Messages from "./views/Messages.vue";
import Manual from "./views/Manual.vue";
import ManualViewer from "./views/ManualViewer.vue";
import PasswordReset from './views/PasswordReset.vue';
import PasswordForgotten from './views/PasswordForgotten.vue';
import Groups from "@/views/Groups.vue";
import OAuth2 from "./views/OAuth2.vue";
import NetworkShares from "@/views/NetworkShares.vue";
import DataImport from "@/views/DataImport.vue";
import Wallpaper from "@/views/Wallpaper.vue";
import Timers from "@/util/timers";
import SyncJobs from "@/views/Synchronization.vue";
import ReplicaJobs from "@/views/ReplicaJobs.vue";
import AuthenticationSettings from "@/views/AuthenticationSettings.vue";
import SystemNotifications from "@/views/SystemNotifications.vue";

Vue.use(Router);

const router = new Router({
  mode: (window.groupwareIsPresent === true) ? 'abstract' : 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/password/forgotten',
      component: PasswordForgotten
    },
    {
      path: '/password/reset',
      component: PasswordReset
    },
    {
      path: '/oauth2/authorize',
      component: OAuth2
    },
    {
      path: '/',
      component: Dashboard,
      children: [
        {
          path: '/app/:id',
          name: 'app',
          component: AppFrame
        },
        {
          path: '/account',
          name: 'account',
          component: Account
        },
        {
          path: '/store',
          name: 'store',
          component: Store
        },
        {
          path: '/store/:id',
          name: 'storeapp',
          component: StoreApp
        },
        {
          path: '/messages',
          name: 'messages',
          component: Messages
        },
        {
          path: '/settings',
          name: 'settings',
          component: Settings,
          children: [
            {
              path: 'domains',
              name: 'domains',
              component: Domains
            },
            {
              path: 'networkshares',
              name: 'networkshares',
              component: NetworkShares
            },
            {
              path: 'dataimport',
              name: 'dataimport',
              component: DataImport
            },
            {
              path: 'synchronization',
              name: 'synchronization',
              component: SyncJobs
            },
            {
              path: 'failover',
              name: 'failover',
              component: ReplicaJobs
            },
            {
              path: 'feedback',
              name: 'feedback',
              component: Feedback
            },
            {
              path: 'firewall',
              name: 'firewall',
              component: Firewall
            },
            {
              path: 'hosting',
              name: 'hosting',
              component: Hosting
            },
            {
              path: 'mailaliases',
              name: 'mailaliases',
              component: MailAliases
            },
            {
              path: 'mailaliases/new',
              name: 'mailaliasesnew',
              component: MailAliasesNew
            },
            {
              path: 'maildomains',
              name: 'maildomains',
              component: MailDomains
            },
            {
              path: 'maintenance',
              name: 'maintenance',
              component: Maintenance
            },
            {
              path: 'passwords',
              name: 'passwords',
              component: Passwords
            },
            {
              path: 'pstupload',
              name: 'pstupload',
              component: PSTUpload
            },
            {
              path: 'email',
              name: 'email',
              component: MailSettings
            },
            {
              path: 'backup',
              name: 'backup',
              component: BackupSettings
            },
            {
              path: 'backup/new',
              name: 'backupjobnew',
              component: BackupJobNew
            },
            {
              path: 'info',
              name: 'info',
              component: SystemInfo
            },
            {
              path: 'notifications',
              name: 'notifications',
              component: SystemNotifications
            },
            {
              path: 'licenses',
              name: 'licenses',
              component: Licenses
            },
            {
              path: 'reset',
              name: 'reset',
              component: Reset
            },
            {
              path: 'updates',
              name: 'updates',
              component: SystemUpdate
            },
            {
              path: 'authentication',
              name: 'authentication',
              component: AuthenticationSettings
            },
            {
              path: 'users',
              name: 'users',
              component: Users
            },
            {
              path: 'groups',
              name: 'groups',
              component: Groups
            },
            {
              path: 'users/new',
              name: 'usersnew',
              component: UserNew
            },
            {
              path: 'users/:id',
              name: 'user',
              component: UserEdit
            },
            {
              path: 'wallpapers',
              name: 'wallpapers',
              component: Wallpaper
            }
          ]
        },
        {
          path: '/manual/howto/:id',
          name: 'howto',
          component: ManualViewer
        },
        {
          path: '/manual/:id',
          name: 'manual',
          component: Manual
        },
        {
          path: '/manual/',
          name: 'manuals',
          component: Manual
        },
        {
          path: '/apps',
          name: 'apps',
          component: Home
        },
        {
          path: '*',
          name: 'home',
          component: Home
        }
        // catch all redirect
        //{ path: '*', redirect: '/apps' }
      ]
    }
  ]
});

// Manage authorization for the routes:

router.beforeEach((to, from, next) => {
  if (store.getters.isLoggedIn && !store.getters.needsTwoFactorConfirm) {
    store.commit('setMessageRefreshEnabled', true);
    Timers.setInterval(Timers.MESSAGES, () => {
      store.dispatch('GET_MESSAGES');
    }, 3000);
  } else {
    store.commit('setMessageRefreshEnabled', false);
    Timers.clearInterval(Timers.MESSAGES);
  }
  if (window.groupwareIsPresent === true) {
    try {
      window.groupwareBeforeRouterCallback({
        fullPath: to.fullPath,
        path: to.path,
        query: to.query,
        hash: to.hash,
        name: to.name || ""
      });
    } catch (e) {
      //eslint-disable-next-line no-console
      console.error("An error occurred while pushing before route event to the groupware", e);
    }
  }
  next();
});

router.afterEach((to, from) => {
  window.setTimeout(function () {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, 0);
  if (window.groupwareIsPresent === true) {
    try {
      window.groupwareAfterRouterCallback({
        fullPath: to.fullPath,
        path: to.path,
        query: to.query,
        hash: to.hash,
        name: to.name || ""
      });
    } catch (e) {
      //eslint-disable-next-line no-console
      console.error("An error occurred while pushing after route event to the groupware", e);
    }
  }
});

export default router;
