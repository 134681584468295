













































import { Vue, Component, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import User from "../model/User";
import { BModal } from "bootstrap-vue";

@Component({
  components: {
    LoadingButton,
    AnimatedInput
  }
})
export default class MailAliasesNew extends Vue {
  @Getter getUsers!: User[];
  @Getter getMailDomains!: string[];
  @Action GET_USERS!: () => Promise<any>;
  @Action ADD_ALIAS!: ({ alias, recipients }:
                       { alias: string, recipients: string[] }) => Promise<any>;

  @Prop(BModal) modal!: BModal;

  mailAliasPlaceholder: string = this.$pgettext("placeholder", "Mail alias");
  emailRegex: RegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

  mailDomain: string = ""; //this.getMailDomains[0] || "";
  mailAlias: string = "";
  addresses: string[] = [];

  get canCreate(): boolean {
    return Boolean(this.isValidMailAlias && this.mailDomain && this.addresses && (this.addresses.length > 0));
  }

  get isValidMailAlias(): boolean {
    return this.emailRegex.test(this.mailAlias + '@' + this.mailDomain);
  }

  createAlias(): Promise<any> {
    let finalAlias = this.mailAlias + '@' + this.mailDomain;

    return this.ADD_ALIAS({ alias: finalAlias, recipients: this.addresses }).then(() => {
      this.$snotify.success(this.$pgettext("notification", "New alias created"));
      if (this.modal) this.modal.hide();
      this.mailDomain = this.getMailDomains[0] || "";
      this.mailAlias = "";
      this.addresses = [];
    },
    error => {
      this.$snotify.error(this.$pgettext("server_message", error.response.data.message),
        this.$pgettext("notification", "Could not create new alias. An error occurred."));
    });
  }

  onAliasChanged(): void {
    this.mailAlias = this.mailAlias.replace('@', '.');
  }

  getUserMails(): string[] {
    let userMails: string[] = [];
    for (let user of this.getUsers as User[]) {
      if (user.internEmail) userMails.push(user.internEmail);
    }
    return userMails;
  }

  created() {
    this.GET_USERS();
  }

  mounted() {
      this.mailDomain = this.getMailDomains[0] || "";
  }
}
