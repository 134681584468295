




















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";
import App from "../model/App";
import LoadingImage from "../components/LoadingImage.vue";
import LoadingButton from "../components/LoadingButton.vue";
import VC from '../util/VersionComparator';
import StoreApp from '../model/StoreApp';
import ManualEntry from "@/model/ManualEntry";
import InfoBadge from "@/components/InfoBadge.vue";

@Component({
  components: { LoadingImage, LoadingButton, InfoBadge }
})
export default class AppGridItem extends Vue {
  @Prop(Object) app!: App;
  @Prop(Array) storeApps!: StoreApp[];
  @Prop(Object) manual!: ManualEntry;
  @Getter loggedInIsAdmin!: boolean;
  @Getter getStoreApps!: StoreApp[];
  @Action RESTART_APP!: (instanceId: string) => Promise<any>;
  @Action UNSET_ADMIN_MODE: any;
  dropDownTitle: string = this.$pgettext("title", "More");

  get displayStoreLink(): boolean {
    return this.loggedInIsAdmin && !this.app.isExtern && !this.app.isCustom && !!this.app.storeApp;
  }

  get isRunning(): boolean {
    return this.app.status === "RUNNING";
  }

  get storeApp(): StoreApp | null {
      let appId = this.app.storeId;
      let storeApp: StoreApp[] = this.storeApps.filter((storeApp: StoreApp) => {
        return storeApp.id === appId;
      });
      if (storeApp.length === 1) {
        return storeApp[0];
      } else {
        return null;
      }
  }

  get modalTitle(): string {
    let translated = this.$gettext('Restart app %{a}?');
    return this.$gettextInterpolate(translated, { a: this.app.title });
  }

  get needsUpdate(): boolean {
    if (this.hasProgress) {
        return false;
    }
    let installedVersion = this.app.appVersion as string;
    if (installedVersion && this.storeApp) {
        return VC.compareVersions(this.storeApp.version, installedVersion) > 0;
    } else {
        return false;
    }
  }

  get hasProgress(): boolean {
    let progress: string | null = this.app.status;
    return (progress === "INSTALLING" || progress === "UPDATING" || progress === "UNINSTALLING" || progress === "STARTING" || progress === "BUILDING_IMAGE");
  }

  get progressText(): string {
    let progress: string | null = this.app.status;
    switch (progress) {
      case 'INSTALLING':
        return this.$pgettext("app_state", "Installing app");
      case 'UPDATING':
        return this.$pgettext("app_state", "Updating app");
      case 'UNINSTALLING':
        return this.$pgettext("app_state", "Removing app");
      case 'STARTING':
        return this.$pgettext("app_state", "Starting app");
      case 'BUILDING_IMAGE':
        return this.$pgettext("app_state", "Building image");
      default:
        return this.$pgettext("app_state", "Please wait");
    }
  }

  restartApp(): Promise<any> {
    return this.RESTART_APP(this.app.instanceId as string).then(
      () => {
        this.$snotify.success(this.$pgettext("notification", "Restarting app"));
        this.$bvModal.hide('restartAppModal-' + this.app.instanceId);
      },
      error => {
        this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not restart app"));
        this.$bvModal.hide('restartAppModal-' + this.app.instanceId);
      }
    );
  }

  get appUrl(): string {
    return 'https://' + this.app.host;
  }

  openInNewTab(): void {
    window.open(this.appUrl, '_blank');
  }

  goToApp(): void {
    if (!this.isRunning) {
      this.$snotify.error(this.$pgettext("notification", "Please wait until the app is ready."), this.progressText);
      return;
    }

    if (this.app.storeApp && this.app.storeApp.noWebUI) {
      this.$snotify.error(this.$pgettext("notification", "This app has no Interface you can visit."), this.$gettext("Error"));
      return;
    }

    if (this.app.storeApp &&
        this.app.storeApp.addons &&
        this.app.storeApp.addons.embed &&
        this.app.storeApp.addons.embed.iframe) {

      this.UNSET_ADMIN_MODE();
      this.$router.push('/app/' + this.app.instanceId);
    } else {
      this.openInNewTab();
    }
  }

  goToStoreApp(): void {
    this.$router.push('/store/' + this.app.storeId);
  }

  goToAppManual(): void {
    this.$router.push('/manual/' + this.app.storeId);
  }
};
