









import { Vue, Component, Prop } from 'vue-property-decorator';
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import LoadingImage from "@/components/LoadingImage.vue";

@Component({
  components: {
    AnimatedInput,
    LoadingButton,
    LoadingImage
  }
})
export default class SelectableListItem extends Vue {

  @Prop(Object)
  option!: any;

  @Prop(Boolean)
  disable!: boolean;

  @Prop(Array)
  selected!: any[];

  @Prop(String)
  primaryKey!: string;

  $refs!: {};

  get isSelected(): boolean {
    return this.selected && this.option[this.primaryKey] && this.selected.indexOf(this.option[this.primaryKey]) >= 0;
  }

  get check(): boolean {
    return this.isSelected;
  }

  set check(value: boolean) {
    //Process check event
    if (this.isSelected !== value) {
      this.$emit('change', { primaryKey: this.option[this.primaryKey], value: value });
    }
  }
}
