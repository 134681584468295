




























































































































































import { Vue, Component, Ref } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import SeafileLibrary from "@/model/SeafileLibrary";
import NetworkShare from "@/model/NetworkShare";
import SelectableList from "@/components/SelectableList.vue";
import LoadingImage from "@/components/LoadingImage.vue";
import User from "@/model/User";
import { FormWizard } from "vue-form-wizard";
import LibraryProgress from "@/model/LibraryProgress";
import App from "@/model/App";
import Timers from "@/util/timers";

@Component({
  components: {
    LoadingButton,
    AnimatedInput,
    SelectableList,
    LoadingImage
  }
})
export default class NetworkShares extends Vue {

  nameLabel: string = this.$pgettext("networkshare", "Name");

  @Action GET_NETWORK_SHARES!: () => Promise<NetworkShare[]>;
  @Action ADD_NETWORK_SHARE!: (networkShare: NetworkShare) => Promise<NetworkShare>;
  @Action UPDATE_NETWORK_SHARE!: (networkShare: NetworkShare) => Promise<NetworkShare>;
  @Action DELETE_NETWORK_SHARE!: (id: string) => Promise<any>;
  @Action GET_SEAFILE_LIBRARIES!: () => Promise<SeafileLibrary[]>;
  @Action GET_USERS!: () => Promise<User[]>;
  @Action GET_INSTALLED_APPS!: () => Promise<App[]>;

  @Getter getApps!: App[];
  @Getter getLibraries!: SeafileLibrary[];
  @Getter getNetworkShares!: NetworkShare[];
  @Getter getUsers!: User[];

  modalTitle: string = this.$gettext('Add new network share');

  availableProtocols: string[] = [ 'SMB' ];
  aclOptions: any[] = [
    {
      text: this.$pgettext("networkshare", "Allow access to selected users"),
      id: 'User'
    },
    {
      text: this.$pgettext("networkshare", "Allow access to all users"),
      id: 'Everyone'
    },
    {
      text: this.$pgettext("networkshare", "Allow access without authentication"),
      id: 'Guest'
    }
  ];

  edited: NetworkShare | null = null;
  shareName: string | null = null;
  selectedLibrary: SeafileLibrary | null = null;
  selectedProtocols: string[] = [ 'SMB' ];
  aclOption: string = 'User';
  selectedUsers: string[] = [];

  @Ref('addNetworkShareWizard') addNetworkShareWizard!: FormWizard;

  get seafileInstalled(): boolean {
    return Boolean(this.getApps && this.getApps.find(app => {
      return Boolean(app.storeId && (app.storeId.startsWith('de.uniki.seafile') || app.storeId.startsWith('de.uniki.files')));
    }));
  }

  get hasNetworkSharesConfigured(): boolean {
    return !!this.getNetworkShares && this.getNetworkShares.length > 0;
  }

  openAddNetworkShareModal() {
    this.modalTitle = this.$gettext('Add new network share');
    this.edited = null;
    this.$bvModal.show('editNetworkShareModal');
    this.$nextTick(() => {
      this.addNetworkShareWizard.reset();
    });
  }

  openEditNetworkShareModal(networkShare: NetworkShare) {
    this.modalTitle = this.$gettext('Edit network share configuration');
    this.shareName = networkShare.name;
    if (networkShare.libraryId && this.getLibraries) {
      this.selectedLibrary = this.getLibraries.find((lib: SeafileLibrary) => lib.id === networkShare.libraryId) || null;
    } else {
      this.selectedLibrary = null;
    }
    if (networkShare.types) {
      this.selectedProtocols = networkShare.types;
    } else {
      this.selectedProtocols = [];
    }
    if (networkShare.guestOk) {
      this.aclOption = 'Guest';
    } else if (networkShare.acl && networkShare.acl.find(acl => acl.indexOf('Everyone') >= 0)) {
      this.aclOption = 'Everyone';
    } else {
      this.aclOption = 'User';
      if (networkShare.acl && this.getUsers) {
        this.selectedUsers = [];
        for (let acl of networkShare.acl) {
          let user: User | undefined = this.getUsers.find(user => !!(user.uid && acl.startsWith(user.uid)));
          if (user && user.uid) {
            this.selectedUsers.push(user.uid);
          }
        }
      }
    }
    this.edited = networkShare;
    this.$bvModal.show('editNetworkShareModal');
    this.$nextTick(() => {
      this.addNetworkShareWizard.reset();
    });
  }

  addOrUpdateNetworkShare(): Promise<any> {
    let networkShare: NetworkShare = this.edited || new NetworkShare();
    networkShare.name = this.shareName;
    if (this.selectedLibrary) networkShare.libraryId = this.selectedLibrary.id;
    networkShare.types = this.selectedProtocols;
    if (this.aclOption === 'User') {
      networkShare.acl = this.selectedUsers.map((uid: string) => { return uid + ':F'; });
      networkShare.guestOk = false;
    } else if (this.aclOption === 'Everyone') {
      networkShare.acl = [ 'Everyone:F' ];
      networkShare.guestOk = false;
    } else if (this.aclOption === 'Guest') {
      networkShare.guestOk = true;
    }
    let task: Promise<any>;
    if (this.edited) {
      task = this.UPDATE_NETWORK_SHARE(networkShare).then(() => {
        this.$snotify.success(this.$pgettext("notification", "The network share configuration was updated."));
      }).catch(error => {
        this.$snotify.error(this.$pgettext("server_message", error.response.data.message),
          this.$pgettext("notification", "Could not update the network share configuration. An error occurred."));
      });
    } else {
      task = this.ADD_NETWORK_SHARE(networkShare).then(() => {
        this.$snotify.success(this.$pgettext("notification", "The network share was created."));
      }).catch(error => {
        this.$snotify.error(this.$pgettext("server_message", error.response.data.message),
          this.$pgettext("notification", "Could create the network share. An error occurred."));
      });
    }
    return task.then(() => { //Only reset if it goes well, otherwise let the user try again.
      this.$bvModal.hide('editNetworkShareModal');
      this.shareName = null;
      this.selectedLibrary = null;
      this.selectedProtocols = [];
      this.selectedUsers = [];
    });
  }

  deleteNetworkShare(id: string): Promise<any> {
    return this.DELETE_NETWORK_SHARE(id).then(() => {
      this.$snotify.success(this.$pgettext("notification", "Network share deleted"));
    }).catch(error => {
      this.$snotify.error(this.$pgettext("server_message", error.response.data.message),
        this.$pgettext("notification", "Could not delete network share. An error occurred."));
    }).finally(() => {
      this.$bvModal.hide('deleteNetworkShareModal-' + id);
    });
  }

  getLibraryName(id: string): string | null {
    let library: SeafileLibrary | undefined = this.getLibraries.find(lib => lib.id === id);
    if (library) {
      return library.name;
    } else {
      return null;
    }
  }

  getStateText(progress: LibraryProgress): string | null {
    if (progress) {
      let text: string = '';
      if (progress.state) {
        text += this.$gettext(progress.state);
      }
      if (progress.errors != null && progress.errors.length > 0) {
        text += '(' + progress.errors.join(', ') + ')';
      } else if (progress.progress != null && progress.speedInKBytePerSec != null) {
        text += ' (' + progress.progress + '%, ' + progress.speedInKBytePerSec + 'KB/s)';
      } else if (progress.progress != null) {
        text += ' (' + progress.progress + '%)';
      } else if (progress.speedInKBytePerSec != null) {
        text += ' (' + progress.speedInKBytePerSec + 'KB/s)';
      }
      return text;
    } else {
      return null;
    }
  }

  created() {
    this.GET_NETWORK_SHARES().finally(() => {
      Timers.setInterval(Timers.SHARES, () => {
        this.GET_NETWORK_SHARES();
      }, 1000);
    });
    this.GET_USERS();
    this.GET_INSTALLED_APPS().finally(() => {
      if (this.seafileInstalled) {
        this.GET_SEAFILE_LIBRARIES();
      }
    });
  }

  beforeDestroy () {
    Timers.clearInterval(Timers.SHARES);
  }
}
