




































import { Vue, Component, Prop } from 'vue-property-decorator';
import ManualGridItem from "../components/ManualGridItem.vue";
import ManualEntry from '../model/ManualEntry';
import { Getter } from "vuex-class";
import App from "@/model/App";

class ManualDropdownItem {
  label: String | null = "";
  code: String | null = "";
}

@Component({
  components: { ManualGridItem }
})
export default class Manual extends Vue {
  getManuals: ManualEntry[] = [];

  @Getter getApps!: App[];

  appFilter: ManualDropdownItem | null = null;

  get getFilteredManuals(): ManualEntry[] {
    if (!this.appFilter) {
      return this.getManuals;
    }

    let filtered: ManualEntry[] = [];
    for (let entry of this.getManuals) {
      if (entry.app === this.appFilter.code) {
        filtered.push(entry);
      }
    }
    return filtered;
  }

  get availableApps(): string[] {
    let apps: string[] = [];
    apps = this.getManuals.map(x => x.app as string);

    return [...new Set(apps)]; //removing duplicates
  }

  get dropdownOptions(): ManualDropdownItem[] {
    const result: ManualDropdownItem[] = [];

    const manuals = this.getManuals;

    for (let man of manuals) {
      const opt = new ManualDropdownItem();
      opt.label = man.appReference ? man.appReference.title : "";
      opt.code = man.app;

      if (opt.label && opt.code && result.filter((item: ManualDropdownItem) => { return item.code === opt.code; }).length === 0) {
        result.push(opt);
      }
    }
    return result;
  }

  get manualsAreFiltered(): boolean {
    return this.appFilter !== null;
  }

  beforeMount() {
    let appId = this.$router.currentRoute.params.id;
    import('../assets/manpages/de/index.json').then((indexJson: {default:{[key: string]: any}}) => {
      let allManuals: ManualEntry[] = [];
      for (let manual in indexJson.default) {
        allManuals.push(indexJson.default[manual]);
      }

      let installedAppManuals: ManualEntry[] = [];
      for (let manualEntry of allManuals) {
        if (manualEntry.app) {
          const appsMatching: App[] = this.getApps.filter((app: App) => {
            return app.storeId === manualEntry.app;
          });
          if (appsMatching.length > 0) {
            manualEntry.appReference = appsMatching[0];
            installedAppManuals.push(manualEntry);
          }
        } else {
          installedAppManuals.push(manualEntry);
        }
      }
      //Write Manuals
      this.getManuals = installedAppManuals;

      //Check those manuals for the option we want:
      for (let opts of this.dropdownOptions) {
        if (opts.code === appId) {
          this.appFilter = opts;
        }
      }
    });
  }
};
