

























import { Vue, Component, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import Message from "@/model/Message";
import SetupElly from "@/components/SetupElly.vue";
import StateInfo from "@/components/StateInfo.vue";
import Login from "@/views/Login.vue";
import Repair from "@/components/Repair.vue";

@Component({
  components: { SetupElly, Login, StateInfo, Repair }
})
export default class App extends Vue {
  @Getter isLoggedIn!: boolean;
  @Getter getStatus!: any | null;
  @Getter getMessages!: Message[];
  @Getter getNotifications!: Message[];
  @Getter getProgressMessages!: Message[];
  @Getter getDisplayedNotifications!: Message[];

  @Mutation addDisplayedNotification!: (message: Message) => any;

  get running() {
    return this.getStatus && (this.getStatus.status === 'RUNNING' || this.getStatus.status === 'DATA_LOCKED');
  }

  get recovery() {
    return this.getStatus && this.getStatus.status === 'RECOVERY';
  }

  get setup() {
    return this.getStatus && this.getStatus.status === 'WAITING_FOR_SETUP';
  }

  @Watch('getNotifications')
  onMessagesChanged (newVal: Message[] | null | undefined, oldVal: Message[] | null | undefined) {
    if (newVal) {
      for (let newMessage of newVal) {
        let oldMessage = this.getDisplayedNotifications.find((message: Message) => {
          return newMessage.id === message.id;
        });

        if (!oldMessage) {
          if (newMessage.status === 'OK' && newMessage.text) {
            this.$snotify.info(this.$gettext(newMessage.text));
          } else if (newMessage.status === 'ERROR' && newMessage.text) {
            this.$snotify.error(this.$gettext(newMessage.text));
          } else if (newMessage.text) {
            this.$snotify.warning(this.$gettext(newMessage.text));
          }

          this.addDisplayedNotification(newMessage);
        }
      }
    }
  }
};

