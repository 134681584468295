import Subscription from "@/model/invoice/Subscription";
import StoreApp from "@/model/StoreApp";
import ProductMapping from "@/model/invoice/ProductMapping";
import Product from "@/model/invoice/Product";

export default class SubscriptionUpgrade {
    subscription!: Subscription | null
    productMapping!: ProductMapping | null
    product!: Product | null
    storeApp!: StoreApp | null
    seatsRequired!: number
    seatsActive!: number
    seatsToBuy!: number
    price!: number
    taxRate!: number
}
