import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios, { AxiosResponse } from "axios/index";
import Group from "@/model/Group";
import User from "@/model/User";
import App from "@/model/App";

const url: string = '/api/v1/groups';

@Module
export default class GroupStore extends VuexModule {
  groups: Group[] = [];
  loading: boolean = false;

  get getGroups () : Group[] {
    return this.groups;
  }

  @Mutation
  setGroups (groups: Group[]) {
    this.groups = groups;
  }

  @Mutation setGroupsAreLoading (loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  addOrReplaceGroup (newGroup: Group) {
    let oldGroup = this.groups.find((value: Group) => {
      return value.cn === newGroup.cn;
    });

    if (oldGroup !== undefined) {
      this.groups = this.groups.map((value: Group) => {
        if (value.cn === newGroup.cn) {
          return newGroup;
        } else {
          return value;
        }
      });
    } else {
      this.groups.push(newGroup);
    }
  }

  @Mutation
  deleteGroup(cn: string) {
    let toDelete = this.groups.find((value: Group) => {
      return value.cn === cn;
    });

    if (toDelete !== undefined) {
      let index = this.groups.indexOf(toDelete);
      if (index > -1) {
        this.groups.splice(index, 1);
      }
    }
  }

  @Action({ commit: 'setGroups', rawError: true })
  GET_GROUPS() {
    this.context.commit("setGroupsAreLoading", true);
    return axios.get(url).then((response) => {
      return response.data ? response.data.map((group: Group) => Object.assign(new Group(), group)) : [];
    });
  }

  @Action({ commit: 'addOrReplaceGroup', rawError: true })
  GET_GROUP(cn: string) {
    const encodedCn = encodeURIComponent(cn);
    return axios.get(url + '/' + encodedCn).then((response) => {
      return Object.assign(new Group(), response.data);
    });
  }

  @Action({ commit: 'addOrReplaceGroup', rawError: true })
  CREATE_GROUP(group: Group) {
    return axios.post(url, group).then((response) => {
      return Object.assign(new Group(), response.data);
    });
  }

  @Action({ commit: 'addOrReplaceGroup', rawError: true })
  REPLACE_GROUP({ cn, group }: { cn: string, group: Group }) {
    const encodedCn = encodeURIComponent(cn);
    axios.put(url + '/' + encodedCn, group).then((response) => {
      return Object.assign(new Group(), response.data);
    });
  }

  @Action({ commit: 'addOrReplaceGroup', rawError: true })
  UPDATE_GROUP({ cn, group }: { cn: string, group: Group }) {
    const encodedCn = encodeURIComponent(cn);
    return axios.patch(url + '/' + encodedCn, group).then((response) => {
      return Object.assign(new Group(), response.data);
    });
  }

  @Action({ commit: 'deleteGroup', rawError: true })
  DELETE_GROUP(cn: string) {
    const encodedCn = encodeURIComponent(cn);
    return axios.delete(url + '/' + encodedCn).then((response) => {
      return cn;
    });
  }

  @Action({ commit: 'addOrReplaceGroup', rawError: true })
  ADD_USER_TO_GROUP({ cn, user }: { cn: string, user: User }) {
    const encodedCn = encodeURIComponent(cn);
    return axios.post(url + '/' + encodedCn + '/members', user).then((response) => {
      return Object.assign(new Group(), response.data);
    });
  }

  @Action({ commit: 'addOrReplaceGroup', rawError: true })
  REMOVE_USER_FROM_GROUP({ cn, uid }: { cn: string, uid: string }) {
    const encodedCn = encodeURIComponent(cn);
    const encodedUid = encodeURIComponent(uid);
    return axios.delete(url + '/' + encodedCn + '/members/' + encodedUid).then((response) => {
      return this.context.dispatch('GET_GROUP', cn);
    });
  }
}
