import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import AdminMode from "@/model/AdminMode";
import { Getter } from "vuex-class";
import App from "@/model/App";
import StoreApp from "@/model/StoreApp";

@Module
export default class TourStore extends VuexModule {

  getApps!: App[];
  getStoreApps!: StoreApp[];

  get tours(): Step[][] {

    let tours: Step[][] = [];
    tours.push(this.kimai);
    return tours;
  };

  get kimai(): Step[] {
    let app: App | undefined = this.getApps.find(a => a.storeId === 'de.uniki.kimai');
    let storeApp: StoreApp | undefined = this.getStoreApps.find(a => a.id === 'de.uniki.kimai');

    let steps: Step[] = [];

    if (!app && storeApp) {
      steps.push({
        attachTo: { element: '#navbar-menu-store' },
        title: 'Zeiterfassung',
        text: 'Kimai ist nicht installiert. Gehen Sie zum Store.',
        advanceOn: { selector: '#navbar-menu-store', event: 'click' }
      });
      steps.push({
        attachTo: { element: '#de.uniki.kimai' },
        title: 'Kimai installieren',
        text: 'Klicken Sie auf Kimai.',
        advanceOn: { selector: '#de.uniki.kimai', event: 'click' }
      });
    }

    steps.push({
      attachTo: { element: '#de_uniki_bitwarden_bitwarden' },
      title: 'Test',
      text: '<iframe width="560" height="315" src="https://www.youtube.com/embed/trVIIgHlRVo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
      buttons: [
        {
          action(): any {
            return this.back();
          },
          classes: 'shepherd-button-secondary',
          text: 'Back'
        },
        {
          action(): any {
            return this.next();
          },
          text: 'Next'
        }
      ]
    });

    return steps;
  }
};

interface Step {
  attachTo?: any;
  title?: string;
  text?: string;
  advanceOn?: any;
  buttons?: any[];
}
