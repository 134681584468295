import { Module, VuexModule, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import FeedbackModel from "@/model/FeedbackModel";
import Message from "@/model/Message";

const url: string = '/api/v1/oauth/authorize';

@Module
export default class OAuth2Store extends VuexModule {

  @Action({ rawError: true })
  OAUTH2_AUTHORIZE({ clientId, responseType, oauthRedirectURI }: { clientId: string, responseType: string, oauthRedirectURI: string }) {
    let thisUrl = url;
    if (clientId) {
      thisUrl += '?client_id=' + encodeURIComponent(clientId);
    }
    if (responseType) {
      thisUrl += (clientId ? '&' : '?') + 'response_type=' + encodeURIComponent(responseType);
    }
    if (oauthRedirectURI) {
      thisUrl += ((clientId || responseType) ? '&' : '?') + 'oauthRedirectURI=' + encodeURIComponent(oauthRedirectURI);
    }

    return axios.get(thisUrl).then((response) => {
      if (typeof response.data.location === 'string') {
        return response.data.location;
      }
      return '';
    });
  }
}
