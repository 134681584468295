import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import TwoFactorRequest from "@/model/TwoFactorRequest";

const url: string = '/api/v1/twofactor';

@Module
export default class TwoFactorStore extends VuexModule {

  @Action({ rawError: true })
  CHECK_TOKEN(request: TwoFactorRequest) {
    return axios.post(url, request);
  }
};
