
























































import { Vue, Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import AnimatedInput from "../components/AnimatedInput.vue";
import DataImport from "@/views/DataImport.vue";
import WallpaperEntity from "@/model/WallpaperEntity";
import WallpaperThumbnail from "@/components/WallpaperThumbnail.vue";
import LoadingImage from "@/components/LoadingImage.vue";

@Component({
  components: {
    LoadingImage,
    DataImport,
    AnimatedInput,
    WallpaperThumbnail
  }
})
export default class Wallpaper extends Vue {
  @Getter getAllWallpapers!: WallpaperEntity[];
  @Action GET_ALL_WALLPAPERS!: () => Promise<any>;
  @Action UPLOAD_USER_WALLPAPER!: (file: File) => Promise<any>;
  @Action UNSET_USER_WALLPAPER!: () => Promise<any>;
  @Action GET_USER_CONFIG_VALUES!: () => Promise<any>;

  //Status controlling:
  acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  isUploading: boolean = false;
  isLoading: boolean = true;

  get hasWallpaper(): boolean {
    return !!(this.getAllWallpapers && this.getAllWallpapers.length);
  }

  async setWallpaper (image: File) {
    if (this.isFileImage(image)) {
      this.isUploading = true;
      return this.UPLOAD_USER_WALLPAPER(image).then(() => {
          this.$snotify.success(this.$pgettext("notification", "Wallpaper picture uploaded successfully"));
        }
      ).catch((error) => {
        this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not upload wallpaper"));
      }).finally(() => {
        this.isUploading = false;
      });
    } else {
      this.$snotify.error(this.$pgettext("notification", "No Image found"));
    }
  }

  isFileImage (file: File) {
    return file && this.acceptedImageTypes.includes(file['type']);
  }

  resetToDefault() {
    this.UNSET_USER_WALLPAPER().finally(() => {
      this.GET_USER_CONFIG_VALUES();
    });
  }

  created() {
    this.GET_ALL_WALLPAPERS().finally(() => {
      this.isLoading = false;
    });
  }
}
