








import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class AnimatedInput extends Vue {
  @Prop(String) label!: string;
  @Prop(String) placeholder!: string;
  @Prop() value!: string;
  @Prop(Boolean) inline!: boolean;
  @Prop(String) autocomplete!: string;
  @Prop(String) error!: string;
  @Prop(String) type!: string;
  @Prop(Boolean) disabled!: boolean;
  @Prop(String) name!: string;
  @Prop(Boolean) autofocus!: boolean;

  focused: boolean = false;
  internalValue: string = this.value || '';

  $refs!: {
    myinput: HTMLInputElement;
  };

  get animationClass (): string {
    if (this.focused || (this.internalValue && this.internalValue !== '')) {
      return 'ai-filled' + this.errorClass;
    } else {
      return '' + this.errorClass;
    }
  }

  get labelClass (): string {
    return (this.inline ? "input-label-inline" : "input-label");
  }

  get errorClass(): string {
    if (this.error) {
      if (this.focused) {
        return ' border-danger';
      } else {
        return ' border-danger danger';
      }
    } else {
      return '';
    }
  }

  handleInput () {
    this.$emit('input', this.internalValue);
  }

  handleFocus () {
    this.focused = true;
    this.$emit('focus');
  }

  handleBlur () {
    this.focused = false;
    this.$emit('blur');
  }

  checkAutofill(e : AnimationEvent) {
    if (e.animationName === "onAutoFillStart") this.$emit("autofill", true);
    else if (e.animationName === "onAutoFillCancel") this.$emit("autofill", false);
  }

  @Watch('value')
  onValueChanged (val: string, oldVal: string) {
    this.internalValue = val;
  }

  //Do things when we're in the dom:
  mounted () {
    if (this.autofocus) {
      this.$refs.myinput.focus();
    }
  }
};
