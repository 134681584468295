










































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter, Mutation } from "vuex-class";
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import User from '../model/User';
import natoAlphabet from "@/util/natoAlphabet";

let alphabet: any = natoAlphabet;

@Component({
  components: { AnimatedInput, LoadingButton }
})
export default class ConfirmResetPin extends Vue {
  @Getter getUser!: User;
  @Action DELETE_MESSAGE!: (id: string) => Promise<any>;
  @Mutation removePasswordResetPin!: () => any;

  seenPin: boolean = false;
  pinRepeat: string = "";

  get canConfirm(): boolean {
    return this.pinRepeat === this.getUser.passwordResetPin;
  }

  get natoCodeForPuk(): string {
    let result = "";

    if (this.getUser.passwordResetPin && this.getUser.passwordResetPin.length > 0) {
      for (let thisChar of this.getUser.passwordResetPin) {
        if (/^\d+$/.test(thisChar)) {
          result += thisChar; //Add Numbers
        } else {
          result += (alphabet[thisChar.toUpperCase()] as String);
        }

        result += " ";
      }
    }

    return result.trim();
  }

  markUnSeen(): void {
    this.seenPin = false;
  }

  markSeen(): void {
    this.seenPin = true;
  }

  confirm(): Promise<any> {
    return this.DELETE_MESSAGE(this.getUser.uid as string).then(() => {
      this.removePasswordResetPin();
      //Always go to home after puk confirm:
      this.$router.push("/");
    });
  }
};
