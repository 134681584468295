export default class CacheUtil {

  static imageCache: Map<string, Blob> = new Map<string, Blob>();
  static cache: Cache;

  static async init(): Promise<string[]> {
    CacheUtil.cache = await caches.open('elly-image-cache');
    let cached: ReadonlyArray<Request> = await CacheUtil.cache.keys();
    return cached.map((request: Request) => { return request.url; });
  }

  static put(url: string, blob: Blob): Promise<void> {
    if (CacheUtil.cache) {
      return CacheUtil.cache.put(url, new Response(blob)).catch(() => {
        CacheUtil.imageCache.set(url, blob);
      });
    } else {
      CacheUtil.imageCache.set(url, blob);
      return Promise.resolve();
    }
  }

  static get(url: string): Promise<Blob | undefined> {
    if (CacheUtil.cache) {
      return CacheUtil.cache.match(url).then(response => {
        if (response) {
          return response.blob();
        } else {
          return Promise.resolve(CacheUtil.imageCache.get(url));
        }
      }).catch(() => {
        return Promise.resolve(CacheUtil.imageCache.get(url));
      });
    } else {
      return Promise.resolve(CacheUtil.imageCache.get(url));
    }
  }
}
