






















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from "vuex-class";
import Password from "../model/Password";

@Component({
  components: {}
})
export default class PasswordGridItem extends Vue {
  @Prop(Object) pw!: Password;

  isVisible: boolean = false;
  delaySeconds: number = 10;
  currentSecond: number = 0;

  copyToClipboard(): void {

  }

  get buttonText(): string {
    if (this.currentSecond === 0) return "";
    return this.currentSecond.toString();
  }

  get getInputType(): string {
    return (this.isVisible ? "text" : "password");
  }

  showPassword(): void {
    if (this.isVisible) return;
    this.isVisible = true;
    this.startTimer().then(() => {
      this.isVisible = false;
    });
  }

  async startTimer(): Promise<any> {
    this.currentSecond = this.delaySeconds;
    for (let i = 0; i < this.delaySeconds; i++) {
      await new Promise(resolve => {
        setTimeout(resolve, 1000);
      });
      this.currentSecond = this.currentSecond - 1;
    }
  }
};
