import LibraryProgress from '@/model/LibraryProgress';

export default class SyncConfig {
  url!: string | null;
  user!: string | null;
  password!: string | null;
  libraryId!: string | null;
  path!: string | null;
  state!: LibraryProgress | null;

  constructor() {
    this.url = null;
    this.user = null;
    this.password = null;
    this.libraryId = null;
    this.path = null;
    this.state = null;
  }
}
