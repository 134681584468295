import router from './router';
import { store } from './store';
import axios from 'axios';
import Vue from "vue";
import App from "@/App.vue";
import VueFormWizard from "vue-form-wizard";
import VueShepherd from "vue-shepherd";
import SnotifyPlugin, { SnotifyPosition } from "vue-snotify";
import GetTextPlugin from "vue-gettext";
import { de_DE } from "@/locale/include/de_DE.json";
import { en_US } from "@/locale/include/en_US.json";
import { extend, localize, ValidationObserver, ValidationProvider } from "vee-validate";
import * as validationRules from "vee-validate/dist/rules";
import BootstrapVue from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import vSelect from "vue-select";
import veeValidate_de from 'vee-validate/dist/locale/de.json';
import { getBrowserSelectedLanguageForI18N } from "@/util/language";

Vue.use(VueFormWizard);
Vue.use(VueShepherd);

// Doesn't work with app icons
// Setup the base path:
// axios.defaults.baseURL = "/api/v1/";

// Setup notifications:
const notifyOptions = {
  toast: {
    position: SnotifyPosition.rightTop,
    timeout: 6000,
    titleMaxLength: 50,
    bodyMaxLength: 250
  }
};

const translations: any = { de_DE, en_US };

Vue.use(GetTextPlugin, {
  availableLanguages: {
    en_US: 'American English',
    de_DE: 'Deutsch'
  },
  defaultLanguage: getBrowserSelectedLanguageForI18N(translations),
  translations
});

//Globally register validation library
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
//Load all validation rules:
// with typescript
for (let [ rule, validation ] of Object.entries(validationRules)) {
  extend(rule, {
    ...validation
  });
}
//Load german translation and set it:
localize({ 'de_DE': veeValidate_de });
localize(getBrowserSelectedLanguageForI18N(translations));

Vue.use(BootstrapVue);
Vue.use(SnotifyPlugin, notifyOptions);

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
// @ts-ignore
Vue.component('v-select', vSelect);

// @ts-ignore
Apex.colors = [ '#47BBC7', '#81C17E', '#EEE082', '#E94B50', '#686971' ];

Vue.config.productionTip = false;

const vue = new Vue({
  router: router,
  store: store,
  render: function (h) { return h(App); }
});

axios.interceptors.request.use(function (config: any) {
  let isBackendRequest: boolean = config.url.startsWith('/') ||
    config.url.startsWith(window.location.protocol + '//' + window.location.host);
  if (store.state.session.authToken && isBackendRequest) {
    config.headers['X-Auth-Token'] = store.state.session.authToken;
  }
  return config;
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const code = error.response.status;
  const text = error.response.data.message;
  if (code === 400 && text.indexOf('Demo') >= 0) {
    vue.$bvModal.show('modaldemo');
  } else if (code === 401) {
    store.dispatch('LOGOUT_REQUEST');
  } else if (code === 412) { //Admin Mode expired / was deleted in another session
    store.dispatch('UNSET_ADMIN_MODE');
  }
  return Promise.reject(error);
});

//@ts-ignore
window.loadAdminpanel = () => {
  const app = document.getElementById("app");
  if (app) {
    vue.$mount(document.createElement("div"));
    app.appendChild(vue.$el);
    return {
      el: app,
      vue: vue,
      router: router,
      store: store
    };
  } else {
    return null;
  }
};
