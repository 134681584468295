



























import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import LoadingButton from "../components/LoadingButton.vue";
import { Action, Getter } from "vuex-class";
import Group from "@/model/Group";
import LoadingImage from "@/components/LoadingImage.vue";
import { BModal } from "bootstrap-vue";
import User from "@/model/User";
import SelectableList from "@/components/SelectableList.vue";
import UpgradeAppWizard from "@/components/UpgradeAppWizard.vue";
import SimpleGroupEditModal from "@/components/SimpleGroupEditModal.vue";

@Component({
  components: {
    UpgradeAppWizard,
    LoadingButton,
    LoadingImage,
    SelectableList,
    SimpleGroupEditModal
  }
})
export default class SimpleGroupOverviewItem extends Vue {

  @Getter getGroups!: Group[];

  @Action GET_USERS!: () => Promise<User[]>;
  @Action DELETE_GROUP!: (cn: string) => Promise<void>;

  @Prop(Boolean)
  isLoading!: boolean;

  @Prop(String)
  groupDn!: string;

  @Prop(Boolean)
  canDelete!: boolean;

  systemGroupDescriptions = [
    {
      dn: 'cn=admin,ou=Groups,dc=uniki,dc=de',
      cn: "admin",
      name: this.$gettext("Administrator"),
      description: this.$gettext("May configure the system, install and uninstall apps.")
    },
    {
      dn: 'cn=signing,ou=Groups,dc=uniki,dc=de',
      cn: "signing",
      name: this.$gettext("Manager"),
      description: this.$gettext("May obtain subscriptions for services, install apps and edit app groups.")
    }
  ];

  $refs!: {
    editModal: BModal;
  };

  get group(): Group | null {
    if (this.groupDn && this.getGroups) {
      return this.getGroups.find(group => group.dn === this.groupDn) || null;
    } else {
      return null;
    }
  }

  get name(): string {
    if (this.group) {
      let description = this.systemGroupDescriptions.find(description => Boolean(this.group && this.group.cn === description.cn));
      return description ? description.name : (this.group.cn || '');
    } else {
      return '';
    }
  }

  get description(): string {
    //Description is now used as the human-readable goup name
    if (this.group && this.group.description) {
      return this.group.description;
    } else if (this.group) {
      return this.name;
    } else {
      return '';
    }
  }

  get uniqueMember(): string[] {
    return (this.group && this.group.uniqueMember) ? this.group.uniqueMember : [];
  }

  openGroupEditModal(): Promise<any> {
    return this.GET_USERS().finally(() => {
      this.$refs.editModal.show();
    });
  }

  deleteGroup(): Promise<void> {
    if (this.group && this.group.cn) {
      return this.DELETE_GROUP(this.group.cn).then(() => {
        this.$snotify.success(this.$pgettext("notification", "Group deleted."));
      }).catch(error => {
        this.$snotify.error(error.response.data.message, this.$pgettext("notification", "Could not deleted group."));
      });
    } else {
      return Promise.resolve();
    }
  }
};
