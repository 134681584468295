































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";
import MessageGridItem from "../components/MessageGridItem.vue";
import Message from '../model/Message';

@Component({
  components: { MessageGridItem }
})
export default class Messages extends Vue {
  @Getter getNotifications!: Message[];

};
