import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import NetworkShare from "@/model/NetworkShare";
import Group from "@/model/Group";

const url: string = '/api/v1/networkshares';

@Module
export default class NetworkShareStore extends VuexModule {
  networkShares: NetworkShare[] = [];
  loading: boolean = false;
  error: boolean = false;

  get getNetworkShares () : NetworkShare[] {
    return this.networkShares;
  }

  @Mutation
  setNetworkShares (networkShares: NetworkShare[]) {
    this.networkShares = networkShares;
  }

  @Mutation
  addNetworkShare (networkShare: NetworkShare) {
    this.networkShares.push(networkShare);
  }

  @Mutation
  replaceNetworkShare (networkShare: NetworkShare) {
    this.networkShares = this.networkShares.map((share: NetworkShare) => {
      if (share.id === networkShare.id) {
        return networkShare;
      } else {
        return share;
      }
    });
  }

  @Mutation
  deleteNetworkShare (id: string) {
    let toDelete = this.networkShares.find((value: NetworkShare) => {
      return value.id === id;
    });

    if (toDelete !== undefined) {
      let index = this.networkShares.indexOf(toDelete);
      if (index > -1) {
        this.networkShares.splice(index, 1);
      }
    }
  }

  @Mutation
  setNetworkShareStoreState ({ loading, error }: { loading: boolean, error: boolean }) {
    this.loading = loading;
    this.error = error;
  }

  @Action({ commit: 'setNetworkShares', rawError: true })
  GET_NETWORK_SHARES() {
    this.context.commit("setNetworkShareStoreState", { loading: true, error: false });
    return axios.get(url).then((response) => {
      this.context.commit("setNetworkShareStoreState", { loading: false, error: false });
      return response.data ? response.data.map((share: NetworkShare) => Object.assign(new NetworkShare(), share)) : [];
    }).catch(() => {
      this.context.commit("setNetworkShareStoreState", { loading: false, error: true });
    });
  }

  @Action({ commit: 'addNetworkShare', rawError: true })
  ADD_NETWORK_SHARE(networkShare: NetworkShare) {
    return axios.post(url, networkShare).then((response) => {
      this.context.dispatch('GET_NETWORK_SHARES');
      return Object.assign(new NetworkShare(), response.data);
    });
  }

  @Action({ commit: 'replaceNetworkShare', rawError: true })
  UPDATE_NETWORK_SHARE(networkShare: NetworkShare) {
    const encodedId = encodeURIComponent(networkShare.id);
    return axios.put(url + '/' + encodedId, networkShare).then((response) => {
      this.context.dispatch('GET_NETWORK_SHARES');
      return Object.assign(new NetworkShare(), response.data);
    });
  }

  @Action({ commit: 'deleteNetworkShare', rawError: true })
  DELETE_NETWORK_SHARE(id: string) {
    const encodedId = encodeURIComponent(id);
    return axios.delete(url + '/' + encodedId + '?removeData=true').then(() => {
      this.context.dispatch('GET_NETWORK_SHARES');
      return id;
    });
  }
}
