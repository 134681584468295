export default class VersionComparator {
  static compareVersions(newVersion: string | null, oldVersion: string | null): number {

    if (oldVersion === newVersion) {
      return 0;
    }

    if (newVersion === "local") {
      return 1;
    }

    if (!newVersion) {
      return -1;
    }

    if (!oldVersion) {
      return 1;
    }

    let oldIsSnapshot = oldVersion.indexOf("-SNAPSHOT") > -1;

    if (oldIsSnapshot) {
      if (VersionComparator.compareInternal(newVersion, oldVersion.replace("-SNAPSHOT", "")) === 0) {
        return 1;
      } else {
        oldVersion = oldVersion.replace("-SNAPSHOT", "");
      }
    }

    let newIsSnapshot = newVersion.indexOf("-SNAPSHOT") > -1;

    if (newIsSnapshot) {
      if (VersionComparator.compareInternal(newVersion.replace("-SNAPSHOT", ""), oldVersion) === 0) {
        return -1;
      } else {
        newVersion = newVersion.replace("-SNAPSHOT", "");
      }
    }

    return VersionComparator.compareInternal(newVersion, oldVersion);
  }

  static compareInternal (a: string, b: string): number {
    const listA = a.split('.');
    const listB = b.split('.');
    const biggerLength = listA.length > listB.length ? listA.length : listB.length;

    for (let i = 0; i < biggerLength; i++) {
      const numA = Number(listA[i]);
      const numB = Number(listB[i]);
      if (numA > numB) return 1;
      if (numB > numA) return -1;
      if (!isNaN(numA) && isNaN(numB)) return 1;
      if (isNaN(numA) && !isNaN(numB)) return -1;
    }
    return 0;
  };
}
