


















































































































import { Vue, Component, Ref, Prop } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";
import LoadingButton from "@/components/LoadingButton.vue";
import SelectableList from "@/components/SelectableList.vue";
import LoadingImage from "../components/LoadingImage.vue";
import { FormWizard } from "vue-form-wizard";
import { BModal } from "bootstrap-vue";
import HostingConfig from "@/model/HostingConfig";
import AnimatedInput from "@/components/AnimatedInput.vue";
import Timers from "@/util/timers";
import App from "@/model/App";

class InterpreterOption {
  label: string = "";
  code: string = "";
}

@Component({
  components: { LoadingButton, AnimatedInput, SelectableList, LoadingImage }
})
export default class AddNewHostingWizard extends Vue {
  @Getter getStatus!: any | null;
  @Action GET_INSTALLED_APPS!: () => Promise<any>;
  @Action ADD_HOSTING!: ({ hostingConfig, file }: { hostingConfig: HostingConfig, file: File }) => Promise<App>;
  @Action GET_BUILD_STATE!: (instanceId: string) => Promise<any>;

  @Prop(BModal) modal!: BModal;
  @Ref('newHostingWizard') hostingWizard!: FormWizard;
  @Ref('dockerBuildLog') dockerBuildLog!: HTMLTextAreaElement;
  @Ref('hiddenfileinput') hiddenFileInput!: HTMLInputElement;

  titleLabel: string = this.$pgettext("placeholder", "Enter app title");
  descLabel: string = this.$pgettext("placeholder", "Enter app description");
  subDomainLabel: string = this.$pgettext("placeholder", "Enter subdomain");

  appTitle: string = "";
  appDesc: string = "";
  appSubDomain: string = "";
  protectBySSO: boolean = false;
  archiveContainer: File | null = null;
  addons: string[] = [];

  addonOptions = [
    {
      key: 'ldap',
      name: 'LDAP'
    },
    {
      key: 'mysql',
      name: 'MySql'
    },
    {
      key: 'postgresql',
      name: 'PostgreSQL'
    },
    {
      key: 'mongodb',
      name: 'MongoDB'
    },
    {
      key: 'redis',
      name: 'Redis'
    },
    {
      key: 'memcached',
      name: 'Memcached'
    },
    {
      key: 'onlyoffice',
      name: 'OnlyOffice'
    },
    {
      key: 'collabora',
      name: 'Collabora Office'
    },
    {
      key: 'sendmail',
      name: 'SMTP Server'
    },
    {
      key: 'jitsi',
      name: 'Jitsi Server'
    }
  ]

  acceptedContainerTypes = [ '.zip', '.tar', '.tar.gz', '.tgz' ];
  acceptedContentTypes = [ 'application/zip', 'application/x-zip-compressed', 'application/x-gzip', 'application/x-tar' ]

  availableInterpreters: InterpreterOption[] = [
    { label: this.$gettext("No interpreter"), code: "html" },
    { label: "PHP7", code: "php7" },
    { label: "Python", code: "python" }
  ];
  chosenInterpreter: InterpreterOption | null = this.availableInterpreters[0];

  availableHostingTypes: { label: string, type: string }[] = [
    { label: "Docker", type: "docker" },
    { label: "Web (HTML, PHP, Python)", type: "web" }
  ]
  hostingType: { label: string, type: string } = { label: "Web (HTML, PHP, Python)", type: "web" };

  appInstanceId: string = '';
  dockerStream: string = '';
  textAreaClass: string = '';

  get isCreatingDocker(): boolean {
    return this.hostingType.type === 'docker';
  }

  reset() {
    this.appTitle = "";
    this.appDesc = "";
    this.appSubDomain = "";
    this.protectBySSO = false;
    this.archiveContainer = null;
    this.addons = [];
    this.appInstanceId = '';
    this.dockerStream = '';
    this.textAreaClass = '';
    this.hostingWizard.reset();
    if (this.modal) this.modal.hide();
    this.hiddenFileInput.value = '';
  }

  get canAdd(): boolean {
    if (this.appTitle && this.appSubDomain && this.archiveContainer) {
      return Boolean(this.acceptedContainerTypes.find((type: string) => {
        return Boolean(this.archiveContainer && this.archiveContainer.name.endsWith(type));
      }));
    } else {
      return false;
    }
  }

  get zipFileButtonText(): string {
    let text = this.$pgettext("button", "Select archive for upload");
    if (this.archiveContainer) {
      text = this.archiveContainer.name;
    }
    return text;
  }

  onSubDomainChanged(): void {
    if (this.appSubDomain.startsWith('.')) this.appSubDomain = this.appSubDomain.substr(1);
    if (this.appSubDomain.endsWith('.')) this.appSubDomain = this.appSubDomain.substr(0, this.appSubDomain.length - 1);
    this.appSubDomain = this.appSubDomain.replace('@', '.');
  }

  handleFileSelected(container: File | null): void {
    this.archiveContainer = container;
  }

  createHosting(props: any): Promise<any> {
    if (this.archiveContainer) {
      let hostingConfig = new HostingConfig();
      hostingConfig.domain = this.appSubDomain + '.' + this.getStatus.domain;
      hostingConfig.title = this.appTitle;
      hostingConfig.description = this.appDesc;
      hostingConfig.protectBySSO = this.protectBySSO;
      hostingConfig.addons = this.addons;
      if (this.isCreatingDocker) {
        hostingConfig.interpreter = this.hostingType.type;
      } else if (this.chosenInterpreter) {
        hostingConfig.interpreter = this.chosenInterpreter.code;
      }

      return this.ADD_HOSTING({
        hostingConfig: hostingConfig,
        file: this.archiveContainer
      }).then((app: App) => {
        this.GET_INSTALLED_APPS();
        if (this.isCreatingDocker) {
          props.nextTab();
          if (app.instanceId) {
            this.appInstanceId = app.instanceId;
            Timers.setInterval('DOCKER_BUILD_' + this.appInstanceId, () => {
              this.GET_BUILD_STATE(this.appInstanceId).then(state => {
                if (state.stream) {
                  this.dockerStream = state.stream;
                  this.$nextTick(() => { this.dockerBuildLog.scrollTop = this.dockerBuildLog.scrollHeight; });
                }
                if (state.done && (state.errorCode || state.errorMessage)) {
                  this.textAreaClass = 'border-danger';
                  this.GET_INSTALLED_APPS();
                } else if (state.done) {
                  this.textAreaClass = 'border-success';
                  this.GET_INSTALLED_APPS();
                }
              }).catch((error) => {
                this.GET_INSTALLED_APPS();
                if (error.response && error.response.status === 404) {
                  Timers.clearInterval('DOCKER_BUILD_' + this.appInstanceId);
                }
              });
            }, 1000);
          }
        } else {
          this.$snotify.success(this.$pgettext("notification", "Hosting created"));
          this.reset();
        }
      }).catch(error => {
        this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not create app"));
      });
    } else {
      return Promise.resolve();
    }
  }

  beforeDestroy () {
    if (this.appInstanceId) {
      Timers.clearInterval('DOCKER_BUILD_' + this.appInstanceId);
    }
  }
};
