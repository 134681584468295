import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import { MigrationStatus } from "@/util/migrationStatus";

const url: string = '/groupware-api/v1/migration';

@Module
export default class MigrationStatusStore extends VuexModule {

  migrationStatus: MigrationStatus = {
    migration_options: {},
    migration_state: {},
    imap_state: {}
  }

  get currentMigrationState(): MigrationStatus {
    return this.migrationStatus;
  }

  get hasMigrationStatus(): boolean {
    if (this.migrationStatus && this.migrationStatus.migration_state) {
      const state = this.migrationStatus.migration_state;
      for (const key of Object.keys(state)) {
        if (state[key] === 'RUNNING' || state[key] === 'PENDING') {
          return true;
        }
      }
    }
    return false;
  }

  get hasAnyMigrationStatus(): boolean {
    if (this.migrationStatus && this.migrationStatus['migration_state']) {
      const state = this.migrationStatus['migration_state'];
      return Object.keys(state).length > 0;
    }
    return false;
  }

  @Mutation setMigrationData (migrationData: MigrationStatus) {
    this.migrationStatus = migrationData;
  }

  @Action({ commit: 'setMigrationData', rawError: true })
  GET_MIGRATION_STATUS () {
    return axios.get(url).then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return {
          migration_options: {},
          migration_state: {},
          imap_state: {}
        };
      }
    });
  }
};
