import StoreApp from "@/model/StoreApp";
import InvoiceContact from "@/model/InvoiceContact";
import Subscription from "@/model/invoice/Subscription";
import Product from "@/model/invoice/Product";
import ProductMapping from "@/model/invoice/ProductMapping";
import appUtils from "@/util/StoreAppUtils";
import App from "@/model/App";
import VersionComparator from "@/util/VersionComparator";
import SubscriptionUpgrade from "@/model/SubscriptionUpgrade";

//This class and its functions are NOT STATIC
//This is done to make the functions callable from the templates
//See Buy/UpgradeAppWizard
export default class SubscriptionUtils {

  getProductForSubscriptionAndApp(subscription: Subscription, storeApp: StoreApp): ProductMapping | undefined {
    if ([ 'ACTIVE', 'TRIAL' ].indexOf(subscription.state) >= 0 && subscription.products) {
      return subscription.products.find((pm: ProductMapping) => {
        if (pm.product && pm.product.appId) {
          let appIds: string[] = pm.product.appId.split(',');
          return Boolean(storeApp && storeApp.id && appIds.indexOf(storeApp.id) >= 0);
        } else {
          return false;
        }
      });
    }
  }

  getUserCountForSubscription(subscription: Subscription): number {
    let userCount = 0;
    if (subscription.products) {
      for (let productMapping of subscription.products) {
        if (productMapping.userCount && productMapping.userCount > userCount) {
          userCount = productMapping.userCount;
        }
      }
    }
    return userCount;
  }

  getUserCountForSubscriptionAndApp(subscription: Subscription, storeApp: StoreApp): number | null {
    let productMapping: ProductMapping | undefined = this.getProductForSubscriptionAndApp(subscription, storeApp);
    if (productMapping) {
      return productMapping.userCount;
    } else {
      return null;
    }
  }

  getProduct(storeApp: StoreApp | null): Product | null {
    if (storeApp && storeApp.products) {
      for (let product of storeApp.products) {
        if (product.id) return product;
      }
    }
    return null;
  }

  trialDays(storeApp: StoreApp | null): number {
    let subscription: Subscription | null = this.getInActiveSubscription(storeApp);
    if (subscription) {
      return 0;
    } else {
      subscription = this.getActiveSubscription(storeApp);
      if (subscription && subscription.state === 'ACTIVE') {
        return 0;
      } else if (subscription && subscription.state === 'TRIAL') {
        let now: number = new Date().getTime();
        let created: number = subscription.created ? Number(subscription.created) : 0;
        let elapsedDays = (now - created) / 24 / 3600000;
        return Math.floor(Math.max(14 - elapsedDays, 0));
      } else {
        let product: Product | null = this.getProduct(storeApp);
        if (product && product.daysOfTrial) {
          return product.daysOfTrial;
        } else {
          return 0;
        }
      }
    }
  }

  hasPaymentMethod(invoiceContact: InvoiceContact | null) {
    if (invoiceContact && invoiceContact.paymentOptions) {
      return invoiceContact.paymentOptions.length;
    }
    return false;
  }

  getActiveSubscription(storeApp: StoreApp | null): Subscription | null {
    if (storeApp && storeApp.subscriptions) {
      for (let subscription of storeApp.subscriptions) {
        if ([ 'ACTIVE', 'TRIAL' ].indexOf(subscription.state) >= 0) {
          return subscription;
        }
      }
    }
    return null;
  }

  getInActiveSubscription(storeApp: StoreApp | null): Subscription | null {
    if (storeApp && storeApp.subscriptions) {
      for (let subscription of storeApp.subscriptions) {
        if ([ 'ACTIVE', 'TRIAL' ].indexOf(subscription.state) < 0) {
          return subscription;
        }
      }
    }
    return null;
  }

  isPaidApp(storeApp: StoreApp | null, installedApp: App | null): boolean {
    if (storeApp && appUtils.storeAppSupportsGroups(storeApp) && storeApp.products && storeApp.products.length > 0) {
      let versionToCompare: string | null = installedApp ? installedApp.appVersion : storeApp.version;
      return !!storeApp.products.find((product: Product) => {
        return !product.appVersion || VersionComparator.compareVersions(product.appVersion, versionToCompare) <= 0;
      });
    } else {
      return false;
    }
  }

  //TODO: Handle subscription.state === 'PAYMENT_FAILED'
  seatsActive(storeApp: StoreApp | null): number {
    let seats: number = 0;
    if (storeApp && storeApp.subscriptions) {
      for (let subscription of storeApp.subscriptions) {
        if ([ 'ACTIVE', 'TRIAL' ].indexOf(subscription.state) >= 0) {
          let userCount: number | null = this.getUserCountForSubscriptionAndApp(subscription, storeApp);
          if (userCount) {
            seats += userCount; //TODO: Sum up all subscriptions?
          }
        }
      }
    }
    return seats;
  }

  taxRate(storeApp: StoreApp | null): number {
    let taxRate: number = 1900;
    if (storeApp && storeApp.products) {
      for (let product of storeApp.products) {
        if (product.taxRate && product.taxRate.taxRate != null) return product.taxRate.taxRate;
      }
    }
    return taxRate;
  }

  currencyFormatDE(price: number | null | undefined): string {
    if (price !== null && price !== undefined) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price);
    } else {
      return '-,--';
    }
  }

  taxAmount(storeApp: StoreApp | null, paymentInterval: number | null): number | null {
    let taxAmount: number | null = null;
    let price: number | null = this.price(storeApp, paymentInterval);
    if (price !== null) {
      taxAmount = price * this.taxRate(storeApp) / 10000;
    }
    return taxAmount;
  }

  price(storeApp: StoreApp | null, paymentInterval?: number | null): number | null {
    if (storeApp) {
      let subscription: Subscription | null = this.getActiveSubscription(storeApp);
      if (subscription) {
        let product: ProductMapping | undefined = this.getProductForSubscriptionAndApp(subscription, storeApp);
        if (product && product.netAmount !== null) {
          return product.netAmount;
        }
      }
      let product: Product | null = this.getProduct(storeApp);
      if (product && product.productPrice && paymentInterval && paymentInterval >= 12 && product.productPrice.yearly !== null) {
        return product.productPrice.yearly;
      } else if (product && product.productPrice && product.productPrice.monthly !== null) {
        return product.productPrice.monthly;
      }
    }
    return null;
  }

  priceAndTaxRateOfSubscriptionAndApp(subscription: Subscription, storeApp: StoreApp): { price: number, taxRate: number } {
    let product: ProductMapping | undefined = this.getProductForSubscriptionAndApp(subscription, storeApp);
    let price: number = product ? (product.netAmount || 0) : 0;
    let taxRate: number = (product && product.taxRate) ? (product.taxRate.taxRate || 1900) : 1900;
    return { price: price, taxRate: taxRate };
  }

  priceAndTaxRateOfProductAndPaymentInterval(product: Product, paymentInterval: number | null): { price: number, taxRate: number } {
    if (product && product.productPrice && paymentInterval && paymentInterval >= 12 && product.productPrice.yearly !== null) {
      return { price: product.productPrice.yearly, taxRate: product.taxRate ? product.taxRate.taxRate || 0 : 1900 };
    } else if (product && product.productPrice && product.productPrice.monthly !== null) {
      return { price: product.productPrice.monthly, taxRate: product.taxRate ? product.taxRate.taxRate || 0 : 1900 };
    } else {
      return { price: 0, taxRate: product.taxRate ? product.taxRate.taxRate || 0 : 1900 };
    }
  }

  seatsRequired(storeApp: StoreApp | null, totalUserCount: number, selectedUsers: string[], selectedGroups: any, paymentInterval?: number | null): SubscriptionUpgrade[] {
    const seatsRequired: SubscriptionUpgrade[] = [];
    let subscription: Subscription | null | undefined = this.getActiveSubscription(storeApp);
    if (storeApp && subscription && subscription.productType === 'VARIABLE_SUPPORT') {
      let seatsActive: number | null = this.getUserCountForSubscriptionAndApp(subscription, storeApp) || 0;
      let priceAndTax: { price: number, taxRate: number } = this.priceAndTaxRateOfSubscriptionAndApp(subscription, storeApp);
      seatsRequired.push({
        subscription: subscription,
        productMapping: this.getProductForSubscriptionAndApp(subscription, storeApp) || null,
        product: null,
        seatsRequired: totalUserCount,
        seatsActive: seatsActive,
        seatsToBuy: Math.max(totalUserCount - seatsActive, 0),
        price: priceAndTax.price,
        taxRate: priceAndTax.taxRate,
        storeApp: storeApp
      });
    } else {
      let product: Product | null = this.getProduct(storeApp);
      if (product && product.type === 'VARIABLE_SUPPORT') {
        let priceAndTax: { price: number, taxRate: number } = this.priceAndTaxRateOfProductAndPaymentInterval(product, paymentInterval || 12);
        seatsRequired.push({
          subscription: null,
          productMapping: null,
          product: product,
          seatsRequired: totalUserCount,
          seatsActive: 0,
          seatsToBuy: totalUserCount,
          price: priceAndTax.price,
          taxRate: priceAndTax.taxRate,
          storeApp: storeApp
        });
      } else if (storeApp && storeApp.id) {
        for (let userDn of selectedUsers) { //Find a suitable subscription OR product for every user and the corresponding groups
          const appIds: string[] = [ storeApp.id ]; //Product/subscription must at least contain the app itself
          if (Array.isArray(selectedGroups[userDn])) {
            for (let group of selectedGroups[userDn]) {
              if (group.productId && !appIds.includes(group.productId)) {
                appIds.push(group.productId);
              }
            }
          }
          let subscriptions: { subscription: Subscription, mapping: ProductMapping }[] = this.findMatchingSubscriptionsForAppIds(storeApp.subscriptions || [], appIds);
          if (subscriptions.length > 0) {
            for (let sub of subscriptions) {
              let seatsRequiredForSubscription: SubscriptionUpgrade | undefined = seatsRequired.find(sr => !!sr.subscription && sr.subscription.id === sub.subscription.id);
              if (seatsRequiredForSubscription) {
                seatsRequiredForSubscription.seatsRequired = seatsRequiredForSubscription.seatsRequired + 1;
                seatsRequiredForSubscription.seatsToBuy = Math.max(seatsRequiredForSubscription.seatsRequired - seatsRequiredForSubscription.seatsActive, 0);
              } else {
                let seatsActive: number | null = this.getUserCountForSubscriptionAndApp(sub.subscription, storeApp) || 0;
                let priceAndTax: { price: number, taxRate: number } = this.priceAndTaxRateOfSubscriptionAndApp(sub.subscription, storeApp);
                seatsRequired.push({
                  subscription: sub.subscription,
                  productMapping: sub.mapping,
                  product: null,
                  seatsRequired: 1,
                  seatsActive: seatsActive,
                  seatsToBuy: Math.max(1 - seatsActive, 0),
                  price: priceAndTax.price,
                  taxRate: priceAndTax.taxRate,
                  storeApp: storeApp
                });
              }
            }
          } else if (storeApp && storeApp.products) {
            const product: Product | undefined = [...storeApp.products].sort((p1: Product, p2: Product) => {
              const price1: number = p1.productPrice ? p1.productPrice.monthly || 0 : 0;
              const price2: number = p2.productPrice ? p2.productPrice.monthly || 0 : 0;
              return Math.sign(price1 - price2);
            }).find((product: Product) => {
              if (product.appId) {
                const productAppIds = product.appId.split(',');
                return appIds.filter(i => productAppIds.includes(i)).length === appIds.length;
              } else {
                return false;
              }
            });
            if (product && product.id) {
              let seatsRequiredForProduct = seatsRequired.find(sr => !!sr.product && sr.product.id === product.id);
              if (seatsRequiredForProduct) {
                seatsRequiredForProduct.seatsRequired = seatsRequiredForProduct.seatsRequired + 1;
                seatsRequiredForProduct.seatsToBuy = Math.max(seatsRequiredForProduct.seatsRequired - seatsRequiredForProduct.seatsActive, 0);
              } else {
                let priceAndTax: { price: number, taxRate: number } = this.priceAndTaxRateOfProductAndPaymentInterval(product, paymentInterval || 12);
                seatsRequired.push({
                  subscription: null,
                  productMapping: null,
                  product: product,
                  seatsRequired: 1,
                  seatsActive: 0,
                  seatsToBuy: 1,
                  price: priceAndTax.price,
                  taxRate: priceAndTax.taxRate,
                  storeApp: storeApp
                });
              }
            }
          }
        }
      }
    }
    return seatsRequired;
  }

  findMatchingSubscriptionsForAppIds(subs: Subscription[], appIds: string[]): { subscription: Subscription, mapping: ProductMapping }[] {
    let subscriptions: { subscription: Subscription, mapping: ProductMapping }[] = [];
    for (let s of subs) {
      if ([ 'ACTIVE', 'TRIAL' ].includes(s.state) && s.products) {
        const mapping: ProductMapping | undefined = s.products.find((mapping: ProductMapping) => {
          if (mapping.product && mapping.product.appId) {
            const productAppIds = mapping.product.appId.split(',');
            return appIds.filter(i => productAppIds.includes(i)).length === appIds.length;
          } else {
            return false;
          }
        });
        if (mapping) {
          subscriptions.push({ subscription: s, mapping: mapping });
        }
      }
    }
    return subscriptions;
  }
}
