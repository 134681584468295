export default class SystemNotificationConfig {
  raidFailure!: string[];
  smartFailure!: string[];
  diskSpace!: string[];
  backupFailure!: string[];
  replicationFailure!: string[];
  systemUpdate!: string[];
  appUpdate!: string[];

  constructor() {
    this.raidFailure = [];
    this.smartFailure = [];
    this.diskSpace = [];
    this.backupFailure = [];
    this.replicationFailure = [];
    this.systemUpdate = [];
    this.appUpdate = [];
  }
}
