import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";

const url: string = '/api/v1/domains';

@Module
export default class DomainConfigStore extends VuexModule {
  domains: string[] = [];
  loading: boolean = false;

  get getDomains() : string[] {
    return this.domains;
  }

  @Mutation
  setDomains(domains: string[]) {
    this.domains = domains;
  }

  @Mutation setDomainsAreLoading (loading: boolean) {
    this.loading = loading;
  }

  @Action({ commit: 'setDomains', rawError: true })
  GET_DOMAINS() {
    this.context.commit("setDomainsAreLoading", true);
    return axios.get(url).then((response) => {
      this.context.commit("setDomainsAreLoading", false);
      return response.data;
    });
  }

  @Action({ rawError: true })
  SET_DOMAIN_CONFIG({ instanceId, alternativeDomains }: { instanceId: string, alternativeDomains: string[] }) {
    const encodedInstanceId = encodeURIComponent(instanceId);
    return axios.post(url + '/' + encodedInstanceId, alternativeDomains).then((response) => {
      this.context.dispatch('GET_DOMAINS');
    });
  }

  @Action({ rawError: true })
  DELETE_DOMAIN_CONFIG(instanceId: string) {
    const encodedInstanceId = encodeURIComponent(instanceId);
    return axios.delete(url + '/' + encodedInstanceId).then((response) => {
      this.context.dispatch('GET_DOMAINS');
    });
  }

  @Action({ rawError: true })
  SET_DEFAULT_DOMAIN_TO_APP(instanceId: string) {
    const encodedInstanceId = encodeURIComponent(instanceId);
    return axios.put(url + '/default?instanceId=' + encodedInstanceId).then((response) => {
      this.context.dispatch('GET_DOMAINS');
    });
  }

  @Action({ rawError: true })
  SET_DEFAULT_DOMAIN_TO_UNIKI() {
    return axios.delete(url + '/default').then((response) => {
      this.context.dispatch('GET_DOMAINS');
    });
  }
}
