












































































































import { Vue, Component } from 'vue-property-decorator';
import { Action } from "vuex-class";
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import ConfigValue from "@/model/ConfigValue";
import ChippedInput from "@/components/ChippedInput.vue";
import SystemNotificationConfig from "@/model/SystemNotificationConfig";

@Component({
  components: {
    AnimatedInput,
    ChippedInput,
    LoadingButton
  }
})
export default class SystemNotifications extends Vue {

  @Action SET_SYSTEM_CONFIG_VALUES!: ({ context, values }: { context: string, values: ConfigValue[] }) => Promise<ConfigValue[]>;
  @Action GET_SYSTEM_CONFIG_VALUES!: (context: string) => Promise<ConfigValue[]>;

  config: SystemNotificationConfig | null = null;
  
  inputLabel = this.$gettext('Recipient address(es)');
  inputPlaceholder = this.$gettext("Input recipient email address(es) and press 'return'.");

  save (): Promise<void> {
    return this.SET_SYSTEM_CONFIG_VALUES({ context: 'system', values: [ { id: 'notifications', context: 'system', value: this.config } ] }).then((values: ConfigValue[]) => {
      const config: ConfigValue | undefined = values.find(value => value.id === 'notifications');
      this.config = config ? Object.assign(new SystemNotificationConfig(), config.value) : new SystemNotificationConfig();
      this.$snotify.success(this.$pgettext('notification', 'Config saved.'));
    }).catch(error => {
      this.$snotify.error(error.response.data.message, this.$pgettext('notification', 'Failed to saved config.'));
    });
  }

  created () {
    this.GET_SYSTEM_CONFIG_VALUES('system').then((values: ConfigValue[]) => {
      const config: ConfigValue | undefined = values.find(value => value.id === 'notifications');
      if (config) this.config = Object.assign(new SystemNotificationConfig(), config.value);
    }).catch(error => {
      this.$snotify.error(error.response.data.message, this.$pgettext('notification', 'Failed to get config from server.'));
    }).finally(() => {
      this.config = this.config || new SystemNotificationConfig();
    });
  }
};
