import BackupDevice from "../model/backup/BackupDevice";
import USBStoragePartition from "../model/backup/USBStoragePartition";
import BackupJob from "../model/backup/BackupJob";
import USBStorageDrive from "../model/backup/USBStorageDrive";
import Subscription from "@/model/invoice/Subscription";
import Product from "@/model/invoice/Product"; //We import the vue main instance as i18n Object so gettext recognizes the call

export default class BackupUtil {

  static dayMapping: (vue: any) => { name: string, number: number }[] = (i18n: any) => [
    { name: i18n.$gettext("Monday"), number: 1 },
    { name: i18n.$gettext("Tuesday"), number: 2 },
    { name: i18n.$gettext("Wednesday"), number: 3 },
    { name: i18n.$gettext("Thursday"), number: 4 },
    { name: i18n.$gettext("Friday"), number: 5 },
    { name: i18n.$gettext("Saturday"), number: 6 },
    { name: i18n.$gettext("Sunday"), number: 7 }
  ];

  static kilobytesToGigaBytes(kilobytes: number) : number {
    return Math.round((kilobytes / 1000) / 1000);
  }

  static getAvailableStoragePercent(usbStorageDrive: USBStorageDrive): number {
    if (usbStorageDrive.partitions != null && usbStorageDrive.partitions.length > 0) {
      let partitions: USBStoragePartition[] = usbStorageDrive.partitions;
      let accumulatedSize = 0;
      let accumulatedUsage = 0;
      for (let c = 0; c < partitions.length; c++) {
        let part = partitions[c];
        if (part.sizeInKBytes) accumulatedSize += part.sizeInKBytes;
        if (part.usedInKBytes) accumulatedUsage += part.usedInKBytes;
      }

      let percent: number = accumulatedUsage / accumulatedSize;
      return Math.round((1 - percent) * 100);
    } else {
      return 100;
    }
  }

  //Only returns true if the device is a USB disk
  static canBeUsedForBackups(backupDevice: BackupDevice): boolean {
    if (backupDevice.hasOwnProperty('partitions')) { //This checks whether it actually is a USBStorageDevice
      let forbiddenMountpoints = ["/boot", "/home", "/data", "/snapshot-ro"];

      let partitionIsForbidden = (partition: USBStoragePartition) => {
        for (let i = 0; i < forbiddenMountpoints.length; i++) {
          let elem = forbiddenMountpoints[i];
          if (partition.mountPoint !== null && (partition.mountPoint === "/" || partition.mountPoint.startsWith(elem))) {
            return true;
          }
        }
        return false;
      };

      let partitions: USBStoragePartition[] = (Object.assign(new USBStorageDrive(), backupDevice)).partitions || [];
      for (let c = 0; c < partitions.length; c++) {
        let part: USBStoragePartition = partitions[c];
        if (partitionIsForbidden(part)) {
          return false;
        }
      }

      return true;
    } else {
      return false;
    }
  }

  static isUsedForBackup(usbStorageDrive: USBStorageDrive): boolean {
    let partitions: USBStoragePartition[] = usbStorageDrive.partitions || [];
    for (let c = 0; c < partitions.length; c++) {
      let part: USBStoragePartition = partitions[c];
      if (part.usedForBackup) {
        return true;
      }
    }
    return false;
  }

  static overAllStorageInGB(usbStorageDrive: USBStorageDrive): number {
    if (usbStorageDrive.partitions !== null && usbStorageDrive.partitions.length > 0) {
      let partitions: USBStoragePartition[] = usbStorageDrive.partitions;
      let accumulatedSize = 0;
      for (let c = 0; c < partitions.length; c++) {
        let part: USBStoragePartition = partitions[c];
        if (part.sizeInKBytes) accumulatedSize += part.sizeInKBytes;
      }
      return this.kilobytesToGigaBytes(accumulatedSize);
    } else {
      let overallSize: number = usbStorageDrive.sizeInKBytes || 0;
      return this.kilobytesToGigaBytes(overallSize);
    }
  }

  static getUsedStorageInGB(usbStorageDrive: USBStorageDrive): number | null {
    if (usbStorageDrive.partitions !== null && usbStorageDrive.partitions.length > 0) {
      let partitions: USBStoragePartition[] = usbStorageDrive.partitions;
      let usedSize = 0;
      for (let c = 0; c < partitions.length; c++) {
        let part: USBStoragePartition = partitions[c];
        if (part.usedInKBytes) usedSize += part.usedInKBytes;
      }
      return this.kilobytesToGigaBytes(usedSize);
    } else {
      return null;
    }
  }

  static isCloud(job: BackupJob): boolean {
    return (job.fsUUID && job.fsUUID.startsWith("cloud://")) as boolean;
  }

  static isNetwork(job: BackupJob): boolean {
    return (job.fsUUID && (job.fsUUID.startsWith("ftp://") || job.fsUUID.startsWith("sftp://") || job.fsUUID.startsWith("smb://"))) as boolean;
  }

  static findDayByNumber(num: string, vue: any): { name: string, number: number } | null {
    let realNum = parseInt(num.trim());
    let mapping = this.dayMapping(vue);
    for (let a = 0; a < mapping.length; a++) {
      let day = mapping[a];
      if (day.number === realNum) {
        return day;
      }
    }
    return null;
  }

  static getTime(job: BackupJob): string | null {
    let cronExpr = job.cronExpression;

    if (cronExpr && cronExpr.indexOf(' ') > 0) {
      let parts = cronExpr.split(" ");

      let minutes = parts[0];
      let hours = parts[1];

      if (minutes.length === 1) {
        minutes = "0" + minutes;
      }

      return `${hours}:${minutes}`;
    } else {
      return null;
    }
  }

  static getTimeObject(job: BackupJob): { minutes: string, hours: string } | null {
    let cronExpr = job.cronExpression;

    if (cronExpr && cronExpr.indexOf(' ') > 0) {
      let parts = cronExpr.split(" ");

      let minutes = parts[0];
      let hours = parts[1];

      return { minutes, hours };
    } else {
      return null;
    }
  }

  static getDayObjects(job: BackupJob, vue: any): { name: string, number: number }[] {
    let cronExpr = job.cronExpression;
    let result: { name: string, number: number }[] = [];

    if (cronExpr && cronExpr.trim().indexOf(' ') > 0) {
      if (cronExpr.trim().endsWith("*")) {
        return this.dayMapping(vue);
      }

      let parts = cronExpr.split(" ");

      let dayNumbers = parts[4];
      let dayDigits = dayNumbers.split(',');

      for (let i = 0; i < dayDigits.length; i++) {
        let day = this.findDayByNumber(dayDigits[i], vue);
        if (day) result.push(day);
      }
    }

    return result;
  }

  static getDeviceName(uuid: string, usbStorageDrives: USBStorageDrive[] | null, i18n: any): string {
    if (uuid) {
      if (uuid.startsWith("cloud://")) {
        return i18n.$gettext("Cloud backup");
      }

      if (uuid.startsWith('ftp') || uuid.startsWith('smb') || uuid.startsWith('sftp')) {
        return uuid;
      }
    }

    if (usbStorageDrives) {
      for (let drive of usbStorageDrives) {
        if (drive.id === uuid) {
          if (drive.model) {
            return `${this.overAllStorageInGB(drive)}GB ${drive.model}`;
          } else {
            return `${this.overAllStorageInGB(drive)}GB ` + i18n.$gettext("USB disk");
          }
        }
        if (drive.partitions) {
          for (let partition of drive.partitions) {
            if (partition.fsUUID === uuid) {
              if (drive.model) {
                return `${this.overAllStorageInGB(drive)}GB ${drive.model}`;
              } else {
                return `${this.overAllStorageInGB(drive)}GB ` + i18n.$gettext("USB disk");
              }
            }
          }
        }
      }
    }

    return i18n.$gettext("USB disk");
  }

  static isCron(job: BackupJob): boolean {
    const cron = job.cron;
    const isCloud = this.isCloud(job);

    if (isCloud) {
      return false;
    } else {
      return cron as boolean;
    }
  }

  static cloudBackupBooked(subscriptions: Subscription[]) {
    if (subscriptions) {
      return Boolean(subscriptions.find((subscription: Subscription) => {
        return subscription.productType === "BACKUP" && subscription.state === "ACTIVE";
      }));
    } else {
      return false;
    }
  }

  static cloudBackupPricePerGB(products: Product[]): number | null {
    let backupProduct: Product | undefined = this.cloudBackupProduct(products);
    if (backupProduct && backupProduct.productPrice && backupProduct.productPrice.monthly != null) {
      return backupProduct.productPrice.monthly / 100000;
    } else {
      return null;
    }
  }

  static cloudBackupProduct(products: Product[]): Product | undefined {
    return products.find(product => product.type === "BACKUP");
  }

  static cloudBackupEnabled(backupJobs: BackupJob[]) {
    if (backupJobs) {
      const cloudJobs = backupJobs.filter((backupJob: BackupJob) => {
        return BackupUtil.isCloud(backupJob);
      });
      return Array.isArray(cloudJobs) && cloudJobs.length > 0;
    } else {
      return false;
    }
  }

  static createCronExpression(selectedDays: { name: string, number: number }[], minute: number | string, hour: number | string): string | null {
    if (selectedDays && selectedDays.length > 0) {
      let days = '';
      for (let i = 0; i < selectedDays.length; i++) {
        days += selectedDays[i].number + (i === selectedDays.length - 1 ? "" : ",");
      }
      return `${minute} ${hour} * * ${days}`;
    } else {
      return null;
    }
  }
}
