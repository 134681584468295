
























import { Vue, Component, Prop } from 'vue-property-decorator';
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import LoadingImage from "@/components/LoadingImage.vue";
import SelectableListItem from "@/components/SelectableListItem.vue";

@Component({
  components: {
    SelectableListItem,
    AnimatedInput,
    LoadingButton,
    LoadingImage
  }
})
export default class SelectableList extends Vue {

  @Prop(Array)
  options!: [];

  @Prop(Array)
  value!: string[];

  @Prop(String)
  primaryKey!: string;

  @Prop(String)
  title!: string;

  @Prop(Array)
  titles!: string[];

  @Prop(Boolean)
  multiColumn!: boolean;

  $refs!: {};

  get allTitles(): string[] {
    if (this.multiColumn && this.titles) {
      return this.titles;
    } else {
      return [ this.title ];
    }
  }

  getOptionByPk(primaryKey: string): any | null {
    if (!primaryKey || !this.options) return null;
    let option = this.options.find((opt) => {
      return opt[this.primaryKey] === primaryKey;
    });
    if (option) return option;
    else return null;
  }

  isDisabled(option: any): boolean {
    return Boolean(option && option[this.primaryKey] && option.disabled) || this.isRequired(option);
  }

  isRequired(option: any): boolean {
    if (option && option[this.primaryKey]) {
      return Boolean(this.value.find((primaryKey: string) => {
        let otherOption = this.getOptionByPk(primaryKey);
        return Boolean(otherOption && otherOption[this.primaryKey] && otherOption.requires === option[this.primaryKey]);
      }));
    } else {
      return false;
    }
  }

  processSelection({ primaryKey, value }: { primaryKey: any, value: boolean }) {
    let option = this.getOptionByPk(primaryKey);
    let result = [ ...this.value ]; //Copy this selection
    let contained: boolean = this.value.indexOf(primaryKey) >= 0;
    if (option && value && !contained) { //Selected Case
      result.push(primaryKey);
    } else if (!option || contained) { //Deselected case
      result = result.filter((item: any) => { return item !== primaryKey; });
    }
    if (value) {
      if (option && option.requires) {
        if (this.value.indexOf(option.requires) < 0) {
          this.value.push(option.requires);
        }
      }
    }
    this.$emit("input", result);
  }

  toggleSelectAll(value: boolean): void {
    if (value) { //Select all
      let result = this.options.map((opt: any) => { return opt[this.primaryKey]; });
      this.$emit('input', result);
    } else { //Deselect all
      let result = this.value.filter((primaryKey: string) => {
        let option = this.getOptionByPk(primaryKey);
        return Boolean(option && option.disabled);
      });
      this.$emit('input', result);
    }
  }

  set isAllSelected(value: boolean) {}

  get isAllSelected(): boolean {
    if (this.options && this.value && this.options.length > 0) {
      return this.options.length <= this.value.length;
    }
    return false;
  }
}
