import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import Password from "@/model/Password";

const url: string = '/api/v1/users';

@Module
export default class PSTUploadStore extends VuexModule {
  runningJobs: string[] = [];
  loading: boolean = false;

  get getRunningJobs () : string[] {
    return this.runningJobs;
  }

  @Mutation
  setRunningJobs (runningJobs: string[]) {
    this.runningJobs = runningJobs;
  }

  @Mutation
  addRunningJob (userId: string) {
    this.runningJobs.push(userId);
  }

  @Mutation setRunningPstJobsLoading (loading: boolean) {
    this.loading = loading;
  }

  @Action({ commit: 'setRunningJobs', rawError: true })
  GET_RUNNING_JOBS(userId: string) {
    const encodedUserId = encodeURIComponent(userId);
    this.context.commit("setRunningPstJobsLoading", true);
    return axios.get(url + '/' + encodedUserId + '/pstimport').then((response) => {
      this.context.commit("setRunningPstJobsLoading", false);
      return response.data;
    });
  }

  @Action({ commit: 'addRunningJob', rawError: true })
  UPLOAD_PST({ userId, file }: { userId: string, file: File }) {
    const encodedUserId = encodeURIComponent(userId);
    const formData = new FormData();
    formData.append('file', file);
    const thisUrl = url + '/' + encodedUserId + '/pstimport';

    return axios({
      method: 'post',
      url: thisUrl,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response) => {
      this.context.dispatch('GET_RUNNING_JOBS');
      return userId;
    });
  }
}
