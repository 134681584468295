export default class UserConfigValue {
  id!: string | null;
  context!: string | null;
  value!: any | null;

  constructor() {
    this.id = null;
    this.context = null;
    this.value = null;
  }
}
