














































































































































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import StoreApp from "@/model/StoreApp";
import { Action, Getter } from "vuex-class";
import InvoiceContact from "@/model/InvoiceContact";
import User from "@/model/User";
import LoadingButton from "@/components/LoadingButton.vue";
import SelectableList from "@/components/SelectableList.vue";
import LoadingImage from "../components/LoadingImage.vue";
import { FormWizard } from "vue-form-wizard";
import { BModal } from "bootstrap-vue";
import App from "@/model/App";
import DomainGridItem from "@/components/DomainGridItem.vue";
import Group from "@/model/Group";
import Subscription from "@/model/invoice/Subscription";
import SubscriptionUtils from "@/util/SubscriptionUtils";
import ConfigValue from "@/model/ConfigValue";
import StoreAppUtils from "@/util/StoreAppUtils";
import Product from "@/model/invoice/Product";
import SelectableGroup from "@/util/SelectableGroup";
import SubscriptionUpgrade from "@/model/SubscriptionUpgrade";

@Component({
  components: { DomainGridItem, LoadingButton, SelectableList, LoadingImage }
})
export default class UpgradeAppWizard extends Vue {
  @Prop(String) installedAppInstanceId!: string;
  @Prop(String) storeAppId!: string;
  @Prop(Array) users!: string[];

  @Getter getInvoiceContact!: InvoiceContact | null;
  @Getter getApps!: App[];
  @Getter getStoreApps!: StoreApp[];
  @Getter getUsers!: User[];
  @Getter getGroups!: Group[];
  @Getter loggedInIsSigning!: boolean;
  @Getter getPaymentInterval!: number | null;
  @Getter getNextPayment!: number | null;
  @Getter getSystemConfigValues!: ConfigValue[];

  @Action UPGRADE_SERVICE!:({ subscriptionId, planId, userCount }: { subscriptionId: number, planId: string, userCount: number }) => Promise<any>;
  @Action GET_PAYMENT_OPTIONS!: any;
  @Action PURCHASE_SERVICE!:({ planId, userCount }: { planId: string, userCount: number }) => Promise<any>;
  @Action INSTALL_APP!: ({ storeId, version, options }: { storeId: string, version: string | null, options: { users: string[] | null, groups: any }}) => Promise<any>;
  @Action UPDATE_APP!: ({ instanceId, version, options }: { instanceId: string, version: string | null, options: { users: string[] | null }}) => Promise<any>;
  @Action GET_STORE_APPS!: any;

  @Ref('upgrademodal') upgadeModal!: BModal;
  @Ref('upgradeWizard') upgradeWizard!: FormWizard;

  selectedUsersInternal: string[] = [];
  selectedGroups: any = {};
  selectedStorageClasses: string[] = [ 'localstorage', 'objectstorage' ];
  containerEngines: string[] = [ 'Lokal' ];
  selectedContainerEngine: string = 'Lokal';
  subsUtil: SubscriptionUtils = new SubscriptionUtils();

  step: number = 0;
  buyWizardTitles: string[] = [
    this.$pgettext("title", "Payment Method"),
    this.$pgettext("title", "Resources and Permissions"),
    this.$pgettext("title", "Storage Classes"),
    this.$pgettext("title", "Select Users"),
    this.$pgettext("title", "Confirm Order")
  ];

  isFirstTab(props: any): boolean {
    return props.activeTabIndex === 0;
  }

  isLastTab(props: any): boolean {
    return props.isLastStep;
  }

  get getUsersWithRequired(): { dn: string | null, user: User, disabled: boolean }[] {
    return this.getUsers.map(user => {
      /*let disabled: boolean = Boolean(user.dn &&
          UserUtils.userIsExternal(user.dn, this.getSystemConfigValues) &&
          UserUtils.groupIsMappedToExternalGroup(this., this.getSystemConfigValues));*/

      return { dn: user.dn, user: user, disabled: false };
    });
  }

  get installedApp(): App | null {
    if (this.installedAppInstanceId && this.getApps) {
      return this.getApps.find(app => app.instanceId === this.installedAppInstanceId) || null;
    } else {
      return null;
    }
  }

  get storeApp(): StoreApp | null {
    if (this.storeAppId && this.getStoreApps) {
      return this.getStoreApps.find(storeApp => storeApp.id === this.storeAppId) || null;
    } else {
      return null;
    }
  }

  get trialDays(): number {
    return this.subsUtil.trialDays(this.storeApp);
  }

  get nextPaymentAsDateString(): string | null {
    let nextPayment: number | null = this.getNextPayment;
    if (nextPayment) {
      return new Date(nextPayment).toLocaleDateString();
    } else {
      return null;
    }
  }

  get addons(): { id: string, title: string, description: string }[] {
    return StoreAppUtils.getAddons(this.storeApp, this.$gettext);
  }

  get storageClasses(): { id: string, title: string, description: string, required: boolean }[] {
    return StoreAppUtils.getStorageClasses(this.storeApp, this.$gettext);
  }

  get permissions(): { id: string, title: string, description: string }[] {
    return StoreAppUtils.getPermissions(this.storeApp, this.$gettext);
  }

  get isPayByUser(): boolean {
    let subscription: Subscription | null = this.subsUtil.getActiveSubscription(this.storeApp);
    if (subscription && subscription.productType === 'VARIABLE_SUPPORT') {
      return false;
    } else {
      let product: Product | null = this.subsUtil.getProduct(this.storeApp);
      return !(product && product.type === 'VARIABLE_SUPPORT');
    }
  }

  get seatsToBuy(): SubscriptionUpgrade[] {
    return this.subsUtil.seatsRequired(this.storeApp, this.getUsers.length, this.selectedUsers, this.selectedGroups, this.getPaymentInterval);
  }

  get consolidatedSubscriptionUpdate(): SubscriptionUpgrade | null {

    let stb = [...this.seatsToBuy];

    if (stb.length === 0) {
      return null;
    }

    stb.sort((a: SubscriptionUpgrade, b: SubscriptionUpgrade) => {
      return a.price - b.price;
    });

    let chosenSub: SubscriptionUpgrade = Object.assign(new SubscriptionUpgrade(), stb[0]);
    let seatsSum: number = chosenSub.seatsToBuy;
    for (let i = 1; i < stb.length; i++) {
      seatsSum -= stb[i].seatsActive;
    }

    chosenSub.seatsToBuy = Math.max(seatsSum, 0);

    return chosenSub;
  }

  get totalSeatsActive(): number {
    let totalSeatsActive: number = 0;
    for (let s of this.seatsToBuy) {
      totalSeatsActive += s.seatsActive;
    }
    return totalSeatsActive;
  }

  get totalSeatsToBuy(): number {
    if (this.consolidatedSubscriptionUpdate) {
      return this.consolidatedSubscriptionUpdate.seatsToBuy;
    } else {
      return 0;
    }
  }

  get selectedCount(): number {
    if (this.selectedUsers) {
      return this.selectedUsers.length;
    }
    return 0;
  }

  get needsUpgrade(): boolean {
    if (this.consolidatedSubscriptionUpdate) {
      return this.subsUtil.isPaidApp(this.storeApp, null) && this.consolidatedSubscriptionUpdate.seatsToBuy > 0;
    } else {
      return false;
    }
  }

  show(): void {
    this.upgadeModal.show();
  }

  reset(): void {
    this.upgradeWizard.reset();
    this.upgadeModal.hide();
  }

  setUpgradeWizardStep(current: number, next: number) {
    this.step = next;
  }

  get title(): string {
    if (!this.subsUtil.hasPaymentMethod(this.getInvoiceContact) && this.subsUtil.isPaidApp(this.storeApp, null)) {
      return this.buyWizardTitles[0];
    } else {
      return this.buyWizardTitles[this.step + 1];
    }
  }

  get totalAmount(): number | null {
    if (this.consolidatedSubscriptionUpdate) {
      return this.consolidatedSubscriptionUpdate.seatsToBuy * this.consolidatedSubscriptionUpdate.price * (1 + this.consolidatedSubscriptionUpdate.taxRate / 10000);
    } else {
      return 0;
    }
  }

  get secondaryAppGroupOptions(): SelectableGroup[] | null {
    return this.storeApp ? StoreAppUtils.getSecondaryAppGroupsOfStoreApp(this.storeApp, this.installedApp, this.getGroups) : null;
  }

  get secondaryAppGroups(): any {
    const groups: any = {};
    for (const group of (this.secondaryAppGroupOptions || [])) {
      groups[group.id] = [];
      for (const userDn of this.selectedUsers) {
        if (group.required || (Array.isArray(this.selectedGroups[userDn]) && this.selectedGroups[userDn].find((g: SelectableGroup) => g.id === group.id))) {
          groups[group.id].push(userDn);
        }
      }
    }
    return groups;
  }

  get selectedUsers(): string[] {
    const selectedUsers = [...this.selectedUsersInternal];
    const requiredGroups: SelectableGroup[] = (this.secondaryAppGroupOptions || []).filter(o => o.required);
    for (const user of this.getUsers) {
      if (user.dn) {
        if (!this.selectedGroups[user.dn] || !Array.isArray(this.selectedGroups[user.dn])) {
          this.$set(this.selectedGroups, user.dn, []);
        }
        for (let requiredGroup of requiredGroups) {
          if (!this.selectedGroups[user.dn].find((g: SelectableGroup) => g.id === requiredGroup.id)) {
            this.selectedGroups[user.dn].push(requiredGroup);
          }
        }
        if (selectedUsers.indexOf(user.dn) < 0 && this.selectedGroups[user.dn].length > 0) {
          selectedUsers.push(user.dn);
        }
      }
    }
    return selectedUsers;
  }

  set selectedUsers(selectedUsers: string[]) {
    this.selectedUsersInternal = selectedUsers;
    const requiredGroups: SelectableGroup[] = (this.secondaryAppGroupOptions || []).filter(o => o.required);
    for (const user of this.getUsers) {
      if (user.dn && selectedUsers.indexOf(user.dn) < 0) {
        this.$set(this.selectedGroups, user.dn, []);
      } else if (user.dn) {
        if (!this.selectedGroups[user.dn] || !Array.isArray(this.selectedGroups[user.dn])) {
          this.$set(this.selectedGroups, user.dn, []);
        }
        for (let requiredGroup of requiredGroups) {
          if (this.selectedGroups[user.dn] && !this.selectedGroups[user.dn].find((g: SelectableGroup) => g.id === requiredGroup.id)) {
            this.selectedGroups[user.dn].push(requiredGroup);
          }
        }
      }
    }
  }

  redirectToCustomerCenter() {
    if (this.getInvoiceContact && this.getInvoiceContact.portalURL) {
      window.open(this.getInvoiceContact.portalURL, '_blank');
    } else {
      this.$snotify.error(this.$pgettext("notification", "Could not get customer center url."));
    }
  }

  buyOrUpgradeApp(): Promise<any> {
    if (!this.storeApp) {
      return Promise.resolve();
    } else if (this.needsUpgrade) { //TODO: Reactivate an existing subscription if there is one
      const promises: Promise<any>[] = [];
      let toUpgrade = this.consolidatedSubscriptionUpdate;
      if (toUpgrade) {
        if (toUpgrade.seatsToBuy > 0 && toUpgrade.subscription && toUpgrade.subscription.id && toUpgrade.productMapping && toUpgrade.productMapping.product && toUpgrade.productMapping.product.id) {
          promises.push(this.UPGRADE_SERVICE({
            subscriptionId: toUpgrade.subscription.id, planId: toUpgrade.productMapping.product.id, userCount: toUpgrade.seatsRequired
          }));
        } else if (toUpgrade.seatsToBuy > 0 && toUpgrade.product && toUpgrade.product.id) {
          promises.push(this.PURCHASE_SERVICE({
            planId: toUpgrade.product.id, userCount: toUpgrade.seatsRequired
          }));
        }
      }
      return new Promise((resolve, reject) => {
        Promise.all(promises).then(() => {
          this.$snotify.success(this.$pgettext("notification", "Updated subscription."));
        }).catch((error: { response: { data: { message: string; } } }) => {
          reject(error);
          this.$snotify.error(error.response.data.message, this.$pgettext("notification", "Subscription was not updated."));
        }).finally(() => {
          this.installOrUpdate().finally(() => {
            resolve();
            this.GET_STORE_APPS();
          });
        });
      });
    } else {
      return this.installOrUpdate();
    }
  }

  installOrUpdate(): Promise<any> {
    if (this.installedApp) {
      return this.updateApp();
    } else {
      return this.installApp();
    }
  }

  installApp(): Promise<any> {
    if (!this.storeApp) {
      return Promise.resolve();
    }

    let options: { users: string[] | null, groups: any };
    if (this.storeApp && !StoreAppUtils.storeAppSupportsGroups(this.storeApp)) {
      options = { users: null, groups: null }; //Groups not supported => select ALL users which is signaled by sending null.
    } else {
      options = { users: this.selectedUsers, groups: this.secondaryAppGroups };
    }
    return this.INSTALL_APP({
      storeId: this.storeApp.id as string,
      version: null,
      options
    }).then(() => {
      this.$snotify.success(this.$pgettext("notification", "Installing app"));
    }).catch((error: any) => {
      this.$snotify.error(error.response.data.message,
        this.$pgettext("notification", "Could not install app"));
    }).finally(() => {
      this.reset();
    });
  }

  updateApp(keepUsers?: boolean): Promise<any> {
    if (this.installedApp && this.installedApp.instanceId) {
      let options: { users: string[] | null, groups: any };
      if (keepUsers) {
        options = { users: null, groups: null }; //setting users to null means keeping them unchanged
      } else {
        options = { users: this.selectedUsers, groups: this.secondaryAppGroups };
      }
      return this.UPDATE_APP({
        instanceId: this.installedApp.instanceId,
        version: null,
        options
      }).then(() => {
        this.$snotify.success(this.$pgettext("notification", "Updating app"));
      }).catch((error: any) => {
        this.$snotify.error(error.response.data.message, this.$pgettext("notification", "Could not update app"));
      }).finally(() => {
        this.reset();
      });
    } else {
      this.$snotify.error(this.$pgettext("notification", "Could not update app"));
      return Promise.resolve();
    }
  }

  updateGroupMemberships(): void {
    //Must be set after props are set
    const installedApp: App | null = this.installedApp;
    const appGroups: SelectableGroup[] = StoreAppUtils.getSecondaryAppGroupsOfInstalledApp(installedApp, this.getGroups) || [];
    for (const user of this.getUsers) {
      if (user.dn) {
        const groupsOfUser = [];
        for (const appGroup of appGroups) {
          if (appGroup.group && appGroup.group.uniqueMember && appGroup.group.uniqueMember.indexOf(user.dn) >= 0) {
            groupsOfUser.push(appGroup);
          }
        }
        this.$set(this.selectedGroups, user.dn, groupsOfUser);
      }
    }
    //TODO: If not installed => find existing group and populate selectedUsers model
    if (installedApp && installedApp.userGroup) {
      const group: Group | undefined = this.getGroups.find(group => group.dn === installedApp.userGroup);
      if (group) {
        this.selectedUsers = group.uniqueMember || [];
      }
    } else {
      this.selectedUsers = [];
    }
  }

  mounted() {
    this.updateGroupMemberships();
  }
};
