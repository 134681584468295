import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import CacheUtil from "@/util/CacheUtil";

@Module
export default class ImageStore extends VuexModule {

  imageCache: Map<string, string> = new Map<string, string>();

  get getImageCache() {
    return this.imageCache;
  }

  @Mutation
  addOrReplaceImage(image: { url: string, blobUrl: string }) {
    this.imageCache.set(image.url, image.blobUrl);
    this.imageCache = new Map(this.imageCache); //Replace map so that Vuex can detect change!
  }

  @Action({ commit: 'addOrReplaceImage', rawError: true })
  GET_IMAGE(url: string): Promise<{ url: string, blobUrl: string }> {
    return axios({
      method: 'GET',
      url: url,
      responseType: 'arraybuffer'
    }).then(response => {
      let blob: Blob = new Blob([ response.data ]);
      let fullUrl: string;
      if (url.startsWith('http://') || url.startsWith('https://')) {
        fullUrl = url;
      } else if (url.startsWith('/')) {
        fullUrl = window.location.protocol + '//' + window.location.host + url;
      } else {
        fullUrl = window.location.href + '/' + url;
      }
      CacheUtil.put(url, blob);
      return { url: fullUrl, blobUrl: window.URL.createObjectURL(blob) };
    });
  }
};
