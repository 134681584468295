


















































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import LoadingButton from "../components/LoadingButton.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import axios from "axios";
import { BModal } from "bootstrap-vue";

@Component({
  components: {
    LoadingButton, ValidationObserver, ValidationProvider
  },
  props: {
    needsRAMUpgrade: Boolean
  }
})
export default class InstallGroupwareModal extends Vue {
  options: any = {};

  @Prop(Boolean)
  needsRAMUpgrade!: boolean

  $refs!: {
    installGroupwareModal: BModal;
  };

  newTeamText: string = 'Neues Team anlegen';

  importRocketChat: boolean = false;
  defaultImportTeam: string = this.newTeamText;
  defaultProjectTeam: string = this.newTeamText;
  importWekan: boolean = false;
  replaceKopano: boolean = false;
  importKopano: boolean = false;
  importTasks: boolean = false;
  importCalendars: boolean = false;
  importAddressbooks: boolean = false;
  importMail: boolean = false;
  sendLogs: boolean = true;
  iamsure: boolean = false;

  get teams(): string[] | null {
    const teams: string[] | null = this.options.teams ? Object.keys(this.options.teams) : null;
    if (teams !== null) {
      teams.push(this.newTeamText);
    }
    return teams;
  }

  installGroupware() {
    const options = {
      importRocketChat: this.importRocketChat,
      defaultImportTeam: this.defaultImportTeam === this.newTeamText ? null : this.options.teams[this.defaultImportTeam],
      defaultProjectTeam: this.defaultProjectTeam === this.newTeamText ? null : this.options.teams[this.defaultProjectTeam],
      importWekan: this.importWekan,
      replaceKopano: this.replaceKopano,
      importKopano: this.importKopano,
      importTasks: this.importTasks,
      importCalendars: this.importCalendars,
      importAddressbooks: this.importAddressbooks,
      importMail: this.importMail,
      sendLogs: this.sendLogs
    };
    axios.post('/api/v1/groupwareconfig/options', options);
    this.$refs.installGroupwareModal.hide();
  }

  show(options: any) {
    this.options = options;
    this.importRocketChat = !!this.options.rocketchat;
    this.defaultImportTeam = this.options.defaultTeam || this.newTeamText;
    this.defaultProjectTeam = this.options.defaultTeam || this.newTeamText;
    this.importWekan = !!this.options.wekan;
    this.replaceKopano = !!this.options.kopano;
    this.importKopano = !!this.options.kopano;
    this.importTasks = !!this.options.kopano;
    this.importCalendars = !!this.options.kopano;
    this.importAddressbooks = !!this.options.kopano;
    this.importMail = !!this.options.kopano;
    this.$refs.installGroupwareModal.show();
  }
}
