



























import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import { Action, Getter } from "vuex-class";
import Group from "@/model/Group";
import LoadingImage from "@/components/LoadingImage.vue";
import { BModal } from "bootstrap-vue";
import SelectableList from "@/components/SelectableList.vue";
import StoreApp from "@/model/StoreApp";
import UpdateGroupWizard from "@/components/UpdateGroupWizard.vue";
import App from "@/model/App";
import User from "@/model/User";

@Component({
  components: {
    UpdateGroupWizard,
    AnimatedInput,
    LoadingButton,
    LoadingImage,
    SelectableList
  }
})
export default class GroupOverviewItem extends Vue {

  @Getter getStoreApps!: StoreApp[];
  @Getter getGroups!: Group[];
  @Getter getApps!: App[];

  @Action GET_PAYMENT_OPTIONS!: () => Promise<any>;
  @Action GET_STORE_APPS!: () => Promise<any>;
  @Action GET_USERS!: () => Promise<User[]>;
  @Action GET_GROUPS!: () => Promise<Group[]>;

  @Prop(Boolean)
  isLoading!: boolean;

  @Prop(String)
  groupDn!: string;

  @Ref('upgradewizard') upgradeWizard!: BModal;

  get group(): Group | null {
    if (this.groupDn && this.getGroups) {
      return this.getGroups.find(group => group.dn === this.groupDn) || null;
    } else {
      return null;
    }
  }

  get storeAppId(): string {
    if (this.group && this.correspondingApp && this.correspondingApp.storeApp &&
        this.correspondingApp.storeApp.id) {
      return this.correspondingApp.storeApp.id;
    }
    return '';
  }

  get storeApp(): StoreApp | null {
    return this.getStoreApps.find((value: StoreApp) => {
      return value.id === this.storeAppId;
    }) || null;
  }

  get correspondingApp(): App | null {
    return this.getApps.find(app => Boolean(this.group && this.group.dn && app.userGroup === this.group.dn)) || null;
  }

  get uniqueMember() {
    return (this.group && this.group.uniqueMember) ? this.group.uniqueMember : [];
  }

  openGroupEditModal(): Promise<any> {
    return Promise.all([
      this.GET_PAYMENT_OPTIONS(),
      this.GET_STORE_APPS(),
      this.GET_GROUPS(),
      this.GET_USERS()
    ]).finally(() => {
      this.upgradeWizard.updateGroupMemberships();
      this.upgradeWizard.show();
    });
  }

  mounted() {}
}
