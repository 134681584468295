import Price from '@/model/invoice/Price';
import ProductVariant from '@/model/invoice/ProductVariant';
import ProductOption from '@/model/invoice/ProductOption';
import TaxRate from "@/model/invoice/TaxRate";

export default class Product {
  id!: string | null;
  name!: string | null;
  url!: string | null;
  users!: number | null;
  maxUsers!: number | null;
  apps!: number | null;
  maxApps!: number | null;
  unsupportedApps!: string[] | null;
  storage!: number | null;
  maxStorage!: number | null;
  windowsServer!: boolean | null;
  hotSwap!: boolean | null;
  silent!: boolean | null;
  raid!: boolean | null;
  rack!: boolean | null;
  redundantPower!: boolean | null;
  images!: string[] | null;
  productPrice!: Price | null;
  supportPrice!: Price | null;
  taxRate!: TaxRate | null;
  daysOfTrial!: number | null;
  shortDescription!: Map<string, string> | null;
  longDescription!: string | null;
  variants!: ProductVariant[] | null;
  options!: ProductOption[] | null;
  isPublic!: boolean | null;
  isCloud!: boolean | null;
  isBusinessProduct!: boolean | null;
  isConsumerProduct!: boolean | null;
  type!: any | null;
  externalId!: string | null;
  appId!: string | null;
  appVersion!: string | null;

  constructor() {
    this.id = null;
    this.name = null;
    this.url = null;
    this.users = null;
    this.maxUsers = null;
    this.apps = null;
    this.maxApps = null;
    this.unsupportedApps = null;
    this.storage = null;
    this.maxStorage = null;
    this.windowsServer = null;
    this.hotSwap = null;
    this.silent = null;
    this.raid = null;
    this.rack = null;
    this.redundantPower = null;
    this.images = null;
    this.productPrice = null;
    this.supportPrice = null;
    this.taxRate = null;
    this.daysOfTrial = null;
    this.shortDescription = null;
    this.longDescription = null;
    this.variants = null;
    this.options = null;
    this.isPublic = null;
    this.isCloud = null;
    this.isBusinessProduct = null;
    this.isConsumerProduct = null;
    this.type = null;
    this.externalId = null;
    this.appId = null;
    this.appVersion = null;
  }
}
