import Vue from 'vue';
import Vuex, { Module } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import session from '@/store/SessionStore';
import system from '@/store/SystemStore';
import feedback from '@/store/FeedbackStore';
import firewall from '@/store/FirewallStore';
import backup from '@/store/BackupStore';
import backupJob from '@/store/BackupJobStore';
import backupDevice from '@/store/BackupDeviceStore';
import app from '@/store/AppStore';
import storeApp from '@/store/StoreAppStore';
import adminMode from '@/store/AdminModeStore';
import hosting from '@/store/HostingStore';
import pstUpload from '@/store/PSTUploadStore';
import password from '@/store/PasswordStore';
import image from '@/store/ImageStore';
import user from '@/store/UserStore';
import status from '@/store/StatusStore';
import maintenanceMode from '@/store/MaintenanceModeStore';
import subscription from '@/store/SubscriptionStore';
import product from '@/store/ProductStore';
import message from '@/store/MessageStore';
import mailConfig from '@/store/MailConfigStore';
import domainConfig from '@/store/DomainConfigStore';
import twoFactor from '@/store/TwoFactorStore';
import oAuth2Store from "@/store/OAuth2Store";
import clientSettings from '@/store/ClientSettingsStore';
import userConfigValue from '@/store/UserConfigValueStore';
import networkShare from '@/store/NetworkShareStore';
import seafile from '@/store/SeafileStore';
import groups from '@/store/GroupStore';
import invoiceContact from '@/store/InvoiceContactStore';
import dataImport from '@/store/DataImportJobStore';
import wallpaper from '@/store/WallpaperStore';
import replicaConfig from '@/store/ReplicaConfigStore';
import systemConfigValue from '@/store/SystemConfigValueStore';
import tours from '@/store/TourStore';
import migration from '@/store/MigrationStore';
import cloneDeep from 'lodash/cloneDeep';
import forOwn from 'lodash/forOwn';
import Timers from "@/util/timers";
import CacheUtil from "@/util/CacheUtil";

Vue.use(Vuex);

const modules = {
  session,
  system,
  feedback,
  firewall,
  backup,
  backupJob,
  backupDevice,
  app,
  storeApp,
  adminMode,
  image,
  user,
  status,
  maintenanceMode,
  subscription,
  product,
  message,
  mailConfig,
  domainConfig,
  hosting,
  pstUpload,
  password,
  twoFactor,
  groups,
  oAuth2Store,
  clientSettings,
  userConfigValue,
  networkShare,
  seafile,
  invoiceContact,
  dataImport,
  wallpaper,
  replicaConfig,
  systemConfigValue,
  tours,
  migration
};

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: cloneDeep(modules),
  plugins: [ createPersistedState({ paths: [
    'session',
    'system',
    'firewall',
    'backup',
    'backupJob',
    'backupDevice',
    'app',
    'storeApp',
    'user',
    'subscription',
    'product',
    'mailConfig',
    'domainConfig',
    'hosting',
    'adminMode',
    'groups',
    'clientSettings',
    'userConfigValue',
    'networkShare',
    'seafile',
    'replicaConfig'
  ] }) ]
});

const clear = function (state: any) {
  forOwn(modules, (value, key) => {
    if (key !== 'status') {
      state[key] = cloneDeep(value.state);
    }
  });
  store.replaceState(state);
};

// Start status and messages loop
store.dispatch('GET_STATUS');
Timers.setInterval(Timers.STATUS, () => {
  store.dispatch('GET_STATUS');
}, 3000);
if (store.getters.isLoggedIn && !store.getters.needsTwoFactorConfirm) {
  store.dispatch('GET_MESSAGES');
  Timers.setInterval(Timers.MESSAGES, () => {
    store.dispatch('GET_MESSAGES');
  }, 3000);
}

CacheUtil.init().then((urls: string[]) => {
  for (let url of urls) {
    CacheUtil.get(url).then(blob => {
      store.commit('addOrReplaceImage', { url: url, blobUrl: window.URL.createObjectURL(blob) });
    });
  }
});

export { store, clear };
