






















































import { Vue, Component, Prop } from 'vue-property-decorator';
import LoadingButton from "../components/LoadingButton.vue";
import { Action, Getter } from "vuex-class";
import User from "../model/User";
import App from '../model/App';

@Component({
  components: {
    LoadingButton
  }
})
export default class PSTUpload extends Vue {
  @Getter getUsers!: User[];
  @Getter getMailDomains!: string[];
  @Getter loggedInIsAdmin!: boolean;
  @Getter loggedInUserName!: string;
  @Action GET_USERS!: () => Promise<any>;
  @Action UPLOAD_PST!: ({ userId, file }: { userId: string, file: File }) => Promise<any>;

  targetUser: string = "";
  pstFile!: File;
  fileSelected: boolean = false;

  acceptedContainerTypes = ['.pst'];

  get canImport(): boolean {
    return !!(this.targetUser && this.fileSelected);
  }

  getUserMails(): string[] {
    const userMails: string[] = [];
    for (let user of this.getUsers as User[]) {
      if (user.internEmail) userMails.push(user.internEmail);
    }
    return userMails;
  }

  handleFileSelected(pst: File | null | undefined): void {
    if (pst === null || pst === undefined) {
      this.fileSelected = false;
    } else {
      this.pstFile = pst;
      this.fileSelected = true;
    }
  }

  importMails(): Promise<any> {
    return this.UPLOAD_PST({
      userId: this.targetUser,
      file: this.pstFile
    }).then(() => {
          this.$snotify.success(this.$pgettext("notification", "PST file uploaded"));
      },
      error => {
          this.$snotify.error(this.$pgettext("server_message", error.response.data.message),
          this.$pgettext("notification", "Could not upload pst file"));
      });
  }

  beforeMount() {
    this.targetUser = this.loggedInUserName;
    this.GET_USERS();
  }
};
