import App from "@/model/App";

export default class Group {
  objectClass!: string[] | null;
  dn!: string | null;
  cn!: string | null;
  description!: string | null;
  mailLocalAddress!: string[] | null;
  entryParentId!: number | null;
  uniqueMember!: string[] | null;

  constructor() {
    this.objectClass = null;
    this.dn = null;
    this.cn = null;
    this.description = null;
    this.mailLocalAddress = null;
    this.entryParentId = null;
    this.uniqueMember = null;
  }
}
