import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import App from "@/model/App";
import Vue from 'vue';
import progressTypeToAppStatusMap from "@/util/progressTypeToAppStatusMap";
import User from "@/model/User";
import appUtils from "@/util/StoreAppUtils";

const url: string = '/api/v1/apps';

@Module
export default class AppStore extends VuexModule {
  apps: App[] = [];
  loading: boolean = false;

  get kopanoIsInstalled(): boolean {
    return this.getUserApps.findIndex((a: App) => { return a.storeId === "de.uniki.kopano" && a.status === 'RUNNING'; }) > -1;
  }

  get getApps () : App[] {
    return this.apps;
  }

  get getUserApps (): App[] {
    const userApps: App[] = [];
    const user: User | null = this.context.getters.getUser;
    if (this.getApps && user) {
      this.getApps.forEach((app: App) => {
        if (!appUtils.appSupportsGroups(app)) {
          userApps.push(app);
        } else if (app.userGroup && user && user.memberOf && user.memberOf.indexOf(app.userGroup) >= 0) {
          userApps.push(app);
        } else if (this.context.getters.loggedInIsAdmin && (app.status !== 'RUNNING' || app.userGroup === null)) {
          userApps.push(app);
        }
      });
    }
    return userApps;
  }

  push (app: App, userApps: App[]) {
    if (app.storeId && (app.storeId.startsWith('de.uniki.seafile') || app.storeId.startsWith('de.uniki.files'))) {
      userApps.unshift(app);
    } else {
      userApps.push(app);
    }
  }

  get getInstalledInstanceIds(): string[] {
    return this.apps.map((app: App) => { return (app.instanceId || ""); }).filter(instanceId => instanceId !== "");
  }

  @Mutation
  setApps (apps: App[]) {
    this.apps = apps;
  }

  @Mutation setAppsAreLoading (loading: boolean) {
    this.loading = loading;
  }

  @Mutation setAppStatus ({ appId, progress, progressType }: { appId: string, progress: number, progressType: string }) {
    this.apps.forEach((value, index, array) => {
      if (value.instanceId === appId) {
        value.progress = progress;
        value.status = progressTypeToAppStatusMap[progressType] || value.status;
        Vue.set(this.apps, index, value); //Set the property with reactivity
      }
    });
  }

  @Mutation setAppStatusByStoreIdAndVersion ({ storeId, version, progress, progressType }: { storeId: string, version: string, progress: number, progressType: string }) {
    this.apps.forEach((value, index, array) => {
      if (value.storeId === storeId && value.appVersion === version) {
        value.progress = progress;
        value.status = progressTypeToAppStatusMap[progressType] || value.status;
        Vue.set(this.apps, index, value); //Set the property with reactivity
      }
    });
  }

  @Mutation
  addOrReplaceApp (newApp: App) {
    let oldApp = this.apps.find((value: App) => {
      return value.instanceId === newApp.instanceId;
    });

    if (oldApp !== undefined) {
      this.apps = this.apps.map((value: App) => {
        if (value.instanceId === newApp.instanceId) {
          return newApp;
        } else {
          return value;
        }
      });
    } else {
      this.apps.push(newApp);
    }
  }

  @Mutation
  deleteApp (instanceId: string) {
    let toDelete = this.apps.find((app: App) => {
      return app.instanceId === instanceId;
    });

    if (toDelete !== undefined) {
      let index = this.apps.indexOf(toDelete);
      if (index > -1) {
        this.apps.splice(index, 1);
      }
    }
  }

  @Action({ commit: 'setApps', rawError: true })
  GET_INSTALLED_APPS() {
    this.context.commit("setAppsAreLoading", true);
    return axios.get(url).then((response) => {
      this.context.commit("setAppsAreLoading", false);
      return response.data ? response.data.map((app: App) => Object.assign(new App(), app)) : [];
    });
  }

  @Action({ commit: 'addOrReplaceApp', rawError: true })
  GET_APP(instanceId: string) {
    const encodedInstanceId = encodeURIComponent(instanceId);

    return axios.get(url + '/' + encodedInstanceId).then((response) => {
      return Object.assign(new App(), response.data);
    });
  }

  @Action({ commit: 'addOrReplaceApp', rawError: true })
  INSTALL_APP({ storeId, version, options }: { storeId: string, version: string | null, options: { users: string[] | null, groups: any } }) {
    const encodedStoreId = encodeURIComponent(storeId);
    let thisUrl = url + '/' + encodedStoreId;
    if (version) {
      thisUrl += '?version=' + encodeURIComponent(version);
    }

    return axios.post(thisUrl, options).then((response) => {
      return Object.assign(new App(), response.data);
    });
  }

  @Action({ commit: 'addOrReplaceApp', rawError: true })
  UPDATE_APP({ instanceId, version, options }: { instanceId: string, version: string | null, options: { users: string[] } }) {
    const encodedInstanceId = encodeURIComponent(instanceId);
    let thisUrl = url + '/' + encodedInstanceId;
    if (version) {
      thisUrl += '?version=' + encodeURIComponent(version);
    }

    return axios.patch(thisUrl, options).then((response) => {
      return Object.assign(new App(), response.data);
    });
  }

  @Action({ commit: 'addOrReplaceApp', rawError: true })
  RESTART_APP(instanceId: string) {
    const encodedInstanceId = encodeURIComponent(instanceId);

    return axios.put(url + '/' + encodedInstanceId).then((response) => {
      return Object.assign(new App(), response.data);
    });
  }

  @Action({ commit: 'deleteApp', rawError: true })
  UNINSTALL_APP({ instanceId, removeData, password }: { instanceId: string, removeData: boolean, password: string | null }) {
    const encodedInstanceId = encodeURIComponent(instanceId);
    let thisUrl = url + '/' + encodedInstanceId;
    if (removeData && password) {
      thisUrl += '?removeData=true&password=' + encodeURIComponent(password);
    }

    return axios.delete(thisUrl).then((response) => {
      return instanceId;
    });
  }
}
