











































































































































































import { Vue, Component } from "vue-property-decorator";
import { Action, Getter, Mutation, State } from "vuex-class";
import User from "../model/User";
import Message from "../model/Message";
import LoadingButton from "../components/LoadingButton.vue";
import SettingsMenuEntry from "../components/SettingsMenuEntry.vue";
import AdminMode from "../model/AdminMode";
import AppStore from "@/store/AppStore";
import ClientSettingsStore from "@/store/ClientSettingsStore";
import InvoiceContact from "@/model/InvoiceContact";

class SettingsLink {
  route!: string;
  icon!: string;
  descr!: string;
}

@Component({
  components: { LoadingButton, SettingsMenuEntry }
})
export default class SideBar extends Vue {
  @State("clientSettings") clientSettings!: ClientSettingsStore;
  @Getter getUser!: User | null;
  @Getter getNotifications!: Message[];
  @Getter loggedInIsAdmin!: boolean;
  @Getter loggedInIsSigning!: boolean;
  @Getter getAdminMode!: boolean;
  @Getter getInvoiceContact!: InvoiceContact | null;
  @Getter kopanoIsInstalled!: boolean;
  @Getter isSambaSharesDisabled!: boolean;
  @Mutation setSettingsMenuVisible!: (settingsMenuVisible: boolean) => any;
  @Action LOGOUT_REQUEST!: any;
  @Action UNSET_ADMIN_MODE: any;
  @Action GET_PAYMENT_OPTIONS!: any;

  get visible() {
    return this.clientSettings.settingsMenuVisible;
  }

  set visible(visible: boolean) {
    this.setSettingsMenuVisible(visible);
  }

  get accountLinkText(): string {
    return (this.getUser && this.getUser.givenname) ? this.getUser.givenname.trim() : 'Account';
  }

  get hasNotifications(): boolean {
    return this.getNotificationCount > 0;
  }

  get getNotificationCount(): number {
    if (typeof this.getNotifications === "undefined") return 0;
    else return this.getNotifications.length;
  }

  logout() {
    return this.LOGOUT_REQUEST().then(() => {
      if (this.$router.currentRoute.path !== "/") this.$router.replace("/");
      this.$snotify.success(this.$pgettext("notification", "Logged out."));
    });
  }

  redirectToCustomerCenter() {
    if (this.getInvoiceContact && this.getInvoiceContact.portalURL) {
      window.open(this.getInvoiceContact.portalURL, '_blank');
    } else {
      window.open('https://portal.uniki.de', '_blank');
    }
  }

  toggleSettings(): void {
    this.visible = !this.visible;
  }

  mounted() {
    this.GET_PAYMENT_OPTIONS();
  }
}
