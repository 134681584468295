















































import { Vue, Component } from 'vue-property-decorator';
import FirewallRule from '../components/FirewallRule.vue';
import { Action, Getter } from "vuex-class";
import Port from "../model/Port";
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";

@Component({
  components: {
    FirewallRule,
    AnimatedInput,
    LoadingButton
  }
})
export default class Firewall extends Vue {

  @Getter getFirewallRules!: Port[];

  @Action GET_FIREWALL_RULES!: () => Promise<any>;
  @Action CREATE_FIREWALL_RULE!: (rule: Port) => Promise<any>;

  portNumber: number | '' = '';

  addRule () {
    if (this.portNumber !== '') {
      this.CREATE_FIREWALL_RULE({ rule: 'ALLOW', number: this.portNumber }).then(() => {
        this.$snotify.success(this.$pgettext("notification", 'Firewall rule was added.'));
      }).catch((error) => {
        this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not add rule"));
      });
    } else {
      this.$snotify.error(this.$pgettext("notification", 'Please enter the port number you want to open for incoming connections.'));
    }
  }

  beforeMount () {
    this.GET_FIREWALL_RULES().catch((error) => {
      if (error.response.status !== 412) {
        this.$snotify.error(this.$pgettext("server_message", error.response.data.message));
      }
    });
  }
};
