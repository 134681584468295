import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import BackupDevice from "@/model/backup/BackupDevice";

const url: string = '/api/v1/backupdevices';

@Module
export default class BackupDeviceStore extends VuexModule {
  backupDevices: BackupDevice[] = [];
  loading: boolean = false;

  get getBackupDevices () : BackupDevice[] {
    return this.backupDevices;
  }

  @Mutation
  setBackupDevices (backupDevices: BackupDevice[]) {
    this.backupDevices = backupDevices;
  }

  @Mutation setBackupDevicesAreLoading (loading: boolean) {
    this.loading = loading;
  }

  @Action({ commit: 'setBackupDevices', rawError: true })
  GET_BACKUP_DEVICES() {
    this.context.commit("setBackupDevicesAreLoading", true);
    return axios.get(url).then((response) => {
      this.context.commit("setBackupDevicesAreLoading", false);
      return response.data ? response.data.map((backupDevice: BackupDevice) => Object.assign(new BackupDevice(), backupDevice)) : [];
    });
  }

  @Action({ rawError: true })
  GET_BACKUP_DEVICE(serial: string) {
    const encodedSerial = encodeURIComponent(serial);
    return axios.get(url + '/' + encodedSerial);
  }
}
