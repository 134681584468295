

















import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";

@Component
export default class LoadingImage extends Vue {

  //Getters and Actions mapped from store
  @Getter getImageCache!: Map<string, string>;
  @Action GET_IMAGE!: (src: string) => Promise<any>;

  //Properties passed to this component
  @Prop({ default: '' }) src!: string;
  @Prop({ default: '' }) imgClass!: string;
  @Prop({ default: '100%' }) width!: string;
  @Prop({ default: '100%' }) height!: string;
  @Prop(Function) clickEvent!: (any: any) => Promise<any>;
  @Prop() arguments!: any;

  //Internal state of the component
  isLoading: boolean = true;
  loadingFailed: boolean = false;
  isHandlingClicked: boolean = false;

  get elementStyle () {
    return `height: ${this.height}; width: ${this.width}`;
  }

  get getImageUrl (): string {
    let fullUrl: string;
    if (this.src.startsWith('http://') || this.src.startsWith('https://')) {
      fullUrl = this.src;
    } else if (this.src.startsWith('/')) {
      fullUrl = window.location.protocol + '//' + window.location.host + this.src;
    } else {
      fullUrl = window.location.href + '/' + this.src;
    }
    return this.getImageCache.get(fullUrl) || this.src;
  }

  setFailed () {
    this.isLoading = false;
    this.loadingFailed = true;
  }

  setSuccess () {
    this.isLoading = false;
    this.loadingFailed = false;
  }

  callRegisteredAction () {
    if (!this.isHandlingClicked && !!this.clickEvent) { //Prevent additional clicks!
      this.isHandlingClicked = true;
      let func = this.clickEvent;
      let promise: Promise<any> = func(this.arguments);
      if (promise) {
        promise.then((successObject: any) => {
          this.isHandlingClicked = false;
          setTimeout(() => {
          }, 400);

          if (!successObject) {
            return;
          }

          if (successObject.hasOwnProperty("redirect") && typeof successObject.redirect === "string") {
            this.$router.push(successObject.redirect);
          }

          if (successObject.hasOwnProperty("callback") && typeof successObject.callback === "function") {
            successObject.callback();
          }
        }, (errorObject: any) => {
          this.isHandlingClicked = false;
          setTimeout(() => {
          }, 400);

          //Test if we caught a platform error:
          if (errorObject instanceof Error) {
            throw errorObject;
          }

          if (!errorObject) {
            return;
          }

          if (errorObject.hasOwnProperty("redirect") && typeof errorObject.redirect === "string") {
            this.$router.push(errorObject.redirect);
          }

          if (errorObject.hasOwnProperty("callback") && typeof errorObject.callback === "function") {
            errorObject.callback();
          }
        });
      } else {
        this.isHandlingClicked = false;
        setTimeout(() => {
        }, 400);
      }
    }
  }

  beforeMount () {
    let promise: Promise<any> = this.GET_IMAGE(this.src);
    if (!this.src) {
      this.setFailed();
    } else if (this.getImageCache) {
      let fullUrl: string;
      if (this.src.startsWith('http://') || this.src.startsWith('https://')) {
        fullUrl = this.src;
      } else if (this.src.startsWith('/')) {
        fullUrl = window.location.protocol + '//' + window.location.host + this.src;
      } else {
        fullUrl = window.location.href + '/' + this.src;
      }
      if (this.getImageCache.has(fullUrl)) {
        this.setSuccess();
      } else {
        promise.then(() => {
          this.setSuccess();
        }).catch(() => {
          this.setFailed();
        });
      }
    } else {
      promise.then(() => {
        this.setSuccess();
      }).catch(() => {
        this.setFailed();
      });
    }
  }
}
