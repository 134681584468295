import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import Backup from "@/model/backup/Backup";

const url: string = '/api/v1/backups';

@Module
export default class BackupStore extends VuexModule {
  backups: Backup[] = [];
  loading: boolean = false;

  get getBackups () : Backup[] {
    return this.backups;
  }

  @Mutation setBackupsAreLoading (loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setBackups (backups: Backup[]) {
    this.backups = backups;
  }

  @Mutation
  deleteBackup (deletedBackupId: string) {
    let toDelete = this.backups.find((backup: Backup) => {
      return backup.id === deletedBackupId;
    });

    if (toDelete !== undefined) {
      let index = this.backups.indexOf(toDelete);
      if (index > -1) {
        this.backups.splice(index, 1);
      }
    }
  }

  @Action({ commit: 'setBackups', rawError: true })
  GET_BACKUPS() {
    this.context.commit("setBackupsAreLoading", true);
    return axios.get(url).then((response) => {
      this.context.commit("setBackupsAreLoading", false);
      return response.data ? response.data.map((backup: Backup) => Object.assign(new Backup(), backup)) : [];
    });
  }

  @Action({ rawError: true })
  CREATE_BACKUP(deviceId: string) {
    return axios.post(url, { deviceId: deviceId });
  }

  @Action({ rawError: true })
  RESTORE_BACKUP(backupId: string) {
    const encodedId = encodeURIComponent(backupId);
    return axios.put(url + '/' + encodedId);
  }

  @Action({ commit: 'deleteBackup', rawError: true })
  DELETE_BACKUP(backupId: string) {
    const encodedId = encodeURIComponent(backupId);
    return axios.delete(url + '/' + encodedId).then((response) => {
      this.context.dispatch('GET_BACKUPS');
      return backupId;
    });
  }
}
