
















































import { Vue, Component } from 'vue-property-decorator';
import App from "@/model/App";
import LoadingImage from "../components/LoadingImage.vue";
import AppGridItem from "../components/AppGridItem.vue";
import { Action, Getter, State } from 'vuex-class';
import StoreApp from "../model/StoreApp";
import appUtils from "@/util/StoreAppUtils";
import AppStore from "@/store/AppStore";
import SecretaryDisplay from "@/components/SecretaryDisplay.vue";
import User from "@/model/User";
import ManualEntry from "@/model/ManualEntry";
import MigrationDisplay from "@/components/MigrationDisplay.vue";
import Message from "@/model/Message";

@Component({
  components: { LoadingImage, AppGridItem, SecretaryDisplay, MigrationDisplay }
})
export default class Home extends Vue {

  @State("app") appState!: AppStore;

  @Getter getUser!: User | null;
  @Getter loggedInIsAdmin!: boolean;
  @Getter loggedInUserHasSecretary!: boolean;
  @Getter getApps!: App[];
  @Getter getUserApps!: App[];
  @Getter getStoreApps!: StoreApp[];
  @Getter getCurrentlyRunningBackups!: { text: string, progress: number }[];
  @Getter getCurrentlyRunningImports!: { text: string, progress: number }[];
  @Getter getProgressMessages!: Message[];

  @Action GET_INSTALLED_APPS!: any;
  @Action GET_STORE_APPS!: any;
  @Action GET_SYSTEM_INFORMATION!: any;
  @Action GET_USER!: (userId: string) => any;
  @Action GET_MIGRATION_STATUS!: () => Promise<any>;

  appUrl: string = '';
  manuals: ManualEntry[] = [];
  numberOfApps: number | null = null;
  migrationStatusLoop: number = 0;

  get loggedInUID (): string {
    if (!this.getUser) return "";
    return (this.getUser.uid || "");
  }

  get noApps (): boolean {
    let apps: App[] = this.getApps;
    return apps.length === 0;
  }

  get userApps (): App[] {
    let userApps: App[] = this.getUserApps;
    //Update group membership information because it is possible that it has changed during app installation
    //and apps would disappear if we don't refresh the group membership information
    if (this.numberOfApps != null && (userApps.length !== this.numberOfApps) && this.loggedInUID) {
      this.GET_USER(this.loggedInUID);
    }
    this.numberOfApps = userApps.length;
    return userApps.filter(app => !app.storeApp || !app.storeApp.dontShowInAppList);
  }

  get storeApps(): StoreApp[] {
      return this.getStoreApps;
  }

  get groupwareMigrationRunning(): boolean {
    let progress: Message | undefined = this.getProgressMessages.find(m => m.indicatorId === 'installGroupware' && m.status !== 'ERROR');
    if (progress && progress.progress && progress.progress >= 70 && progress.progress < 100) {
      if (this.migrationStatusLoop === 0) {
        this.migrationStatusLoop = window.setInterval(() => {
          if (this.migrationStatusLoop) {
            void this.GET_MIGRATION_STATUS();
          }
        }, 10000);
      }
      return true;
    } else {
      if (this.migrationStatusLoop) {
        window.clearInterval(this.migrationStatusLoop);
        this.migrationStatusLoop = 0;
      }
      return false;
    }
  }

  getManual(app: App): ManualEntry | undefined {
    if (this.manuals) {
      return this.manuals.find(entry => entry.app === app.storeId);
    } else {
      return undefined;
    }
  }

  beforeMount () {
    this.GET_INSTALLED_APPS();
    this.GET_SYSTEM_INFORMATION();
    this.GET_STORE_APPS();
    if (this.loggedInUID) { //Update group membership information
      this.GET_USER(this.loggedInUID);
    }
    import('../assets/manpages/de/index.json').then((indexJson: {default:{[key: string]: any}}) => {
      let allManuals: ManualEntry[] = [];
      for (let manual in indexJson.default) {
        allManuals.push(indexJson.default[manual]);
      }
      //Write Manuals
      this.manuals = allManuals;
    });
  }

  goToApp (instanceId: string) {
    this.$router.push('/app/' + instanceId);
  }

  goToStoreApp (storeId: string) {
    this.$router.push('/store/' + storeId);
  }
};
