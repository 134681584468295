import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import ReplicaConfig from "@/model/backup/ReplicaConfig";

const url: string = '/api/v1/replication/jobs';

@Module
export default class ReplicaConfigStore extends VuexModule {
  replicaJobs: ReplicaConfig[] = [];
  loading: boolean = false;

  get getReplicaConfigs () : ReplicaConfig[] {
    return this.replicaJobs;
  }

  @Mutation setReplicaConfigsAreLoading (loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setReplicaConfigs (replicaJobs: ReplicaConfig[]) {
    this.replicaJobs = replicaJobs;
  }

  @Mutation
  addReplicaConfig (newJob: ReplicaConfig) {
    this.replicaJobs.push(newJob);
  }

  @Mutation
  replaceReplicaConfig (newJob: ReplicaConfig) {
    this.replicaJobs = this.replicaJobs.map((job: ReplicaConfig) => {
      if (job.id === newJob.id) {
        return newJob;
      } else {
        return job;
      }
    });
  }

  @Mutation
  deleteReplicaConfig (deletedJobId: string) {
    let toDelete = this.replicaJobs.find((job: ReplicaConfig) => {
      return job.id === deletedJobId;
    });

    if (toDelete !== undefined) {
      let index = this.replicaJobs.indexOf(toDelete);
      if (index > -1) {
        this.replicaJobs.splice(index, 1);
      }
    }
  }

  @Action({ commit: 'setReplicaConfigs', rawError: true })
  GET_REPLICA_JOBS() {
    this.context.commit("setReplicaConfigsAreLoading", true);
    return axios.get(url).then((response) => {
      return response.data ? response.data.map((replicaJob: ReplicaConfig) => Object.assign(new ReplicaConfig(), replicaJob)) : [];
    }).finally(() => {
      this.context.commit("setReplicaConfigsAreLoading", false);
    });
  }

  @Action({ commit: 'addReplicaConfig', rawError: true })
  CREATE_REPLICA_JOB(data: { ip: string, serial: string, pin: string, cronExpression: string | null }) {
    return axios.post(url, data).then((response) => {
      return Object.assign(new ReplicaConfig(), response.data);
    }).finally(() => {
      this.context.dispatch('GET_REPLICA_JOBS');
    });
  }

  @Action({ commit: 'replaceReplicaConfig', rawError: true })
  UPDATE_REPLICA_JOB(data: { id: string, host: string | null, cronExpression: string | null }) {
    const encodedId = encodeURIComponent(data.id);
    return axios.patch(url + '/' + encodedId, data).then((response) => {
      return Object.assign(new ReplicaConfig(), response.data);
    }).finally(() => {
      this.context.dispatch('GET_REPLICA_JOBS');
    });
  }

  @Action({ commit: 'replaceReplicaConfig', rawError: true })
  REPLACE_REPLICA_JOB(data: { id: string, host: string, cronExpression: string | null }) {
    const encodedId = encodeURIComponent(data.id);
    return axios.put(url + '/' + encodedId, data).then((response) => {
      return Object.assign(new ReplicaConfig(), response.data);
    }).finally(() => {
      this.context.dispatch('GET_REPLICA_JOBS');
    });
  }

  @Action({ commit: 'deleteReplicaConfig', rawError: true })
  DELETE_REPLICA_JOB(replicaJobId: string) {
    const encodedId = encodeURIComponent(replicaJobId);
    return axios.delete(url + '/' + encodedId).then((response) => {
      //Add to state immediately and dispatch update from backend to ensure integrity
      return replicaJobId;
    }).finally(() => {
      this.context.dispatch('GET_REPLICA_JOBS');
    });
  }
}
