
































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import LoadingImage from "../components/LoadingImage.vue";
import DropBox from "../components/DropBox.vue";
import { Action, Getter } from "vuex-class";
import { ValidationObserver } from "vee-validate";
import User from "../model/User";
import validationErrors from "@/util/validationErrors";
import ConfigValue from "@/model/ConfigValue";
import UserUtils from "@/util/UserUtils";

@Component({
  components: {
    AnimatedInput, LoadingButton, LoadingImage, DropBox
  }
})
export default class UserEdit extends Vue {
  @Getter getUsers!: User[];
  @Getter getSystemConfigValues!: ConfigValue[];
  @Action GET_USER!: (userId: string) => Promise<any>;
  @Action REPLACE_USER!: ({ userId, user }: { userId: string, user: User }) => Promise<any>;
  @Action SET_PROFILE_PICTURE!: ({ userId, file }: { userId: string, file: File }) => Promise<any>;
  @Action GET_IMAGE!: (src: string) => Promise<any>;
  acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  loading: boolean = false;
  exists: boolean = false;
  image: File | any;
  origUser: User | any;
  user: User | any;
  selectedSecretary: User | null = null;

  $refs!: {
    usereditform: InstanceType<typeof ValidationObserver>;
  };

  labelUid: string = this.$pgettext("label", "Username");
  labelFirstName: string = this.$pgettext("label", "First name");
  labelLastName: string = this.$pgettext("label", "Last name");
  labelMobile: string = this.$pgettext("label", "Mobile number");
  labelPhone: string = this.$pgettext("label", "Phone number");
  labelExternMail: string = this.$pgettext("label", "Extern email");
  labelInternMail: string = this.$pgettext("label", "Intern email");

  labelFax: string = this.$pgettext("label", "Fax number");
  labelPostalAddress: string = this.$pgettext("label", "City");
  labelPostalCode: string = this.$pgettext("label", "Postal code");
  labelStreet: string = this.$pgettext("label", "Street");
  labelSecretary: string = this.$pgettext("label", "Secretary");

  get extern(): boolean {
    return UserUtils.userIsExternal(this.user.dn, this.getSystemConfigValues);
  }

  get syncExternalMailToExternalMail(): boolean {
    return UserUtils.syncExternalMailToExternalMail(this.getSystemConfigValues);
  }

  get getUserPicturePath () {
    return '/api/v1/users/' + this.user.uid + '/picture';
  }

  async validateThenUpdate () {
    //Update secretary:
    if (typeof this.selectedSecretary === "undefined" || this.selectedSecretary === null) {
      this.user.secretary = null;
    } else {
      this.user.secretary = this.selectedSecretary.dn;
    }
    //Now run validation:
    if (await this.$refs.usereditform.validate()) {
      return this.REPLACE_USER({ userId: this.user.uid, user: this.user }).then(() => {
          this.$snotify.success(this.$pgettext("notification", "User edited sucessfully"));
          this.goToUsers();
        }
      ).catch((error) => {
        this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not update user"));
      });
    } else {
      this.$snotify.error(
        await validationErrors(this.$refs.usereditform)
      );
    }
  }

  async setPicture (image: File) {
    if (this.isFileImage(image)) {
      this.loading = true;
      return this.SET_PROFILE_PICTURE({ userId: this.user.uid, file: image }).then(() => {
        this.$snotify.success(this.$pgettext("notification", "Profile picture uploaded successfully"));
        this.image = null;
        this.GET_IMAGE(this.getUserPicturePath).then(() => {
          this.loading = false;
        },
          () => {
            this.loading = false;
          });
      },
        error => {
          this.$snotify.error(error.response.data.message,
            this.$pgettext("notification", "Could not set profile picture"));
          this.loading = false;
        }
      );
    } else {
      this.$snotify.error(this.$pgettext("notification", "No Image found"));
    }
  }

  isFileImage (file: File) {
      return file && this.acceptedImageTypes.includes(file['type']);
  }

  goToUsers () {
    this.$router.push('/settings/users/');
  }

  created () {
    let uid = this.$router.currentRoute.params.id;
    this.GET_USER(uid);

    let user = this.getUsers.find((value: User) => {
      return value.uid === uid;
    });

    if (typeof user === "undefined") {
      this.exists = false;
      this.$snotify.error(this.$pgettext("notification", "User not found"));
      this.goToUsers();
    } else {
      this.origUser = user;
      this.user = new User();
      Object.assign(this.user, user);
      this.exists = true;

      let secretary = this.getUsers.find((value: User) => {
        return value.dn === this.origUser.secretary;
      });

      if (typeof secretary !== "undefined") this.selectedSecretary = secretary;
    }
  }
};
