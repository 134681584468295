





















































































import { Vue, Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import MailConfig from "@/model/MailConfig";

class IncomingServerOption {
  label!: string;
  code!: string;
}

@Component({
  components: {
    LoadingButton,
    AnimatedInput
  }
})
export default class MailSettings extends Vue {
  @Getter getMailConfig!: MailConfig | null;
  @Action GET_MAIL_CONFIG!: () => Promise<any>;
  @Action SET_MAIL_CONFIG!: (mailConfig: MailConfig) => Promise<any>;

  mailPlaceHolder: string = this.$pgettext("placeholder", "Your email address");
  userPlaceHolder: string = this.$pgettext("placeholder", "User name");
  passwordPlaceHolder: string = this.$pgettext("placeholder", "Password");
  serverNamePlaceHolder: string = this.$pgettext("placeholder", "Hostname or ip address");
  availableIncomingProtocols: IncomingServerOption[] = [
    { label: this.$pgettext('label', 'IMAP://'), code: 'imap' },
    { label: this.$pgettext('label', 'POP3://'), code: 'pop3' }
  ];

  //Mail config fields start
  incomingServer: string | null = "";
  incomingPort!: string | null;
  incomingType: IncomingServerOption = this.availableIncomingProtocols[0];
  incomingMailAddress!: string | null;
  incomingUsername: string | null = "";
  incomingPassword: string | null = "";
  incomingEncryption!: string | null;
  outgoingServer: string | null = "";
  outgoingPort!: string | null;
  outgoingMailAddress: string | null = "";
  outgoingUsername!: string | null;
  outgoingPassword!: string | null;
  outgoingEncryption!: string | null;
  folderNames!: string[] | null;
  keepOnServer: boolean | null = true;
  active: boolean | null = false;
  //Mail config fields end

  emailRegex: RegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  hostNameRegex: RegExp = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/;
  ipRegex: RegExp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  get canSave(): boolean {
    if (!this.active) return true;
    if (!this.isValidEmail) return false;
    if (!this.incomingUsername) return false;
    if (!this.incomingPassword) return false;
    if (!this.incomingType) return false;
    if (!this.isValidInHost && !this.isValidOutHost) return false;
    return true;
  }

  get isValidEmail(): boolean {
    if (!this.outgoingMailAddress) return false;
    return this.emailRegex.test(this.outgoingMailAddress);
  }

  get isValidInHost(): boolean {
    if (!this.incomingServer) return false;
    return (this.hostNameRegex.test(this.incomingServer) ||
            this.ipRegex.test(this.incomingServer));
  }

  get isValidOutHost(): boolean {
    if (!this.outgoingServer) return false;
    return (this.hostNameRegex.test(this.outgoingServer) ||
            this.ipRegex.test(this.outgoingServer));
  }

  findIncomingType(backendType: string): IncomingServerOption {
    let result: IncomingServerOption = this.availableIncomingProtocols[0]; //this is safe;
    this.availableIncomingProtocols.forEach((option: IncomingServerOption) => {
      if (option.code === backendType) result = option;
    });
    return result;
  }

  createConfig(): MailConfig {
    var config: MailConfig = new MailConfig();

    //Uncomment to delete old config on deactivation
    //if (!this.active) {
    //  //Deactivates config
    //  config.active = false;
    //  return config;
    //}

    config.incomingServer = this.incomingServer;
    //config.incomingPort = this.incomingPort;
    config.incomingType = this.incomingType.code;
    //config.incomingMailAddress = this.incomingMailAddress;
    config.incomingUsername = this.incomingUsername;
    config.incomingPassword = this.incomingPassword;
    //config.incomingEncryption = this.incomingEncryption;
    config.outgoingServer = this.outgoingServer;
    //config.outgoingPort = this.outgoingPort;
    config.outgoingMailAddress = this.outgoingMailAddress;
    //config.outgoingUsername = this.outgoingUsername;
    //config.outgoingPassword = this.outgoingPassword;
    //config.outgoingEncryption = this.outgoingEncryption;
    //config.folderNames = this.folderNames;
    config.keepOnServer = this.keepOnServer;
    config.active = this.active;

    config.outgoingUsername = this.incomingUsername;
    config.outgoingPassword = this.incomingPassword;

    return config;
  }

  sendConfig(): Promise<any> {
    var config: MailConfig = this.createConfig();
    return this.SET_MAIL_CONFIG(config).then(() => {
      this.$snotify.success(this.$pgettext("notification", "Configuration created"));
      this.updateConfig();
    },
    error => {
      this.$snotify.error(this.$pgettext("server_message", error.response.data.message),
        this.$pgettext("notification", "Could not create configuration. An error occurred."));
    });
  }

  updateConfig(): void {
    if (this.getMailConfig && this.getMailConfig.active) {
        this.incomingServer = this.getMailConfig.incomingServer;
        this.incomingPort = this.getMailConfig.incomingPort;
        this.incomingType = this.findIncomingType(this.getMailConfig.incomingType || "");
        this.incomingMailAddress = this.getMailConfig.incomingMailAddress;
        this.incomingUsername = this.getMailConfig.incomingUsername;
        this.incomingPassword = this.getMailConfig.incomingPassword;
        this.incomingEncryption = this.getMailConfig.incomingEncryption;
        this.outgoingServer = this.getMailConfig.outgoingServer;
        this.outgoingPort = this.getMailConfig.outgoingPort;
        this.outgoingMailAddress = this.getMailConfig.outgoingMailAddress;
        this.outgoingUsername = this.getMailConfig.outgoingUsername;
        this.outgoingPassword = this.getMailConfig.outgoingPassword;
        this.outgoingEncryption = this.getMailConfig.outgoingEncryption;
        this.folderNames = this.getMailConfig.folderNames;
        this.keepOnServer = this.getMailConfig.keepOnServer;
        this.active = this.getMailConfig.active;
      }
  }

  created() {
    this.GET_MAIL_CONFIG().then(() => {
      this.updateConfig();
    });
  }
}
