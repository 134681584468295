export default class TwoFactorRequest {
  code!: string | null;
  password!: string | null;
  enabled!: boolean | null;

  constructor() {
    this.code = null;
    this.password = null;
    this.enabled = null;
  }
}
