





























































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Getter, Mutation } from "vuex-class";
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import User from "@/model/User";
import axios from "axios/index";
import Timers from "@/util/timers";

@Component({
  components: { AnimatedInput, LoadingButton }
})
export default class SetupElly extends Vue {
  @Getter getStatus!: any;
  @Mutation receiveAuthToken!: (token: string) => void;
  @Mutation receiveSessionUser!: (user: User) => void;
  @Mutation setMessageRefreshEnabled!: (enabled: boolean) => any;

  nonce: string = "";

  //449-ao1
  path: string = "/api/v1/setup/new";
  strongRegex: RegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})");
  //at least 8 chars, lower/upper case letters und numbers
  mediumRegex: RegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
  emailRegex: RegExp = /^\w+([._+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

  firstNamePlaceholder: string = this.$pgettext("label", "Givenname");
  lastNamePlaceholder: string = this.$pgettext("label", "Surname");
  uidPlaceholder: string = this.$pgettext("label", "Username");
  passwordPlaceholder: string = this.$pgettext("label", "Password");
  pwRepeatPlaceholder: string = this.$pgettext("label", "Password repeat");
  pinPlaceholder: string = this.$pgettext("label", "Pin");
  emailPlaceholder: string = this.$pgettext("label", "Email");

  isCustonUid: boolean = false;
  password: string = "";
  pwRepeat: string = "";
  uid: string = "";
  firstName: string = "";
  lastName: string = "";
  externEmail: string = "";
  hideEmail: boolean = false;
  hideUid: boolean = false;
  pin: string = "";

  hasClicked: boolean = false;

  pwStrongText: string = this.$pgettext("password-strength-info", "Password strength: Strong");
  pwOkText: string = this.$pgettext("password-strength-info", "Password strength: OK");
  pwBadText: string = this.$pgettext("password-strength-info", "Password strength: Bad");

  replaceUmlauts(s: string): string {
    s = s.replace(/ä/gi, "ae");
    s = s.replace(/ü/gi, "ue");
    s = s.replace(/ö/gi, "oe");
    s = s.replace(/ß/gi, "ss");
    return s;
  }

  onUIDChanged(): void {
    if (this.uid) {
      this.uid = this.uid.replace('@', '.');
      this.isCustonUid = true;
      return;
    }
    this.isCustonUid = false;
  }
  onNameChanged() {
    if (this.isCustonUid) return;
    if (this.firstName && this.lastName) {
      this.uid = this.firstName + '.' + this.lastName;
    } else if (this.lastName) {
      this.uid = this.lastName;
    } else {
      this.uid = this.firstName;
    }
    this.uid = this.replaceUmlauts(this.uid.replace('@', '')).toLowerCase();
  }

  get firstPasswordEntered(): boolean {
    return this.password !== "";
  }

  get bothPasswordsEntered(): boolean {
    return this.firstPasswordEntered && this.pwRepeat !== "";
  }

  get canSubmit(): boolean {
    let isValidSecret: boolean = this.hasPIN || !!this.nonce;
    return this.hasName && this.hasUID &&
      this.hasPassword && this.hasPwRepeat &&
      isValidSecret && this.hasExternMail;
  }

  get hasName(): boolean {
    return !!(this.firstName && this.lastName);

  }

  get hasUID(): boolean {
    return !!(this.hideUid && this.externEmail) || !!this.uid;
  }

  get hasPassword(): boolean {
    return this.getPwStrength !== this.pwBadText;
  }

  get hasPwRepeat(): boolean {
    if (!this.password) return false;
    return this.password === this.pwRepeat;
  }

  get hasPIN(): boolean {
    return !!this.pin;
  }

  get hasExternMail(): boolean {
    return this.emailRegex.test(this.externEmail);
  }

  get hasSecurePw(): boolean {
    if (!this.hasPwRepeat) return false;
    return this.getPwStrength !== this.pwBadText;
  }

  get getPwStrength(): string {
    if (this.strongRegex.test(this.password)) {
      return this.pwStrongText;
    } else if (this.mediumRegex.test(this.password)) {
      return this.pwOkText;
    } else {
      return this.pwBadText;
    }
  }

  get getPwInfoColor(): string {
    let strength: string = this.getPwStrength;
    if (strength === this.pwStrongText) return "success";
    else if (strength === this.pwOkText) return "warning";
    else return "danger";
  }

  sendForm(): Promise<any> {
    this.hasClicked = true;
    let formData: FormData = new FormData();

    formData.append("uid", this.hideUid ? this.externEmail : (this.uid + '@' + this.getStatus.domain));
    formData.append("firstName", this.firstName);
    formData.append("lastName", this.lastName);
    formData.append("externEmail", this.externEmail);
    formData.append("password", this.password);
    formData.append("passwordRepeat", this.pwRepeat);
    formData.append("pin", this.pin);
    formData.append("nonce", this.nonce);

    return axios.post(this.path, formData).then((response: any) => {
      const authToken = response.headers['x-auth-token'];
      const user: User = Object.assign(new User(), response.data);
      this.receiveAuthToken(authToken);
      this.receiveSessionUser(user);
      this.setMessageRefreshEnabled(true);
      Timers.setInterval(Timers.MESSAGES, () => {
        this.$store.dispatch('GET_MESSAGES');
      }, 3000);
    }).catch(error => {
      this.hasClicked = false;
      this.$snotify.error(error.response.data.message,
        this.$pgettext("notification", "An error occurred"));
    });
  }

  getSetupConfigWithNonce() {
    void axios.get('/api/v1/setup?nonce=' + this.nonce).then((response: any) => {
      if (response.data && response.data.hasOwnProperty('email') && this.emailRegex.test(response.data.email)) {
        this.hideEmail = true;
        this.hideUid = true;
        this.externEmail = response.data.email;
        if (response.data.hasOwnProperty('firstName') && response.data.firstName) {
          this.firstName = response.data.firstName;
        }
        if (response.data.hasOwnProperty('lastName') && response.data.lastName) {
          this.lastName = response.data.lastName;
        }
      }
    }).catch((error: any) => {
      if (error.response && error.response.status === 400) {
        this.nonce = "";
      }
    });
  }

  @Watch('$route.query.nonce')
  watchNonce(nonce: string) {
    if (!this.nonce && nonce) {
      this.nonce = nonce;
      this.getSetupConfigWithNonce();
    }
  }

  mounted() {
    this.$nextTick(() => {
      let param: string = "";
      if (typeof this.$route.query.nonce === "string") param = this.$route.query.nonce;
      if (param !== "") this.nonce = param;
      if (this.nonce) {
        this.getSetupConfigWithNonce();
      }
    });
  }
}
