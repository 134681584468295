import Dataset from '@/model/backup/Dataset';

export default class Backup {
  id!: string;
  date!: string | null;
  deviceId!: string | null;
  datasetsToChunks!: Map<string, Dataset> | null;
  available!: boolean | null;
  sizeInKBytes!: number | null;

  constructor() {
    this.date = null;
    this.deviceId = null;
    this.datasetsToChunks = null;
    this.available = null;
    this.sizeInKBytes = null;
  }
}
