import ProductMapping from '@/model/invoice/ProductMapping';
import TaxRate from '@/model/invoice/TaxRate';
import User from '@/model/User';
import Order from '@/model/invoice/Order';
import Device from '@/model/Device';

export default class Subscription {
  id!: number | null;
  reference!: string | null;
  paymentMethod!: any | null;
  paymentInterval!: number | null;
  productType!: any | null;
  state!: any | null;
  value!: string | null;
  products!: ProductMapping[] | null;
  netAmount!: number | null;
  taxAmount!: number | null;
  taxRate!: TaxRate | null;
  currency!: string | null;
  stripeSubscription!: string | null;
  klarnaToken!: string | null;
  cancellationReceived!: any | null;
  cancellationDue!: any | null;
  stateUpdatedAt!: any | null;
  lastProcessed!: any | null;
  lastInvoiced!: any | null;
  nextPayment!: any | null;
  created!: any | null;
  updated!: any | null;
  user!: User | null;
  orders!: Order[] | null;
  device!: Device | null;

  constructor() {
    this.id = null;
    this.reference = null;
    this.paymentMethod = null;
    this.paymentInterval = null;
    this.productType = null;
    this.state = null;
    this.value = null;
    this.products = null;
    this.netAmount = null;
    this.taxAmount = null;
    this.taxRate = null;
    this.currency = null;
    this.stripeSubscription = null;
    this.klarnaToken = null;
    this.stateUpdatedAt = null;
    this.lastProcessed = null;
    this.lastInvoiced = null;
    this.nextPayment = null;
    this.cancellationReceived = null;
    this.cancellationDue = null;
    this.created = null;
    this.updated = null;
    this.user = null;
    this.orders = null;
    this.device = null;
  }
}
