






















































import { Vue, Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import FactoryResetRequest from "../model/FactoryResetRequest";

@Component({
  components: {
    LoadingButton,
    AnimatedInput
  }
})
export default class Reset extends Vue {
  @Action FACTORY_RESET!: (request: FactoryResetRequest) => Promise<any>;
  password: string = "";
  passwordPlaceholder: string = this.$pgettext("placeholder", "Password");
  modalTitle: string = this.$pgettext("title", "Factory reset your device");

  get canReset(): boolean {
    if (this.password) return true;
    return false;
  }

  doReset() {
    if (this.password) {
      var resetRequest = new FactoryResetRequest();
      resetRequest.password = this.password;
      return this.FACTORY_RESET(resetRequest).then(
        () => {
          this.$snotify.success(this.$pgettext("notification", "Performing factory reset"));
          this.$bvModal.hide('resetModal');
        },
        error => {
          this.$snotify.error(error.response.data.message,
            this.$pgettext("notification", "Could not perform factory reset"));
          this.$bvModal.hide('resetModal');
        }
      );
    } else {
      this.$snotify.error(this.$pgettext("notification", "No password provided!"));
      this.$bvModal.hide('resetModal');
    }
  }
}
