

































































































import { Vue, Component } from "vue-property-decorator";
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import { Action, Getter, Mutation } from "vuex-class";
import validationErrors from "@/util/validationErrors";
import { ValidationObserver } from "vee-validate";
import UserConfigValue from "@/model/UserConfigValue";
import TwoFactorRequest from "@/model/TwoFactorRequest";
import User from "@/model/User";
import OAuthAuthorizationRequest from "@/model/OAuthAuthorizationRequest";
import Timers from "@/util/timers";

const OAUTH_USER_CONFIG_KEY: string = 'oauth2.allowed.clients';

@Component({
  components: {
    AnimatedInput,
    LoadingButton
  }
})
export default class OAuth2 extends Vue {
  @Getter getUserConfigValues!: UserConfigValue[];
  @Action GET_USER_CONFIG_VALUES!: () => Promise<any>;
  @Action SET_USER_CONFIG_VALUE!: (config: UserConfigValue) => Promise<any>;
  @Action LOGIN_REQUEST!: ({ username, password }: { username: string, password: string }) => Promise<any>;
  @Action LOGOUT_REQUEST!: () => Promise<any>;
  @Action CHECK_TOKEN!: (request: TwoFactorRequest) => Promise<any>;
  @Action OAUTH2_AUTHORIZE!: (request: OAuthAuthorizationRequest) => Promise<any>;

  @Mutation setTwoFactorConfirmed!: () => any;

  username: string = "";
  password: string = "";
  twoFactorCode: string = "";
  loading: boolean = false;

  $refs!: {
    loginform: InstanceType<typeof ValidationObserver>;
  };

  clientId: string = "";
  responseType: string = "";
  oauthRedirectURI: string = "";

  @Getter isLoggedIn!: boolean;
  @Getter needsTwoFactorConfirm!: boolean;

  @Mutation setMessageRefreshEnabled!: (enabled: boolean) => any;

  get isValidRequest(): boolean {
    if (this.hasAlreadyAllowedScopes) {
      this.doOauth2();
    }
    return Boolean(this.clientId && this.responseType);
  }

  get hasAlreadyAllowedScopes(): boolean {
    if (!this.clientId) return false;

    let config: UserConfigValue | undefined = this.getUserConfigValues.find((config: UserConfigValue) => {
      return config.id === OAUTH_USER_CONFIG_KEY;
    });

    if (config && config.value) {
      let clientConfig = JSON.parse(config.value);
      return clientConfig.hasOwnProperty(this.clientId); //TODO: Check exact scopes
    } else {
      return false;
    }
  }

  async validateThenLogin () {
    let results;
    results = await this.$refs.loginform.validate();
    if (results) {
      this.loading = true;
      await this.login();
    } else {
      this.$snotify.error(
        await validationErrors(this.$refs.loginform),
        this.$pgettext("notification", "Login failed.")
      );
    }
  }

  async saveScopesAndDoOAuth2 () {
    this.loading = true;
    await this.setConfig();
    await this.doOauth2();
  }

  doOauth2 (): Promise<any> {
    return this.OAUTH2_AUTHORIZE({
      clientId: this.clientId, responseType: this.responseType, oauthRedirectURI: this.oauthRedirectURI
    }).then((response) => {
      window.location = response;
    }).catch(error => {
      const code = error.response.status;
      switch (code) {
        case 302: //Client found but the response_type is wrong
          this.$snotify.error(this.$pgettext("notification", "OAuth response type is invalid."));
          break;
        case 401:
          this.$snotify.error(this.$pgettext("notification", "OAuth client is not registered."));
          break;
        default:
          this.$snotify.error(this.$pgettext("notification", "OAuth failed."));
      }
    }).finally(() => {
      this.loading = false;
    });
  }

  login (): Promise<any> {
    return this.LOGIN_REQUEST({ username: this.username, password: this.password }).then(() => {
        if (!this.needsTwoFactorConfirm) {
          this.setMessageRefreshEnabled(true);
          Timers.setInterval(Timers.MESSAGES, () => {
            this.$store.dispatch('GET_MESSAGES');
          }, 3000);
          this.GET_USER_CONFIG_VALUES().then(() => {
              if (this.hasAlreadyAllowedScopes) {
                return this.doOauth2();
              } else {
                this.loading = false;
              }
            }
          ).catch(() => {
            this.loading = false;
          });
        } else {
          this.loading = false;
        }
      }).catch(error => {
        this.loading = false;
        const code = error.response.status;
        switch (code) {
          case 422:
            this.$snotify.error(this.$pgettext("notification", "Username or Password wrong."));
            break;
          default:
            this.$snotify.error(this.$pgettext("notification", "Login failed."));
        }
      });
  }

  logout(): Promise<any> {
    return this.LOGOUT_REQUEST().then(() => {
      this.$router.push('/');
    });
  }

  confirmTwoFactor(): Promise<any> {
    return this.CHECK_TOKEN({ code: this.twoFactorCode, password: null, enabled: true }).then(() => {
      this.setMessageRefreshEnabled(true);
      Timers.setInterval(Timers.MESSAGES, () => {
        this.$store.dispatch('GET_MESSAGES');
      }, 3000);
      this.setTwoFactorConfirmed();
      this.GET_USER_CONFIG_VALUES().then(() => {
          if (this.hasAlreadyAllowedScopes) {
            return this.doOauth2();
          } else {
            this.loading = false;
          }
        }
      ).catch(() => {
        this.loading = false;
      });
    });
  }

  setConfig(): Promise<any> {
    if (this.clientId) {
      let config: UserConfigValue | undefined = this.getUserConfigValues.find((config: UserConfigValue) => {
        return config.id === OAUTH_USER_CONFIG_KEY;
      });

      let value;
      if (config && config.value) {
        value = JSON.parse(config.value);
      } else {
        config = new UserConfigValue();
        config.id = OAUTH_USER_CONFIG_KEY;
        value = {};
      }
      value[this.clientId] = { scopes: 'sub' };
      config.value = JSON.stringify(value);

      return this.SET_USER_CONFIG_VALUE(config).catch(error => {
        this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not save the accepted scopes."));
      }).finally(() => {
        this.loading = false;
      });
    } else {
      return Promise.resolve();
    }
  }

  created() {
    if (typeof this.$route.query["oauthClient"] === 'string') {
      this.clientId = this.$route.query["oauthClient"] as string;
    }
    if (typeof this.$route.query["oauthResponseType"] === 'string') {
      this.responseType = this.$route.query["oauthResponseType"] as string;
    }
    if (typeof this.$route.query["oauthRedirectURI"] === 'string') {
      this.oauthRedirectURI = this.$route.query["oauthRedirectURI"] as string;
    }

    if (this.isLoggedIn) {
      this.GET_USER_CONFIG_VALUES().then(() => {
        if (this.hasAlreadyAllowedScopes) {
          return this.doOauth2();
        } else {
          this.loading = false;
        }
      });
    }
  }
}
