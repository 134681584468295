var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings-panel panel"},[_c('h2',[_c('translate',{attrs:{"translate-context":"title"}},[_vm._v(" Failover Configuration ")])],1),_c('p',[_c('b',[_c('translate',[_vm._v("What you can do here:")])],1),_vm._v(" "),_c('translate',[_vm._v("A failover configuration helps to avoid downtime in case of a hardware defect. If you own two or more ELLY servers, you can configure this server as the primary server and others as failover servers. This server's data will then be replicated continuously to the failover server(s). In case this server goes offline, the failover server(s) can take over.")])],1),_c('p',[_c('b',[_c('translate',[_vm._v("What you can't do here:")])],1),_vm._v(" "),_c('translate',[_vm._v("If you own two or more ELLY servers and want to synchronize files and folders between them in real time, go to \"Synchronization\".")])],1),_c('div',{staticClass:"row mt-6"},[_c('div',{staticClass:"col-auto"},[_c('b-button',{staticClass:"mb-2 align-self-end",attrs:{"id":"createReplicaJobButton","block":"","variant":"outline-primary"},on:{"click":function($event){$event.preventDefault();return _vm.$bvModal.show('createReplicaJobModal')}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "),_c('translate',{attrs:{"translate-context":"button"}},[_vm._v("Add Failover Server")])],1)],1)]),_c('div',{staticClass:"row mt-6"},[(_vm.getReplicaConfigs.length > 0)?_c('div',{staticClass:"col"},[_c('h3',[_c('translate',[_vm._v("Failover Configurations")])],1),_vm._l((_vm.getReplicaConfigs),function(config){return _c('ReplicationGridItem',{key:config.id,attrs:{"config":config}})})],2):_vm._e()]),_c('b-modal',{attrs:{"hide-footer":"","size":"lg","id":"createReplicaJobModal","title":_vm.modalTitle,"title-class":"h3 p-3"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"p-lg-3"},[_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"mb-1 mt-3"},[_c('translate',[_vm._v("Enter the IP address of the failover server:")])],1),_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.labelServerAddress},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AnimatedInput',{attrs:{"error":errors[0],"inline":"","label":_vm.labelServerAddress,"name":"ip","ntype":"text"},model:{value:(_vm.ip),callback:function ($$v) {_vm.ip=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"ip"}})]}}],null,true)}),_c('p',{staticClass:"mb-1 mt-3"},[_c('translate',[_vm._v("Enter the serial number of the remote server:")])],1),_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.labelSerial},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AnimatedInput',{attrs:{"error":errors[0],"inline":"","label":_vm.labelSerial,"name":"serial","ntype":"text"},model:{value:(_vm.serial),callback:function ($$v) {_vm.serial=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"serial"}})]}}],null,true)}),_c('p',{staticClass:"mb-1 mt-3"},[_c('translate',[_vm._v("Enter the setup PIN of the remote server:")])],1),_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.labelPIN},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AnimatedInput',{attrs:{"error":errors[0],"inline":"","label":_vm.labelPIN,"name":"pin","ntype":"text"},model:{value:(_vm.pin),callback:function ($$v) {_vm.pin=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"pin"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row justify-content-around pt-3"},[_c('div',{staticClass:"col"},[_c('b-button',{attrs:{"variant":"outline-primary","type":"button","block":""},on:{"click":_vm.closeModal}},[_c('translate',{attrs:{"translate-context":"button"}},[_vm._v("Close")])],1)],1),_c('div',{staticClass:"col"},[_c('LoadingButton',{staticClass:"btn-block",attrs:{"block":"","id":"saveReplicaJobButton","disabled":invalid,"variant":"outline-success","action":_vm.createReplicaJob}},[_c('translate',{attrs:{"translate-context":"button"}},[_vm._v("Add")])],1)],1)])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }