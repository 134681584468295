


























import { Vue, Component, Prop } from 'vue-property-decorator';
import LoadingImage from "../components/LoadingImage.vue";
import { Action, Getter } from "vuex-class";
import User from "../model/User";

@Component({
  components: {
    LoadingImage
  }
})
export default class SecretaryDisplay extends Vue {

  @Prop({ default: '' }) idOfUser!: string;
  @Action GET_SECRETARY!: (userId: string) => Promise<User>;
  //Secretary is only displayed once, so local state is sufficient:
  loading: boolean = true;
  hidden: boolean = false;
  secretary: User | null = null;

  get secretaryImageUrl(): string {
    return this.secretary ? '/api/v1/users/' + this.secretary.uid + '/picture' : '';
  }

  get hasAdress(): boolean {
    return Boolean(this.secretary && typeof this.secretary.street === "string" &&
      typeof this.secretary.postalCode === "string" && typeof this.secretary.postalAddress === "string");
  }

  beforeMount() {
    if (this.idOfUser === "") {
      this.hidden = true;
      return;
    }

    this.GET_SECRETARY(this.idOfUser).then((user: User) => {
      if (user) {
        this.secretary = user;
      } else {
        this.hidden = true;
      }
    }).catch(() => {
      this.hidden = true;
    }).finally(() => {
      this.loading = false;
    });
  }
}
