




























































import { Vue, Component, Ref } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import SelectableList from "@/components/SelectableList.vue";
import LoadingImage from "@/components/LoadingImage.vue";
import { FormWizard } from "vue-form-wizard";
import ReplicaConfig from "@/model/backup/ReplicaConfig";
import ReplicationGridItem from "@/components/ReplicationGridItem.vue";

@Component({
  components: {
    ReplicationGridItem,
    LoadingButton,
    AnimatedInput,
    SelectableList,
    LoadingImage
  }
})
export default class ReplicaJobs extends Vue {

  libraryNameLabel: string = this.$pgettext("dataimport", "Name");

  @Action CREATE_REPLICA_JOB!: (job: { ip: string, serial: string, pin: string, cronExpression: string | null }) => Promise<ReplicaConfig>;
  @Action GET_REPLICA_JOBS!: () => Promise<ReplicaConfig[]>;
  @Action DELETE_REPLICA_JOB!: (replicaJobId: string) => Promise<any>;

  @Getter getReplicaConfigs!: ReplicaConfig[];
  @Getter getCurrentlyRunningReplicas!: { id: string, progress: number }[];

  @Ref('createReplicaJobWizard') createReplicaJobWizard!: FormWizard;

  modalTitle: string = this.$gettext('Create Failover Configuration');

  labelSerial: string = this.$gettext('Serial');
  labelPIN: string = this.$gettext('PIN');
  labelServerAddress: string = this.$gettext('IP Address');

  ip: string | null = null;
  serial: string | null = null;
  pin: string | null = null;
  hours: number | null = 1;

  createReplicaJob(): Promise<any> {
    if (this.ip && this.serial && this.pin) {
      return this.CREATE_REPLICA_JOB({ ip: this.ip, serial: this.serial, pin: this.pin, cronExpression: '0 0 * * * *' }).then(() => {
        this.$snotify.success(this.$pgettext("notification", "The replication was started."));
        this.closeModal();
      }).catch(error => {
        this.$snotify.error(this.$pgettext("server_message", error.response.data.message),
          this.$pgettext("notification", "Could start replication. An error occurred."));
      });
    } else {
      return Promise.resolve();
    }
  }

  closeModal() {
    this.$bvModal.hide('createReplicaJobModal');
    this.ip = null;
    this.serial = null;
    this.pin = null;
    this.hours = null;
  }

  created() {
    this.GET_REPLICA_JOBS();
  }
}
