
































import { Vue, Component } from "vue-property-decorator";
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import { Action, Getter, Mutation } from "vuex-class";
import PasswordResetRequest from "@/model/PasswordResetRequest";

@Component({
  components: {
    AnimatedInput,
    LoadingButton
  }
})
export default class PasswordForgotten extends Vue {
  @Action REQUEST_PASSWORD_RESET_MAIL!: (userId : string) => Promise<any>;

  userId: string = "";

  requestPasswordResetMail(): Promise<any> {
    return this.REQUEST_PASSWORD_RESET_MAIL(this.userId).then(() => {
      this.$snotify.success(this.$pgettext('notification', 'Password reset email was sent.'));
    }).catch((error) => {
      this.$snotify.error(error.response.data.message,
        this.$pgettext("notification", "Could not send password reset email"));
    }).finally(() => {
      this.$router.push("/");
    });
  }

}
