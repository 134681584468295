import PaymentOption from '@/model/PaymentOption';

export default class InvoiceContact {
  paymentOptions!: PaymentOption[] | null;
  portalURL!: string | null;
  city!: string | null;
  country!: string | null;
  addressLine1!: string | null;
  addressLine2!: string | null;
  state!: string | null;
  zipCode!: string | null;
  company!: string | null;

  constructor() {
    this.paymentOptions = null;
    this.portalURL = null;
    this.city = null;
    this.country = null;
    this.addressLine1 = null;
    this.addressLine2 = null;
    this.state = null;
    this.zipCode = null;
    this.company = null;
  }
}
