

































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";
import LoadingButton from "@/components/LoadingButton.vue";
import AnimatedInput from "../components/AnimatedInput.vue";
import DomainChip from "../components/DomainChip.vue";
import App from "@/model/App";

@Component({
  components: { LoadingButton, AnimatedInput, DomainChip }
})
export default class DomainGridItem extends Vue {
  @Getter getStatus!: any | null;
  @Prop(Object) app!: App;
  @Action SET_DOMAIN_CONFIG!: ({ instanceId, alternativeDomains }: { instanceId: string, alternativeDomains: string[] }) => Promise<any>;
  @Action GET_INSTALLED_APPS!: () => Promise<any>;
  modalTitle: string = this.$pgettext("title", "Add Domain");

  isCheckedAddSubdomain: Boolean = false;
  domainName: string = "";

  get hasAlternatives(): Boolean {
    return (this.app.alternativeDomains !== null &&
            this.app.alternativeDomains.length > 0);
  }

  checkInput(): void {
    this.domainName = this.domainName.replace('@', '.');
  }

  getDomainToAdd(): string {
    let toAdd: string = this.domainName;
    if (this.isCheckedAddSubdomain) toAdd = toAdd + '.' + this.getStatus.domain;
    return toAdd;
  }

  get isDomainValid(): Boolean {
    let toCheck: string = this.getDomainToAdd();
    const re = new RegExp(/^((?:(?:(?:\w[.\-+]?)*)\w)+)((?:(?:(?:\w[.\-+]?){0,62})\w)+)\.(\w{2,6})$/);
    let isNotInUse: Boolean = !(this.app.alternativeDomains as string[]).includes(toCheck);
    isNotInUse = isNotInUse && (toCheck !== this.getStatus.domain);

    let isValidDomain: Boolean = re.test(toCheck);

    return isNotInUse && isValidDomain;
  }

  reset(): void {
    this.$bvModal.hide('addDomainModal-' + this.app.instanceId);
    this.isCheckedAddSubdomain = false;
    this.domainName = '';
  }

  addDomain(): Promise<any> {
    var alternatives = (this.app.alternativeDomains as string[]).slice();
    alternatives.push(this.getDomainToAdd());

    return this.updateDomains(alternatives, this.$pgettext("notification", "Domain added"));
  }

  updateDomains(alternatives: string[], successMessage: string): Promise<any> {
    return this.SET_DOMAIN_CONFIG({
      instanceId: this.app.instanceId as string,
      alternativeDomains: alternatives
    }).then(() => {
      this.$snotify.success(successMessage);
      this.reset();
      this.GET_INSTALLED_APPS();
    },
    error => {
      this.$snotify.error(error.response.data.message,
        this.$pgettext("notification", "Could not update domains. An error occurred."));
    });
  }
};
