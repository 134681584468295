import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import Subscription from "@/model/invoice/Subscription";

const url: string = '/api/v1/subscriptions';

@Module
export default class SubscriptionStore extends VuexModule {
  subscriptions: Subscription[] = [];
  loading: boolean = false;

  get getNextPayment (): number | null {
    let deviceSubscription: Subscription | undefined = this.subscriptions.find((s: Subscription) => {
      return s.state !== 'INACTIVE' && s.productType === 'DEVICE';
    });
    return deviceSubscription ? deviceSubscription.nextPayment : null;
  }

  get getPaymentInterval (): number | null {
    let deviceSubscription: Subscription | undefined = this.subscriptions.find((s: Subscription) => {
      return s.state !== 'INACTIVE' && s.productType === 'DEVICE';
    });
    return deviceSubscription ? deviceSubscription.paymentInterval : null;
  }

  get getSubscriptions () : Subscription[] {
    return this.subscriptions;
  }

  @Mutation
  setSubscriptions (subscriptions: Subscription[]) {
    this.subscriptions = subscriptions;
  }

  @Mutation
  setSubscriptionsAreLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  addOrReplaceSubscription (newSubscription: Subscription) {
    let oldSubscription = this.subscriptions.find((value: Subscription) => {
      return value.id === newSubscription.id;
    });

    if (oldSubscription !== undefined) {
      this.subscriptions = this.subscriptions.map((value: Subscription) => {
        if (value.id === newSubscription.id) {
          return newSubscription;
        } else {
          return value;
        }
      });
    } else {
      this.subscriptions.push(newSubscription);
    }
  }

  @Action({ commit: 'setSubscriptions', rawError: true })
  GET_SUBSCRIPTIONS() {
    this.context.commit("setSubscriptionsAreLoading", true);
    return axios.get(url).then((response) => {
      this.context.commit("setSubscriptionsAreLoading", false);
      return response.data ? response.data.map((s: Subscription) => Object.assign(new Subscription(), s)) : [];
    });
  }

  @Action({ commit: 'addOrReplaceSubscription', rawError: true })
  PURCHASE_SERVICE({ planId, userCount }: { planId: string, userCount: number }): Promise<Subscription> {
    let thisUrl = url;
    if (planId && userCount !== null) {
      thisUrl += '/' + encodeURIComponent(planId);
      thisUrl += '?userCount=' + userCount;
    }
    return axios.post(thisUrl).then((response) => {
      return Object.assign(new Subscription(), response.data);
    });
  }

  @Action({ commit: 'addOrReplaceSubscription', rawError: true })
  UPGRADE_SERVICE({ subscriptionId, planId, userCount }: { subscriptionId: number, planId: string, userCount: number }): Promise<Subscription> {
    let thisUrl = url;
    if (subscriptionId && planId && userCount !== null) {
      thisUrl += '/' + subscriptionId + '/products';
      thisUrl += '/' + encodeURIComponent(planId);
      thisUrl += '?userCount=' + userCount;
    }
    return axios.patch(thisUrl).then((response) => {
      return Object.assign(new Subscription(), response.data);
    });
  }
};
