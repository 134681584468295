
























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LoadingButton from "@/components/LoadingButton.vue";
import ReplicaConfig from "@/model/backup/ReplicaConfig";
import { Action, Getter } from "vuex-class";
import AnimatedInput from "@/components/AnimatedInput.vue";

@Component({
  components: { AnimatedInput, LoadingButton }
})
export default class ReplicationGridItem extends Vue {
  @Prop(Object) config!: ReplicaConfig;
  @Getter getCurrentlyRunningReplicas!: { id: string, progress: number }[];
  @Action DELETE_REPLICA_JOB!: (replicaJobId: string) => Promise<any>;
  @Action UPDATE_REPLICA_JOB!: (data: { id: string, host: string | null, cronExpression: string | null }) => Promise<ReplicaConfig>;

  labelServerAddress: string = this.$gettext('IP Address');

  ip: string | null = null;

  get progress(): number | null {
    let message: { id: string, progress: number } | undefined = this.getCurrentlyRunningReplicas.find(msg => msg.id === this.config.id);
    if (message) {
      return message.progress;
    } else {
      return null;
    }
  }

  get date(): string | null {
    return this.config.lastReplicationTime ? new Date(this.config.lastReplicationTime / 1000 * 1000).toLocaleString() : null;
  }

  saveReplicaConfig(): Promise<any> {
    return this.UPDATE_REPLICA_JOB({ id: this.config.id, host: this.ip, cronExpression: null }).then(() => {
      this.$snotify.success(this.$gettext('Failover configuration was saved.'));
    }).catch((error) => {
      this.$snotify.error(error.response.data.message,
        this.$pgettext("notification", "Could not save failover configuration."));
    }).finally(() => {
      this.$bvModal.hide('replicaConfigEditModal-' + this.config.id);
    });
  }

  deleteReplicaConfig(): Promise<any> {
    return this.DELETE_REPLICA_JOB(this.config.id).then(() => {
      this.$snotify.success(this.$gettext('Failover configuration was deleted.'));
    }).catch((error) => {
      this.$snotify.error(error.response.data.message,
        this.$pgettext("notification", "Could not delete failover configuration,"));
    }).finally(() => {
      this.$bvModal.hide('replicaConfigDeleteModal-' + this.config.id);
    });
  }

  mounted() {
    if (this.config) this.ip = this.config.host;
  }
}
