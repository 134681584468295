



























































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";
import BackupJob from "../model/backup/BackupJob";
import BackupUtil from "@/util/BackupUtil";
import USBStorageDrive from "@/model/backup/USBStorageDrive";
import Message from "@/model/Message";
import LoadingButton from "@/components/LoadingButton.vue";

@Component({
  components: { LoadingButton }
})
export default class BackupJobGridItem extends Vue {
  @Prop(Object) backupJob!: BackupJob;
  @Prop(Array) backupDevices!: USBStorageDrive[];

  deviceIsTarget: boolean | null = null;
  timetableBased: boolean | null = null;
  selectedDays: { name: string, number: number }[] | null = null;
  hour: number | string = 0;
  minute: number | string = 0;

  @Getter getProgressMessages!: Message[];

  @Action CREATE_BACKUP!: (deviceId: string) => Promise<any>;
  @Action GET_BACKUP_JOBS!: () => Promise< BackupJob[]>;
  @Action DELETE_BACKUP_JOB!: (backupJobId: string) => Promise<any>;
  @Action REPLACE_BACKUP_JOB!: (backupJob: BackupJob) => Promise<any>;

  get isCloud(): boolean {
    return BackupUtil.isCloud(this.backupJob);
  }

  get isCron(): boolean {
    return BackupUtil.isCron(this.backupJob);
  }

  get getDeviceName(): string {
    return this.$gettext(BackupUtil.getDeviceName(this.backupJob.fsUUID, this.backupDevices, this));
  }

  get getDaysExpression(): string {
    let days: { name: string, number: number }[] = BackupUtil.getDayObjects(this.backupJob, this);
    if (days && days.length === 7) {
      return this.$gettext('Every day');
    } else if (days && days.length > 0) {
      return this.$gettext('Every') + ' ' + days.map((day: { name: string, number: number }) => {
        return this.$gettext(day.name);
      }).join(', ');
    } else {
      return this.$gettext('Never') + ' ';
    }
  }

  get getTime(): string | null {
    return BackupUtil.getTime(this.backupJob);
  }

  get getAllDays(): { name: string, number: number }[] {
    return BackupUtil.dayMapping(this);
  }

  get getHours(): number[] {
    return [...Array(24).keys()].filter(num => num > 0);
  }

  get getMinutes(): number[] {
    return [...Array(59).keys()];
  }

  get hasProgress(): boolean {
    return this.getProgress !== false;
  }

  get backupIsBeingPrepared(): boolean {
    return this.getProgress === 0;
  }

  get getProgressText(): string | null {
    const progress = this.getProgress;
    if (progress === false) {
      return null;
    } else {
      return progress + "%";
    }
  }

  get getProgress(): number | false {
    let progressMessage = null;

    for (let message of this.getProgressMessages) {
      if (this.backupJob.fsUUID === message.indicatorId) {
        progressMessage = message;
      }
    }

    if (progressMessage && progressMessage.progress !== null) {
      return progressMessage.progress;
    } else {
      return false;
    }
  }

  saveBackupJob(): Promise<any> {

    let newBackupJob = Object.assign(new BackupJob(), this.backupJob);
    if (this.timetableBased) {
      newBackupJob.cron = true;
      newBackupJob.udev = false;
      newBackupJob.cronExpression = BackupUtil.createCronExpression(this.selectedDays || [], this.minute, this.hour) || '';
    } else {
      newBackupJob.cron = false;
      newBackupJob.udev = true;
      newBackupJob.cronExpression = null;
    }

    return this.REPLACE_BACKUP_JOB(newBackupJob).then(() => {
      this.$snotify.success(this.$gettext('Backup job was saved.'));
    }).catch((error) => {
      this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not save backup job"));
    }).finally(() => {
      this.$bvModal.hide('backupJobEditModal-' + this.backupJob.id);
    });
  }

  deleteBackupJob(): Promise<any> {
    return this.DELETE_BACKUP_JOB(this.backupJob.id).then(() => {
      this.$snotify.success(this.$gettext('Backup job was deleted.'));
    }).catch((error) => {
      this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not delete backup job"));
    }).finally(() => {
      this.$bvModal.hide('backupJobDeleteModal-' + this.backupJob.id);
    });
  }

  startBackup(): Promise<any> {
    return this.CREATE_BACKUP(this.backupJob.fsUUID).then(() => {
      this.$snotify.success(this.$gettext('Backup started.'));
    }).catch((error) => {
      this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not start backup job"));
    });
  }

  beforeMount(): void {
    this.timetableBased = this.backupJob.cron || false;
    this.selectedDays = BackupUtil.getDayObjects(this.backupJob, this);
    this.selectedDays = BackupUtil.getDayObjects(this.backupJob, this);
    let time: { minutes: string, hours: string } | null = BackupUtil.getTimeObject(this.backupJob);
    if (time) {
      this.hour = time.hours;
      this.minute = time.minutes;
    }
    this.deviceIsTarget = !(BackupUtil.isCloud(this.backupJob) || BackupUtil.isNetwork(this.backupJob));
  }
}
