



































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter, Mutation } from "vuex-class";
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import User from '../model/User';
import TwoFactorRequest from "@/model/TwoFactorRequest";
import Timers from "@/util/timers";

@Component({
  components: { AnimatedInput, LoadingButton }
})
export default class TwoFactorForm extends Vue {
  @Action LOGOUT_REQUEST!: () => Promise<any>;
  @Action CHECK_TOKEN!: (request: TwoFactorRequest) => Promise<any>;
  @Action GET_USER_CONFIG_VALUES!: () => Promise<any>;

  @Mutation setTwoFactorConfirmed!: () => any;
  @Mutation setMessageRefreshEnabled!: (enabled: boolean) => any;

  twoFactorCode: string = "";

  logout(): Promise<any> {
    return this.LOGOUT_REQUEST().then(() => {
      this.$router.push('/');
    });
  }

  confirm(): Promise<any> {
    return this.CHECK_TOKEN({ code: this.twoFactorCode, password: null, enabled: true }).then(() => {
      this.GET_USER_CONFIG_VALUES();
      this.setMessageRefreshEnabled(true);
      Timers.setInterval(Timers.MESSAGES, () => {
        this.$store.dispatch('GET_MESSAGES');
      }, 3000);
      this.setTwoFactorConfirmed();
    }).catch(error => {
      this.$snotify.error(error.response.data.message);
    });
  }
};
