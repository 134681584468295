const progressTypeToAppStatusMap : { [index:string] : string } = {
  'APP_INSTALLATION': 'INSTALLING',
  'APP_UPDATE': 'UPDATING',
  'APP_REMOVAL': 'UNINSTALLING',
  'APP_STARTING': 'STARTING',
  'APP_RUNNING': 'RUNNING',
  'DOCKER_BUILD': 'BUILDING_IMAGE'
};

export default progressTypeToAppStatusMap;
