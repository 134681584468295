import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import AdminMode from "@/model/AdminMode";

const url: string = '/api/v1/adminmode';

@Module
export default class AdminModeStore extends VuexModule {
  adminMode: AdminMode = Object.assign(new AdminMode(), { enabled: false });
  loading: boolean = false;

  get getAdminMode(): boolean {
    return !!this.adminMode.enabled;
  }

  @Mutation setAdminMode (adminMode: AdminMode) {
    if (!this.loading) {
      this.adminMode = adminMode;
    }
  }

  @Mutation setAdminModeIsLoading (loading: boolean) {
    this.loading = loading;
  }

  @Action({ commit: 'setAdminMode', rawError: true })
  GET_ADMIN_MODE () {
    this.context.commit("setAdminModeIsLoading", true);
    return axios.get(url).then((response) => {
      this.context.commit("setAdminModeIsLoading", false);
      return Object.assign(new AdminMode(), response.data);
    });
  }

  @Action({ commit: 'setAdminMode', rawError: true })
  SET_ADMIN_MODE (password: string) {
    this.context.commit('setAdminModeIsLoading', true);
    return axios.post(url, { password: password }).then((response) => {
      this.context.commit('setAdminModeIsLoading', false);
      return Object.assign(new AdminMode(), { enabled: true });
    });
  }

  @Action({ commit: 'setAdminMode', rawError: true })
  UNSET_ADMIN_MODE () {
    this.context.commit('setAdminModeIsLoading', true);
    let disabled: AdminMode = Object.assign(new AdminMode(), { enabled: false });
    this.context.commit('setAdminMode', disabled);
    return axios.delete(url).finally(() => {
      this.context.commit('setAdminModeIsLoading', false);
      return disabled;
    });
  }
};
