import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios, { AxiosResponse } from "axios/index";
import HostingConfig from "@/model/HostingConfig";
import App from "@/model/App";

const url: string = '/api/v1/hostings';

@Module
export default class HostingStore extends VuexModule {

  @Action({ rawError: true })
  ADD_HOSTING({ hostingConfig, file }: { hostingConfig: HostingConfig, file: File }): Promise<App> {
    const formData = new FormData();
    formData.append('file', file);
    const thisUrl = url + '/uploads';

    return axios({
      method: 'post',
      url: thisUrl,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response) => {
      let encodedTicket: string = encodeURIComponent(response.data.ticket);
      return axios.post(url + '/' + encodedTicket, hostingConfig).then((response: AxiosResponse) => {
        return Object.assign(new App(), response.data);
      });
    });
  }

  @Action({ rawError: true })
  GET_BUILD_STATE(instanceId: string): Promise<any> {
    let encodedId: string = encodeURIComponent(instanceId);
    return axios.get(url + '/' + encodedId + '/buildState').then(response => response.data);
  }
}
