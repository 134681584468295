












import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class ChippedInput extends Vue {
  @Prop(String) label!: string;
  @Prop(String) placeholder!: string;
  @Prop(Array) value!: string[];
  @Prop(Boolean) list!: boolean;
  @Prop(String) autocomplete!: string;
  @Prop(String) error!: string;
  @Prop(String) type!: string;
  @Prop(Boolean) disabled!: boolean;
  @Prop(String) name!: string;
  @Prop(Boolean) autofocus!: boolean;

  focused: boolean = false;
  inputValue: string = '';
  chips: string[] = this.value || [] as string[];

  $refs!: {
    myinput: HTMLInputElement;
  };

  inputToChips() {
    let chips: string[] = this.inputValue ? this.inputValue.split(/[ ,]+/) : [];
    for (const chip of chips) {
      if ((!this.list && this.chips.indexOf(chip) === -1) || this.list) {
        this.chips.push(chip);
      }
    }
    this.inputValue = '';
    this.$emit('input', this.chips);
  }

  backspaceDelete(event: any) {
    if (event.which === 8 && this.inputValue === '') {
      this.chips.splice(this.chips.length - 1);
    }
    this.$emit('input', this.chips);
  }

  deleteChip(index: number) {
    this.chips.splice(index, 1);
    this.$emit('input', this.chips);
  }

  get animationClass (): string {
    if (this.focused || this.chips.length > 0 || (this.inputValue && this.inputValue !== '')) {
      return 'ai-filled' + this.errorClass;
    } else {
      return '' + this.errorClass;
    }
  }

  get errorClass(): string {
    if (this.error) {
      if (this.focused) {
        return ' border-danger';
      } else {
        return ' border-danger danger';
      }
    } else {
      return '';
    }
  }

  get placeHolderIfEmpty() {
    return this.chips.length === 0 ? this.placeholder : '';
  }

  handleFocus () {
    this.focused = true;
    this.$emit('focus');
  }

  handleBlur () {
    this.focused = false;
    this.$emit('blur');
    this.inputToChips();
  }

  checkAutofill(e : AnimationEvent) {
    if (e.animationName === "onAutoFillStart") this.$emit("autofill", true);
    else if (e.animationName === "onAutoFillCancel") this.$emit("autofill", false);
  }

  @Watch('value')
  onValueChanged (val: string[], oldVal: string[]) {
    this.chips = val;
  }

  //Do things when we're in the dom:
  mounted () {
    if (this.autofocus) {
      this.$refs.myinput.focus();
    }
  }
};
