import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import WallpaperEntity from "@/model/WallpaperEntity";

const url: string = '/api/v1/wallpapers';

@Module
export default class wallpaperStore extends VuexModule {
  allWallpapers: WallpaperEntity[] = [];
  loading: boolean = false;

  get getAllWallpapers () : WallpaperEntity[] {
    return this.allWallpapers;
  }

  @Mutation
  setWallpapersAreLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setAllWallpapers (wallpapers: WallpaperEntity[]) {
    this.allWallpapers = wallpapers;
  }

  @Mutation
  addUserWallpaper(wallpaper: WallpaperEntity): void {
    this.allWallpapers.push(wallpaper);
  }

  @Mutation
  deleteWallpaper(id: string) {
    let toDelete = this.allWallpapers.find((value: WallpaperEntity) => {
      return value.name === id;
    });

    if (toDelete !== undefined) {
      let index = this.allWallpapers.indexOf(toDelete);
      if (index > -1) {
        this.allWallpapers.splice(index, 1);
      }
    }
  }

  @Action({ commit: 'setAllWallpapers', rawError: true })
  GET_ALL_WALLPAPERS() {
    this.context.commit("setWallpapersAreLoading", true);
    return axios.get(url + '/available').then((response) => {
      this.context.commit("setWallpapersAreLoading", false);
      return response.data ? response.data.map((wallpaper: WallpaperEntity) => Object.assign(new WallpaperEntity(), wallpaper)) : [];
    });
  }

  @Action({ commit: 'addUserWallpaper', rawError: true })
  UPLOAD_USER_WALLPAPER(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(url + '/user', formData, { headers: { "Content-Type": "multipart/form-data" } }).then((response) => {
      let wallpaper: WallpaperEntity = new WallpaperEntity();
      wallpaper.name = response.data;
      wallpaper.stock = false;
      return wallpaper;
    });
  }

  @Action({ rawError: true })
  SET_USER_WALLPAPER(id: string) {
    return axios.post(url + '/current', id, { headers: { "Content-Type": "text/plain" } });
  }

  @Action({ rawError: true })
  UNSET_USER_WALLPAPER() {
    return axios.delete(url + '/current').then((response) => {
      this.context.commit('setCurrentWallpaper', null);
    });
  }

  @Action({ commit: 'deleteWallpaper', rawError: true })
  DELETE_WALLPAPER(id: string) {
    const encodedId = encodeURIComponent(id);
    return axios.delete(url + '/user/' + encodedId).then((response) => {
      return id;
    });
  }
};
