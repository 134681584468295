




































import { Vue, Component, Prop } from 'vue-property-decorator';
import ManualGridItem from "../components/ManualGridItem.vue";
import ManualEntry from '../model/ManualEntry';
import axios from "axios/index";
import { Action, Getter } from "vuex-class";
import SystemInformation from '../model/SystemInformation';
import App from "@/model/App";

@Component({
  components: { ManualGridItem }
})
export default class ManualViewer extends Vue {
  @Getter loggedInUserId!: string | null;
  @Getter getStatus!: any | null;
  @Getter getSystem!: SystemInformation | null;
  @Action GET_SYSTEM_INFORMATION!: () => Promise<any>;

  marked = require('marked');
  id!: string;
  markdown!: string;

  manualEntry!: ManualEntry;

  isLoading: boolean = true;

  get compiledMarkdown() {
    if (this.markdown) {
      return this.markdown;
    }
    return "";
  }

  goToOverview(): void {
    this.$router.push('/manual');
  }

  injectVariables(md: string): string {
    if (!md) return '';

    let myDomain = this.getStatus.domain;
    let username = this.loggedInUserId;

    if (!username) {
      username = "benutzername@" + myDomain;
    }

    let newMd: string = md.replace(/<MYDOMAIN>/g, myDomain);
    newMd = newMd.replace(/<USERNAME>/g, username);

    if (this.getSystem !== null && this.getSystem.localIp !== null) {
      newMd = newMd.replace(/<MYIP>/g, this.getSystem.localIp);
    }

    return newMd;
  }

  created() {
    this.id = encodeURIComponent(this.$router.currentRoute.params.id);
    let resourcePath: string = '/manpages/de/' + this.id + '.md';
    this.GET_SYSTEM_INFORMATION();
    import('../assets/manpages/de/index.json').then((indexJson: {default:{[key: string]: any}}) => {
      let manualsList: ManualEntry[] = [];
      for (let manual in indexJson.default) {
        manualsList.push(indexJson.default[manual]);
      }

      for (let entry of manualsList) {
        if (entry.ref === this.id) {
          this.manualEntry = entry;
        }
      }
    }).then(() => {
      return axios.get(resourcePath);
    }).then((response) => {
      let rawMd: string = response.data;

      this.markdown = this.marked(this.injectVariables(rawMd));
      if (this.markdown) {
        this.isLoading = false;
      }
    });
  }
};
