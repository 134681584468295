import ConfigValue from "@/model/ConfigValue";
import LDAPConfig from "@/model/LDAPConfig";
import AttributeMapping from "@/model/AttributeMapping";

export default class UserUtils {

  static externalAuthenticationEnabled(systemConfigValues: ConfigValue[]): boolean {
    let enabled: ConfigValue | undefined = systemConfigValues.find((value: ConfigValue) => {
      return value.id === 'enabled' && value.context === 'external.authentication';
    });
    return Boolean(enabled && enabled.value === true);
  }

  static groupIsMappedToExternalGroup(groupDn: string, systemConfigValues: ConfigValue[]): boolean {
    if (this.externalAuthenticationEnabled(systemConfigValues)) {
      let directoryConfig: ConfigValue | undefined = systemConfigValues.find((value: ConfigValue) => {
        return value.id === 'directories' && value.context === 'external.authentication';
      });
      if (directoryConfig && Array.isArray(directoryConfig.value) && Array(directoryConfig.value).length > 0) {
        let directories: LDAPConfig[] = directoryConfig.value.map((value: LDAPConfig) => Object.assign(new LDAPConfig(), value));
        let config = directories.find(ldapConfig => Boolean(ldapConfig.enabled));
        if (config) {
          return Boolean(config.groupMapping && config.groupMapping.find((mapping: AttributeMapping) => {
            return mapping.internal === groupDn;
          }));
        } else return false;
      } else return false;
    } else return false;
  }

  static userIsExternal(userDn: string, systemConfigValues: ConfigValue[]): boolean {
    if (this.externalAuthenticationEnabled(systemConfigValues)) {
      let directoryConfig: ConfigValue | undefined = systemConfigValues.find((value: ConfigValue) => {
        return value.id === 'directories' && value.context === 'external.authentication';
      });
      if (directoryConfig && Array.isArray(directoryConfig.value) && Array(directoryConfig.value).length > 0) {
        let directories: LDAPConfig[] = directoryConfig.value.map((value: LDAPConfig) => Object.assign(new LDAPConfig(), value));
        let config = directories.find(ldapConfig => Boolean(ldapConfig.enabled));
        if (config) {
          let userList: ConfigValue | undefined = systemConfigValues.find((value: ConfigValue) => {
            return value.id === 'users' && value.context === 'external.authentication';
          });
          return Boolean(userList && Array.isArray(userList.value) && userList.value.indexOf(userDn) > -1);
        } else return false;
      } else return false;
    } else return false;
  }

  static syncExternalMailToExternalMail(systemConfigValues: ConfigValue[]): boolean {
    if (this.externalAuthenticationEnabled(systemConfigValues)) {
      let directoryConfig: ConfigValue | undefined = systemConfigValues.find((value: ConfigValue) => {
        return value.id === 'directories' && value.context === 'external.authentication';
      });
      if (directoryConfig && Array.isArray(directoryConfig.value) && Array(directoryConfig.value).length > 0) {
        let directories: LDAPConfig[] = directoryConfig.value.map((value: LDAPConfig) => Object.assign(new LDAPConfig(), value));
        let config = directories.find(ldapConfig => Boolean(ldapConfig.enabled));
        if (config) {
          return !config.syncExternalMailToInternalMail;
        } else return false;
      } else return false;
    } else return false;
  }
}
