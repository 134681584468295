import LibraryProgress from '@/model/LibraryProgress';

export default class DataImportJob {
  device!: string | null;
  library!: string | null;
  files!: string[] | null;
  progress!: LibraryProgress[] | null;

  constructor() {
    this.device = null;
    this.library = null;
    this.files = null;
    this.progress = null;
  }
}
