import { ValidationObserver } from "vee-validate";

function wait(time: number) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export default async function (validationObserver: InstanceType<typeof ValidationObserver>) {
  //This waits, so the internal debounce of the validation observer can trigger (2* 16msec debounce + 1msec to be safe)
  await wait(33);
  const errors = validationObserver.errors;
  const plainErrors: string[] = [];

  for (const prop in errors) {
    if (errors.hasOwnProperty(prop)) {
      const msgs: string[] = errors[prop];
      if (msgs.length > 0) {
        plainErrors.push(msgs[0]);
      }
    }
  }
  return plainErrors.join(", ");
}
