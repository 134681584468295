import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import User from "@/model/User";
import PasswordChangeRequest from "@/model/PasswordChangeRequest";
import PasswordResetRequest from "@/model/PasswordResetRequest";
import TwoFactorRequest from "@/model/TwoFactorRequest";

const url: string = '/api/v1/users';

@Module
export default class UserStore extends VuexModule {
  users: User[] = [];
  loading: boolean = false;

  get getUsers(): User[] {
    return this.users;
  }

  get onlyOneAdminLeft(): boolean {
    return this.getUsers.filter(x => x.posixGroupName === "admin").length < 2;
  }

  @Mutation
  setUsers(users: User[]) {
    this.users = users;
  }

  @Mutation
  setUsersAreLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  addOrReplaceUser(newUser: User) {
    let oldUser = this.users.find((value: User) => {
      return value.uid === newUser.uid;
    });

    if (oldUser !== undefined) {
      this.users = this.users.map((value: User) => {
        if (value.uid === newUser.uid) {
          return newUser;
        } else {
          return value;
        }
      });
    } else {
      this.users.push(newUser);
    }
  }

  @Mutation
  deleteUser(id: string) {
    let toDelete = this.users.find((value: User) => {
      return value.uid === id;
    });

    if (toDelete !== undefined) {
      let index = this.users.indexOf(toDelete);
      if (index > -1) {
        this.users.splice(index, 1);
      }
    }
  }

  @Action({ commit: 'setUsers', rawError: true })
  GET_USERS() {
    this.context.commit("setUsersAreLoading", true);
    return axios.get(url).then((response) => {
      this.context.commit("setUsersAreLoading", false);
      this.context.dispatch('PROCESS_USER_CHANGES_FOR_LOGGED_IN_USER', response.data);
      return response.data ? response.data.map((user: User) => Object.assign(new User(), user)) : [];
    });
  }

  @Action({ commit: 'addOrReplaceUser', rawError: true })
  GET_USER(userId: string) {
    const encodedId = encodeURIComponent(userId);
    return axios.get(url + '/' + encodedId).then((response) => {
      this.context.dispatch('PROCESS_USER_CHANGES_FOR_LOGGED_IN_USER', response.data);
      return Object.assign(new User(), response.data);
    });
  }

  @Action({ rawError: true })
  GET_SECRETARY(userId: string) {
    const encodedId = encodeURIComponent(userId);
    return axios.get(url + '/' + encodedId + '/secretary').then((response) => {
      return Object.assign(new User(), response.data);
    });
  }

  @Action({ rawError: true })
  GET_MANAGER(userId: string) {
    const encodedId = encodeURIComponent(userId);
    return axios.get(url + '/' + encodedId + '/manager').then((response) => {
      return Object.assign(new User(), response.data);
    });
  }

  @Action({ commit: 'addOrReplaceUser', rawError: true })
  CREATE_USER(user: User) {
    return axios.post(url, user).then((response) => {
      return Object.assign(new User(), response.data);
    });
  }

  @Action({ commit: 'addOrReplaceUser', rawError: true })
  REPLACE_USER({ userId, user }: { userId: string, user: User }) {
    const encodedId = encodeURIComponent(userId);
    return axios.put(url + '/' + encodedId, user).then((response) => {
      this.context.dispatch('PROCESS_USER_CHANGES_FOR_LOGGED_IN_USER', response.data);
      return Object.assign(new User(), response.data);
    });
  }

  @Action({ commit: 'addOrReplaceUser', rawError: true })
  UPDATE_USER({ userId, user }: { userId: string, user: User }) {
    const encodedId = encodeURIComponent(userId);
    return axios.patch(url + '/' + encodedId, user).then((response) => {
      this.context.dispatch('PROCESS_USER_CHANGES_FOR_LOGGED_IN_USER', response.data);
      return Object.assign(new User(), response.data);
    });
  }

  @Action({ commit: 'deleteUser', rawError: true })
  DELETE_USER(userId: string) {
    const encodedId = encodeURIComponent(userId);
    return axios.delete(url + '/' + encodedId).then((response) => {
      return userId;
    });
  }

  @Action({ rawError: true })
  REMOVE_ATTRIBUTE_FROM_USER({ userId, attribute }: { userId: string, attribute: string }) {
    const encodedId = encodeURIComponent(userId);
    const encodedAttribute = encodeURIComponent(attribute);
    return axios.delete(url + '/' + encodedId + '/' + encodedAttribute).then((response) => {
      return this.context.dispatch('GET_USER', userId);
    });
  }

  @Action({ rawError: true })
  UPDATE_USER_PASSWORD({ userId, request }: { userId: string, request: PasswordChangeRequest }) {
    const encodedId = encodeURIComponent(userId);
    return axios.post(url + '/' + encodedId + '/password_change_request', request);
  }

  @Action({ rawError: true })
  REQUEST_PASSWORD_RESET_MAIL(userId: string) {
    const encodedId = encodeURIComponent(userId);
    return axios.post(url + '/' + encodedId + '/password_mail_request');
  }

  @Action({ rawError: true })
  RESET_USER_PASSWORD({ userId, request }: { userId: string, request: PasswordResetRequest }) {
    const encodedId = encodeURIComponent(userId);
    return axios.post(url + '/' + encodedId + '/password_reset_request', request);
  }

  @Action({ rawError: true })
  SET_PROFILE_PICTURE({ userId, file }: { userId: string, file: File }) {
    const encodedUserId = encodeURIComponent(userId);
    const formData = new FormData();
    formData.append('file', file);
    const thisUrl = url + '/' + encodedUserId + '/picture';

    return axios({
      method: 'post',
      url: thisUrl,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response) => {
      return this.context.dispatch('GET_USER', userId);
    });
  }

  @Action({ rawError: true })
  CHANGE_TWO_FACTOR_CONFIG({ userId, config }: { userId: string, config: TwoFactorRequest }) {
    const encodedId = encodeURIComponent(userId);
    return axios.post(url + '/' + encodedId + '/twofactorconfig', config).finally(() => {
      if (!config.enabled) {
        return this.context.dispatch('GET_USER', userId);
      }
    });
  }

  @Action({ rawError: true })
  PROCESS_USER_CHANGES_FOR_LOGGED_IN_USER(usersOrUser: User[] | User): void {
    if (Array.isArray(usersOrUser)) {
      usersOrUser.forEach((value) => {
        if (value.uid === this.context.getters.loggedInUsername) {
          this.context.commit('receiveSessionUser', { ...value });
        }
      });
    } else {
      if (usersOrUser.uid === this.context.getters.loggedInUsername) {
        this.context.commit('receiveSessionUser', { ...usersOrUser });
      }
    }
  }
}
