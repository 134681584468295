

























import { Vue, Component, Prop } from 'vue-property-decorator';
import LoadingImage from "../components/LoadingImage.vue";
import AnimatedInput from "../components/AnimatedInput.vue";
import ManualEntry from "../model/ManualEntry";

@Component({
  components: { AnimatedInput, LoadingImage }
})
export default class ManualGridItem extends Vue {
  @Prop(Object) entry!: ManualEntry;

  showManual(): void {
    this.$router.push('/manual/howto/' + this.entry.ref);
  }
};
