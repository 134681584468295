











































import { Vue, Component } from "vue-property-decorator";
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import { Action, Getter, Mutation } from "vuex-class";
import { ValidationObserver } from "vee-validate";
import validationErrors from "@/util/validationErrors";
import Timers from "@/util/timers";

@Component({
  components: {
    AnimatedInput,
    LoadingButton
  }
})
export default class Login extends Vue {
  username: string = "";
  password: string = "";
  loading: boolean = false;
  autofillOverwrite: boolean = false; //Overrides validation if chrome autofill does input something directly after page load. See: https://bugs.chromium.org/p/chromium/issues/detail?id=669724

  $refs!: {
    loginform: InstanceType<typeof ValidationObserver>;
  };

  @Getter isLoggedIn!: boolean;
  @Getter getTargetRoute!: string | null;
  @Getter needsTwoFactorConfirm!: boolean;

  @Mutation setTargetRoute!: (route: string | null) => any;
  @Mutation setMessageRefreshEnabled!: (enabled: boolean) => any;

  @Action GET_USER_CONFIG_VALUES!: () => Promise<any>;

  async validateThenLogin () {
    let results;
    results = await this.$refs.loginform.validate();
    if (results) {
      this.loading = true;
      return this.login();
    } else {
      this.$snotify.error(
        await validationErrors(this.$refs.loginform),
        this.$pgettext("notification", "Login failed.")
      );
    }
  }

  login () {
    return this.$store.dispatch("LOGIN_REQUEST", { username: this.username, password: this.password }).then(() => {
        this.loading = false;
        if (!this.needsTwoFactorConfirm) {
          this.GET_USER_CONFIG_VALUES();
          this.setMessageRefreshEnabled(true);
          Timers.setInterval(Timers.MESSAGES, () => {
            this.$store.dispatch('GET_MESSAGES');
          }, 3000);
        }
        let targetRoute = this.getTargetRoute;
        if (targetRoute) {
          this.setTargetRoute(null);
        }
        return {};
      },
      error => {
        this.loading = false;
        const code = error.response.status;
        switch (code) {
          case 400:
          case 422:
            this.$snotify.error(this.$pgettext("notification", "Username or Password wrong."));
            break;
          default:
            this.$snotify.error(this.$pgettext("notification", "Login failed."));
        }
      }
    );
  }

  handleAutofill(args: boolean) {
    this.autofillOverwrite = args;
  }
}
