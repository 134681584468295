import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import DataImportJob from "@/model/DataImportJob";
import ImportableData from "@/model/ImportableData";
import USBStorageDrive from "@/model/backup/USBStorageDrive";
import LibraryProgress from "@/model/LibraryProgress";

const url: string = '/api/v1/datamigration';

@Module
export default class DataImportJobStore extends VuexModule {
  loading: boolean = false;
  syncProgress: LibraryProgress[] = [];

  get getSyncProgress() {
    return this.syncProgress;
  }

  @Mutation
  setSyncProgress (progress: LibraryProgress[]) {
    this.syncProgress = progress;
  }

  @Action({ rawError: true })
  GET_USB_DRIVES (): Promise<USBStorageDrive[]> {
    return axios.get(url + '/devices').then((response) => {
      return response.data ? response.data.map((data: USBStorageDrive) => Object.assign(new USBStorageDrive(), data)) : [];
    });
  }

  @Action({ rawError: true })
  GET_IMPORTABLE_DATA (deviceId: string): Promise<ImportableData[]> {
    const encodedId = encodeURIComponent(deviceId);
    return axios.get(url + '/devices/proto?id=' + encodedId).then((response) => {
      return response.data ? response.data.map((data: ImportableData) => Object.assign(new ImportableData(), data)) : [];
    });
  }

  @Action({ commit: 'setSyncProgress', rawError: true })
  CREATE_IMPORT_JOB (job: DataImportJob): Promise<LibraryProgress[]> {
    return axios.post(url + '/jobs', job).then((response) => {
      return response.data ? response.data.map((data: LibraryProgress) => Object.assign(new LibraryProgress(), data)) : [];
    });
  }

  @Action({ commit: 'setSyncProgress', rawError: true })
  GET_IMPORT_JOBS (): Promise<LibraryProgress[]> {
    return axios.get(url + '/jobs').then((response) => {
      return response.data ? response.data.map((data: LibraryProgress) => Object.assign(new LibraryProgress(), data)) : [];
    });
  }
};
