export default class HostingConfig {
  domain!: string;
  title!: string | null;
  description!: string | null;
  protectBySSO!: boolean | null;
  interpreter!: string | null;
  addons!: string[] | null;

  constructor() {
    this.title = null;
    this.description = null;
    this.protectBySSO = null;
    this.interpreter = null;
    this.addons = null;
  }
}
