


































import { Vue, Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import AnimatedInput from "../components/AnimatedInput.vue";
import DomainGridItem from "../components/DomainGridItem.vue";
import LoadingButton from "../components/LoadingButton.vue";
import App from "@/model/App";
import MailAlias from "@/model/MailAlias";

@Component({
  components: {
    AnimatedInput, LoadingButton, DomainGridItem
  }
})
export default class Domains extends Vue {
  @Getter getStatus!: any | null;
  @Getter getApps!: App[];
  @Action GET_INSTALLED_APPS!: () => Promise<any>;
  @Action SET_DEFAULT_DOMAIN_TO_UNIKI!: () => Promise<any>;
  @Action SET_DEFAULT_DOMAIN_TO_APP!: (instanceId: string) => Promise<any>;

  defaultDomainChecked: boolean = false;
  app: App | null = null;

  get showSaveButton(): boolean {
    return (this.isDefaultSet && !this.defaultDomainChecked) ||
      (!this.isDefaultSet && this.defaultDomainChecked && !!this.app);
  }

  saveSettings(): Promise<any> {
    if (this.defaultDomainChecked && this.app) {
      return this.changeDefaultApp();
    } else {
      return this.deleteDefaultApp();
    }
  }

  changeDefaultApp(): Promise<any> {
    if (this.app) {
      return this.SET_DEFAULT_DOMAIN_TO_APP(this.app.instanceId as string).then(() => {
        this.$snotify.success(this.$pgettext("notification", "New default app set."));
        if (!window.location.href.startsWith("https://cloud." + this.getStatus.domain)) {
          window.location.href = "https://cloud." + this.getStatus.domain;
        }
      }).catch((error) => {
        this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not set new default app. An error occurred."));
      }).finally(() => {
        this.GET_INSTALLED_APPS();
      });
    } else {
      return Promise.resolve();
    }
  }

  deleteDefaultApp(): Promise<any> {
    return this.SET_DEFAULT_DOMAIN_TO_UNIKI().then(() => {
      this.defaultDomainChecked = false;
      this.app = null;
      this.$snotify.success(this.$pgettext("notification", "Restored default"));
    }).catch((error) => {
      this.$snotify.error(error.response.data.message,
        this.$pgettext("notification", "Could not restore default. An error occurred."));
    }).finally(() => {
      this.GET_INSTALLED_APPS();
    });
  }

  get isDefaultSet(): boolean {
    for (let app of this.getApps) {
      if (app.alternativeDomains && app.alternativeDomains.includes(this.getStatus.domain)) {
        return true;
      }
    }
    return false;
  }

  hasDefaultApp(): void {
    for (let app of this.getApps) {
      if (app.alternativeDomains && app.alternativeDomains.includes(this.getStatus.domain)) {
        this.app = app;
        this.defaultDomainChecked = true;
      }
    }
  }

  created() {
    this.GET_INSTALLED_APPS().then(() => {
      this.hasDefaultApp();
    });

    this.hasDefaultApp();
  }
}
