import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import axios from "axios/index";
import InvoiceContact from "@/model/InvoiceContact";

const url: string = '/api/v1/invoicecontacts';

@Module
export default class InvoiceContactStore extends VuexModule {
  invoiceContact: InvoiceContact | null = null;

  get getInvoiceContact(): InvoiceContact | null {
    return this.invoiceContact;
  }

  @Mutation
  setInvoiceContact(invoiceContact: InvoiceContact) {
    this.invoiceContact = invoiceContact;
  }

  @Action({ commit: 'setInvoiceContact', rawError: true })
  GET_PAYMENT_OPTIONS() {
    return axios.get(url).then((response) => {
      return Object.assign(new InvoiceContact(), response.data);
    });
  }
}
