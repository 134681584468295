








































import { Vue, Component, Ref } from 'vue-property-decorator';
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import HostedAppGridItem from "../components/HostedAppGridItem.vue";
import { Action, Getter } from "vuex-class";
import App from '../model/App';
import AddNewHostingWizard from "@/components/AddNewHostingWizard.vue";
import { BModal } from "bootstrap-vue";

@Component({
  components: {
    AddNewHostingWizard, AnimatedInput, LoadingButton, HostedAppGridItem
  }
})
export default class Hosting extends Vue {
  @Getter getStatus!: any | null;
  @Getter getApps!: App[];
  @Action GET_INSTALLED_APPS!: () => Promise<any>;

  @Ref('addHostingModal') newHostingModal!: BModal;

  acceptedContainerTypes = ['.zip'];

  get hasHosting(): boolean {
    return this.getCustomApps.length > 0;
  }

  get getCustomApps(): App[] {
    let customApps: App[] = [];
    for (let app of this.getApps) {
      if (app.isCustom) {
        customApps.push(app);
      }
    }
    return customApps;
  }

  showNewHostingModal() {
    this.newHostingModal.show();
  }

  beforeMount() {
    this.GET_INSTALLED_APPS();
  }
};
