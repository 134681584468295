import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import BackupJob from "@/model/backup/BackupJob";

const url: string = '/api/v1/backupjobs';

@Module
export default class BackupJobStore extends VuexModule {
  backupJobs: BackupJob[] = [];
  loading: boolean = false;

  get getBackupJobs () : BackupJob[] {
    return this.backupJobs;
  }

  @Mutation setBackupJobsAreLoading (loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setBackupJobs (backupJobs: BackupJob[]) {
    this.backupJobs = backupJobs;
  }

  @Mutation
  addBackupJob (newJob: BackupJob) {
    this.backupJobs.push(newJob);
  }

  @Mutation
  replaceBackupJob (newJob: BackupJob) {
    this.backupJobs = this.backupJobs.map((job: BackupJob) => {
      if (job.id === newJob.id) {
        return newJob;
      } else {
        return job;
      }
    });
  }

  @Mutation
  deleteBackupJob (deletedJobId: string) {
    let toDelete = this.backupJobs.find((job: BackupJob) => {
      return job.id === deletedJobId;
    });

    if (toDelete !== undefined) {
      let index = this.backupJobs.indexOf(toDelete);
      if (index > -1) {
        this.backupJobs.splice(index, 1);
      }
    }
  }

  @Action({ commit: 'setBackupJobs', rawError: true })
  GET_BACKUP_JOBS() {
    this.context.commit("setBackupJobsAreLoading", true);
    return axios.get(url).then((response) => {
      return response.data ? response.data.map((backupJob: BackupJob) => Object.assign(new BackupJob(), backupJob)) : [];
    }).finally(() => {
      this.context.commit("setBackupJobsAreLoading", false);
    });
  }

  @Action({ commit: 'addBackupJob', rawError: true })
  CREATE_BACKUP_JOB(data: { device: string, format: boolean | null, cronExpression: string | null, udev: boolean | null }) {
    let formData = new FormData();
    formData.set('device', data.device);
    if (data.format !== null) formData.set('format', data.format.toString());
    if (data.cronExpression !== null) formData.set('cronExpression', data.cronExpression);
    if (data.udev !== null) formData.set('udev', data.udev.toString());
    return axios.request({
      method: 'post',
      url: url,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response) => {
      return Object.assign(new BackupJob(), response.data);
    }).finally(() => {
      this.context.dispatch('GET_BACKUP_JOBS');
    });
  }

  @Action({ commit: 'replaceBackupJob', rawError: true })
  REPLACE_BACKUP_JOB(backupJob: BackupJob) {
    const encodedId = encodeURIComponent(backupJob.id);
    return axios.put(url + '/' + encodedId, backupJob).then((response) => {
      //Add to state immediately and dispatch update from backend to ensure integrity
      return Object.assign(new BackupJob(), response.data);
    }).finally(() => {
      this.context.dispatch('GET_BACKUP_JOBS');
    });
  }

  @Action({ commit: 'replaceBackupJob', rawError: true })
  UPDATE_BACKUP_JOB(backupJob: BackupJob) {
    const encodedId = encodeURIComponent(backupJob.id);
    return axios.patch(url + '/' + encodedId, backupJob).then((response) => {
      //Add to state immediately and dispatch update from backend to ensure integrity
      return Object.assign(new BackupJob(), response.data);
    }).finally(() => {
      this.context.dispatch('GET_BACKUP_JOBS');
    });
  }

  @Action({ commit: 'deleteBackupJob', rawError: true })
  DELETE_BACKUP_JOB(backupJobId: string) {
    const encodedId = encodeURIComponent(backupJobId);
    return axios.delete(url + '/' + encodedId).then((response) => {
      //Add to state immediately and dispatch update from backend to ensure integrity
      return backupJobId;
    }).finally(() => {
      this.context.dispatch('GET_BACKUP_JOBS');
    });
  }
}
