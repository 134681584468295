import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import Password from "@/model/Password";

const url: string = '/api/v1/passwords';

@Module
export default class PasswordStore extends VuexModule {
  passwords: Password[] = [];
  loading: boolean = false;

  get getPasswords() : Password[] {
    return this.passwords;
  }

  @Mutation
  setPasswords(passwords: Password[]) {
    this.passwords = passwords;
  }

  @Mutation setPasswordsAreLoading (loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  addOrReplacePassword(newPassword: Password) {
    let oldRule = this.passwords.find((value: Password) => {
      return value.id === newPassword.id;
    });

    if (oldRule !== undefined) {
      this.passwords = this.passwords.map((value: Password) => {
        if (value.id === newPassword.id) {
          return newPassword;
        } else {
          return value;
        }
      });
    } else {
      this.passwords.push(newPassword);
    }
  }

  @Action({ commit: 'setPasswords', rawError: true })
  GET_PASSWORDS() {
    this.context.commit("setPasswordsAreLoading", true);
    return axios.get(url).then((response) => {
      this.context.commit("setPasswordsAreLoading", false);
      return response.data ? response.data.map((password: Password) => Object.assign(new Password(), password)) : [];
    });
  }

  @Action({ commit: 'addOrReplacePassword', rawError: true })
  GET_PASSWORD(id: string) {
    const encodedId = encodeURIComponent(id);
    return axios.get(url + '/' + encodedId).then((response) => {
      return Object.assign(new Password(), response.data);
    });
  }
}
