export default class PasswordChangeRequest {
  oldPassword!: string | null;
  newPassword!: string | null;
  newPasswordRepeat!: string | null;

  constructor() {
    this.oldPassword = null;
    this.newPassword = null;
    this.newPasswordRepeat = null;
  }
}
