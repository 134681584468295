

















































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import LoadingImage from "../components/LoadingImage.vue";
import DropBox from "../components/DropBox.vue";
import { Action, Getter } from "vuex-class";
import User from "../model/User";
import PasswordChangeRequest from "@/model/PasswordChangeRequest";
import TwoFactorRequest from "@/model/TwoFactorRequest";
import { ValidationObserver } from "vee-validate";
import validationErrors from "@/util/validationErrors";
import UserConfigValue from "@/model/UserConfigValue";

@Component({
  components: {
    AnimatedInput, LoadingButton, LoadingImage, DropBox
  }
})
export default class Account extends Vue {
  @Getter loggedInUserId!: string | null;
  @Getter loggedInIsAdmin!: boolean;
  @Getter getUser!: User | null;
  @Getter getUserConfigValues!: UserConfigValue[];
  @Action UPDATE_USER_PASSWORD!: ({ userId, request }: { userId: string, request: PasswordChangeRequest }) => Promise<any>;
  @Action CHANGE_TWO_FACTOR_CONFIG!: ({ userId, config }: { userId: string, config: TwoFactorRequest }) => Promise<any>;
  @Action GET_USER!: (userId: string) => Promise<any>;
  @Action REPLACE_USER!: ({ userId, user }: { userId: string, user: User }) => Promise<any>;
  @Action SET_PROFILE_PICTURE!: ({ userId, file }: { userId: string, file: File }) => Promise<any>;
  @Action GET_IMAGE!: (src: string) => Promise<any>;
  @Action GET_USER_CONFIG_VALUES!: () => Promise<any>;
  @Action LOGOUT_REQUEST!: any;

  acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  loading: boolean = false;
  image: File | any;
  origUser: User | any;
  user: User = new User();
  hasChanged: boolean = false;

  pwStrongText: string = this.$pgettext("password-strength-info", "Password strength: Strong");
  pwOkText: string = this.$pgettext("password-strength-info", "Password strength: OK");
  pwBadText: string = this.$pgettext("password-strength-info", "Password strength: Bad");

  strongRegex: RegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})");
  //at least 8 chars, lower/upper case letters und numbers
  mediumRegex: RegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

  labelUid: string = this.$pgettext("label", "Username");
  labelFirstName: string = this.$pgettext("label", "First name");
  labelLastName: string = this.$pgettext("label", "Last name");
  labelMobile: string = this.$pgettext("label", "Mobile number");
  labelPhone: string = this.$pgettext("label", "Phone number");
  labelExternMail: string = this.$pgettext("label", "Email Address for password reset");
  labelInternMail: string = this.$pgettext("label", "Email Address of your mailbox");

  label2FA: string = this.$pgettext("label", "Generated 2FA code");
  labelPw: string = this.$pgettext("label", "Your password");
  labelCurPw: string = this.$pgettext("label", "Your current password");
  labelNewPw: string = this.$pgettext("label", "Your new password");
  labelNewPwRepeat: string = this.$pgettext("label", "Repeat your new password");
  pwCurrent: string = "";
  pwNew: string = "";
  pwNewRepeat: string = "";

  code2FA: string = "";

  $refs!: {
    accountform: InstanceType<typeof ValidationObserver>;
    passwordchangeform: InstanceType<typeof ValidationObserver>;
    twofaenableform: InstanceType<typeof ValidationObserver>;
    twofadisableform: InstanceType<typeof ValidationObserver>;
  };

  get hasPwRepeat(): boolean {
    if (!this.pwNew) return false;
    return this.pwNew === this.pwNewRepeat;
  }

  get hasSecurePw(): boolean {
    if (!this.hasPwRepeat) return false;
    return this.getPwStrength !== this.pwBadText;
  }

  get getPwStrength(): string {
    if (this.strongRegex.test(this.pwNew)) {
      return this.pwStrongText;
    } else if (this.mediumRegex.test(this.pwNew)) {
      return this.pwOkText;
    } else {
      return this.pwBadText;
    }
  }

  get getPwInfoColor(): string {
    let strength: string = this.getPwStrength;
    if (strength === this.pwStrongText) return "success";
    else if (strength === this.pwOkText) return "warning";
    else return "danger";
  }

  get getUserPicturePath() {
    return '/api/v1/users/' + this.loggedInUserId + '/picture';
  }

  get getUser2FAQrCode() {
    return '/api/v1/users/' + this.loggedInUserId + '/twofactorcode';
  }

  get extern(): boolean {
    return Boolean(this.getUserConfigValues.find(config => Boolean(config.id === 'external.authentication' && config.value === 'true')));
  }

  enable2FA(): Promise<any> | void {
    let twoFARequest: TwoFactorRequest = new TwoFactorRequest();
    twoFARequest.code = this.code2FA;
    twoFARequest.password = this.pwCurrent;
    twoFARequest.enabled = true;
    return this.CHANGE_TWO_FACTOR_CONFIG({
      userId: this.loggedInUserId as string,
      config: twoFARequest
    }).then(() => {
        this.$bvModal.hide('twoFAEnableModal');
        this.$snotify.success(this.$pgettext("notification", "Two-factor authentication enabled"));
        Object.assign(this.user, this.getUser);
      },
      error => {
        this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not enable two-factor authentication"));
      });
  }

  disable2FA(): Promise<any> | void {
    let twoFARequest: TwoFactorRequest = new TwoFactorRequest();
    twoFARequest.code = this.code2FA;
    twoFARequest.enabled = false;
    return this.CHANGE_TWO_FACTOR_CONFIG({
      userId: this.loggedInUserId as string,
      config: twoFARequest
    }).then(() => {
        this.$bvModal.hide('twoFADisableModal');
        this.$snotify.success(this.$pgettext("notification", "Two-factor authentication disabled"));
        Object.assign(this.user, this.getUser);
      },
      error => {
        this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not disable two-factor authentication"));
      });
  }

  updatePassword(): Promise<any> | void {
    let changeRequest: PasswordChangeRequest = new PasswordChangeRequest();
    changeRequest.oldPassword = this.pwCurrent;
    changeRequest.newPassword = this.pwNew;
    changeRequest.newPasswordRepeat = this.pwNewRepeat;
    return this.UPDATE_USER_PASSWORD({
      userId: this.loggedInUserId as string,
      request: changeRequest
    }).then(() => {
        this.$bvModal.hide('changePwModal');
        this.$snotify.success(this.$pgettext("notification", "Password successfully changed"));
        Object.assign(this.user, this.getUser);
      },
      error => {
        this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not change password"));
      });
  }

  async validateThenUpdate(): Promise<any> {
    if (await this.$refs.accountform.validate()) {
      return this.REPLACE_USER({ userId: this.loggedInUserId as string, user: this.user }).then(() => {
          this.$snotify.success(this.$pgettext("notification", "Your account has been updated"));
        },
        error => {
          this.$snotify.error(error.response.data.message,
            this.$pgettext("notification", "Could not update your account"));
        }
      );
    } else {
      this.$snotify.error(
        await validationErrors(this.$refs.accountform)
      );
    }
  }

  async setPicture(image: File): Promise<any> {
    if (!image || !this.user.uid) return;
    if (this.isFileImage(image)) {
      this.loading = true;
      return this.SET_PROFILE_PICTURE({ userId: this.user.uid, file: image }).then(() => {
          this.$snotify.success(this.$pgettext("notification", "Profile picture uploaded successfully"));
          this.image = null;
          this.GET_IMAGE(this.getUserPicturePath).then(() => {
              this.loading = false;
            },
            () => {
              this.loading = false;
            });
        },
        error => {
          this.$snotify.error(error.response.data.message,
            this.$pgettext("notification", "Could not upload picture"));
          this.loading = false;
        }
      );
    } else {
      this.$snotify.error(this.$pgettext("notification", "No image found."));
    }
  }

  isFileImage(file: File) {
    return file && this.acceptedImageTypes.includes(file['type']);
  }

  logout() {
    return this.LOGOUT_REQUEST().then(() => {
      if (this.$router.currentRoute.path !== "/") this.$router.replace("/");
      this.$snotify.success(this.$pgettext("notification", "Logged out."));
    });
  }

  created() {
    this.origUser = this.getUser;
    this.user = new User();
    Object.assign(this.user, this.getUser);
    this.GET_USER_CONFIG_VALUES();
  }
};
