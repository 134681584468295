import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios/index";
import MailConfig from "@/model/MailConfig";
import MailAlias from "@/model/MailAlias";

const url: string = '/api/v1/mailconfig';

@Module
export default class MailConfigStore extends VuexModule {
  mailConfig: MailConfig | null = null;
  aliases: MailAlias[] = [];
  domains: string[] = [];
  loading: boolean = false;

  get getMailConfig () : MailConfig | null {
    return this.mailConfig;
  }

  get getAliases () : MailAlias[] {
    return this.aliases;
  }

  get getConfigMailDomains () : string[] {
    return this.domains;
  }

  @Mutation
  setMailConfig (mailConfig: MailConfig) {
    this.mailConfig = mailConfig;
  }

  @Mutation
  setAliases (aliases: any) {
    this.aliases = [];
    for (let [key, value] of Object.entries(aliases)) {
      this.aliases.push({ id: key, recipients: value as string[] });
    }
  }

  @Mutation setMailConfigIsLoading (loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  deleteAlias (aliasId: string) {
    let toDelete = this.aliases.find((alias: MailAlias) => {
      return alias.id === aliasId;
    });

    if (toDelete !== undefined) {
      let index = this.aliases.indexOf(toDelete);
      if (index > -1) {
        this.aliases.splice(index, 1);
      }
    }
  }

  @Mutation
  setDomains (domains: string[]) {
    this.domains = domains;
  }

  @Mutation
  deleteDomain (domain: string) {
    const index = this.domains.indexOf(domain);
    if (index > -1) {
      this.domains.splice(index, 1);
    }
  }

  @Action({ commit: 'setMailConfig', rawError: true })
  GET_MAIL_CONFIG() {
    this.context.commit("setMailConfigIsLoading", true);
    return axios.get(url).then((response) => {
      this.context.commit("setMailConfigIsLoading", false);
      return Object.assign(new MailConfig(), response.data);
    });
  }

  @Action({ commit: 'setMailConfig', rawError: true })
  SET_MAIL_CONFIG(mailConfig: MailConfig) {
    return axios.post(url, mailConfig).then((response) => {
      return Object.assign(new MailConfig(), response.data);
    });
  }

  @Action({ commit: 'setAliases', rawError: true })
  GET_ALIASES() {
    return axios.get(url + '/aliases').then((response) => {
      return response.data;
    });
  }

  @Action({ commit: 'setAliases', rawError: true })
  ADD_ALIAS({ alias, recipients }: { alias: string, recipients: string[] }) {
    const encodedAlias = encodeURIComponent(alias);
    return axios.post(url + '/aliases/' + encodedAlias, recipients).then((response) => {
      return response.data;
    });
  }

  @Action({ commit: 'deleteAlias', rawError: true })
  DELETE_ALIAS(alias: string) {
    const encodedAlias = encodeURIComponent(alias);
    return axios.delete(url + '/aliases/' + encodedAlias).then((response) => {
      this.context.dispatch('GET_ALIASES');
      return alias;
    });
  }

  @Action({ commit: 'setDomains', rawError: true })
  GET_MAIL_DOMAINS() {
    return axios.get(url + '/domains').then((response) => {
      return response.data;
    });
  }

  @Action({ commit: 'setDomains', rawError: true })
  ADD_MAIL_DOMAIN(domain: string) {
    const encodedDomain = encodeURIComponent(domain);
    return axios.post(url + '/domains/' + encodedDomain).then((response) => {
      return response.data;
    });
  }

  @Action({ commit: 'deleteDomain', rawError: true })
  DELETE_MAIL_DOMAIN(domain: string) {
    const encodedDomain = encodeURIComponent(domain);
    return axios.delete(url + '/domains/' + encodedDomain).then((response) => {
      this.context.dispatch('GET_DOMAINS');
      return domain;
    });
  }
}
