








import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class LoadingButton extends Vue {
  @Prop(String) variant!: string;
  @Prop(String) addClasses!: string;
  @Prop(Function) action!: (any: any) => Promise<any>;
  @Prop() arguments!: any;

  loading: boolean = false;
  hideText: boolean = false;
  type: string = "button";

  get classes (): string {
    return (this.addClasses ? this.addClasses : '') + (this.loading ? ' loading' : '') + (this.hideText ? ' hide-text' : '');
  }

  callRegisteredAction () {
    if (!this.loading) { //Prevent additional clicks!
      this.loading = true;
      this.hideText = true;
      let func = this.action;
      let promise: Promise<any> = func(this.arguments);
      if (promise) {
        promise.then((successObject: any) => {
          this.loading = false;
          setTimeout(() => {
            this.hideText = false;
          }, 400);

          if (!successObject) {
            return;
          }

          if (successObject.hasOwnProperty("redirect") && typeof successObject.redirect === "string") {
            this.$router.push(successObject.redirect);
          }

          if (successObject.hasOwnProperty("callback") && typeof successObject.callback === "function") {
            successObject.callback();
          }
        }, (errorObject: any) => {
          this.loading = false;
          setTimeout(() => {
            this.hideText = false;
          }, 400);

          //Test if we caught a platform error:
          if (errorObject instanceof Error) {
            throw errorObject;
          }

          if (!errorObject) {
            return;
          }

          if (errorObject.hasOwnProperty("redirect") && typeof errorObject.redirect === "string") {
            this.$router.push(errorObject.redirect);
          }

          if (errorObject.hasOwnProperty("callback") && typeof errorObject.callback === "function") {
            errorObject.callback();
          }
        });
      } else {
        this.loading = false;
        setTimeout(() => {
          this.hideText = false;
        }, 400);
      }
    }
  }
};
