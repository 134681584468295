



















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from "vuex-class";
import WallpaperEntity from "@/model/WallpaperEntity";
import LoadingImage from "@/components/LoadingImage.vue";
@Component({
  components: { LoadingImage }
})
export default class WallpaperThumbnail extends Vue {
  @Prop(Object) wallpaper!: WallpaperEntity;
  @Prop(String) rule!: string;

  @Action SET_USER_WALLPAPER!: (id: string) => Promise<any>;
  @Action DELETE_WALLPAPER!: (id: string) => Promise<any>;
  @Action GET_USER_CONFIG_VALUES!: () => Promise<any>;

  setAsCurrent() {
    if (!this.wallpaper || !this.wallpaper.name) return;
    this.SET_USER_WALLPAPER(this.wallpaper.name).then(() => {
      this.$snotify.success(this.$pgettext("notification", "Successfully set wallpaper."));
    }).catch((error) => {
      this.$snotify.error(error.response.data.message,
        this.$pgettext("notification", "Could not set wallpaper."));
    }).finally(() => {
      this.GET_USER_CONFIG_VALUES();
    });
  }

  deleteWallpaper() {
    if (!this.wallpaper || !this.wallpaper.name) return;
    this.DELETE_WALLPAPER(this.wallpaper.name).catch((error) => {
      this.$snotify.error(error.response.data.message,
        this.$pgettext("notification", "Could not delete wallpaper."));
    }).finally(() => {
      this.GET_USER_CONFIG_VALUES();
    });
  }
};
