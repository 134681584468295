





































































































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import StoreApp from "@/model/StoreApp";
import { Action, Getter } from "vuex-class";
import InvoiceContact from "@/model/InvoiceContact";
import User from "@/model/User";
import LoadingButton from "@/components/LoadingButton.vue";
import SelectableList from "@/components/SelectableList.vue";
import LoadingImage from "../components/LoadingImage.vue";
import { FormWizard } from "vue-form-wizard";
import { BModal } from "bootstrap-vue";
import App from "@/model/App";
import DomainGridItem from "@/components/DomainGridItem.vue";
import Group from "@/model/Group";
import Subscription from "@/model/invoice/Subscription";
import SubscriptionUtils from "@/util/SubscriptionUtils";
import ConfigValue from "@/model/ConfigValue";
import ProductMapping from "@/model/invoice/ProductMapping";
import StoreAppUtils from "@/util/StoreAppUtils";
import UserUtils from "@/util/UserUtils";
import ArrayCompare from "@/util/ArrayCompare";
import SelectableGroup from "@/util/SelectableGroup";
import SubscriptionUpgrade from "@/model/SubscriptionUpgrade";

@Component({
  components: { DomainGridItem, LoadingButton, SelectableList, LoadingImage }
})
export default class UpdateGroupWizard extends Vue {
  @Prop(String) groupDn!: string;
  @Prop(Array) users!: string[];

  @Getter getInvoiceContact!: InvoiceContact | null;
  @Getter getGroups!: Group[];
  @Getter getApps!: App[];
  @Getter getStoreApps!: StoreApp[];
  @Getter getUsers!: User[];
  @Getter loggedInIsSigning!: boolean;
  @Getter getPaymentInterval!: number | null;
  @Getter getNextPayment!: number | null;
  @Getter getSystemConfigValues!: ConfigValue[];
  @Action UPDATE_GROUP!: ({ cn, group }: { cn: string, group: Group }) => Promise<Group>;
  @Action PURCHASE_SERVICE!:({ planId, userCount }: { planId: string, userCount: number }) => Promise<any>;
  @Action UPGRADE_SERVICE!:({ subscriptionId, planId, userCount }: { subscriptionId: number, planId: string, userCount: number }) => Promise<any>;
  @Action INSTALL_APP!: ({ storeId, version, options }: { storeId: string, version: string | null, options: { users: string[] }}) => Promise<any>;
  @Action UPDATE_APP!: ({ instanceId, version, options }: { instanceId: string, version: string | null, options: { users: string[] | null }}) => Promise<any>;
  @Action GET_STORE_APPS!: () => Promise<any>;
  @Action GET_USERS!: () => Promise<User[]>;
  @Action GET_GROUPS!: () => Promise<Group[]>;

  @Ref('upgrademodal') upgadeModal!: BModal;
  @Ref('upgradeWizard') upgradeWizard!: FormWizard;

  selectedUsersInternal: string[] = [];
  selectedGroups: any = {};
  subsUtil: SubscriptionUtils = new SubscriptionUtils();

  step: number = 0;
  buyWizardTitles: string[] = [
    this.$pgettext("title", "Payment Method"),
    this.$pgettext("title", "Edit Group"),
    this.$pgettext("title", "Confirm Order"),
    this.$pgettext("title", "Congratulations")
  ];

  isFirstTab(props: any): boolean {
    return props.activeTabIndex === 0;
  }

  get getUsersWithRequired(): { dn: string | null, user: User, disabled: boolean }[] {
    return this.getUsers.map(user => {
      let disabled: boolean = Boolean(user.dn &&
          UserUtils.userIsExternal(user.dn, this.getSystemConfigValues) &&
          UserUtils.groupIsMappedToExternalGroup(this.groupDn, this.getSystemConfigValues));

      return { dn: user.dn, user: user, disabled: disabled };
    });
  }

  get group(): Group | null {
    if (this.groupDn && this.getGroups) {
      return this.getGroups.find(group => group.dn === this.groupDn) || null;
    } else {
      return null;
    }
  }

  get installedApp(): App | null {
    return this.getApps.find(app => Boolean(this.group && this.group.dn && app.userGroup === this.group.dn)) || null;
  }

  get storeApp(): StoreApp | null {
    if (this.installedApp && this.installedApp.storeApp && this.installedApp.storeApp.id && this.getStoreApps) {
      const storeAppId: string = this.installedApp.storeApp.id;
      return this.getStoreApps.find(storeApp => storeApp.id === storeAppId) || null;
    } else {
      return null;
    }
  }

  get nextPaymentAsDateString(): string | null {
    let nextPayment: number | null = this.getNextPayment;
    if (nextPayment) {
      return new Date(nextPayment).toLocaleDateString();
    } else {
      return null;
    }
  }

  get addons(): { id: string, title: string, description: string }[] {
    return StoreAppUtils.getAddons(this.storeApp, this.$gettext);
  }

  get storageClasses(): { id: string, title: string, description: string, required: boolean }[] {
    return StoreAppUtils.getStorageClasses(this.storeApp, this.$gettext);
  }

  get permissions(): { id: string, title: string, description: string }[] {
    return StoreAppUtils.getPermissions(this.storeApp, this.$gettext);
  }

  get seatsToBuy(): SubscriptionUpgrade[] {
    return this.subsUtil.seatsRequired(this.storeApp, this.getUsers.length, this.selectedUsers, this.selectedGroups, this.getPaymentInterval);
  }

  get consolidatedSubscriptionUpdate(): SubscriptionUpgrade | null {

    let stb = [...this.seatsToBuy];

    if (stb.length === 0) {
      return null;
    }

    stb.sort((a: SubscriptionUpgrade, b: SubscriptionUpgrade) => {
      return a.price - b.price;
    });

    let chosenSub: SubscriptionUpgrade = Object.assign(new SubscriptionUpgrade(), stb[0]);
    let seatsSum: number = chosenSub.seatsToBuy;
    for (let i = 1; i < stb.length; i++) {
      seatsSum -= stb[i].seatsActive;
    }

    chosenSub.seatsToBuy = Math.max(seatsSum, 0);

    return chosenSub;
  }

  get totalSeatsActive(): number {
    let totalSeatsActive: number = 0;
    for (let s of this.seatsToBuy) {
      totalSeatsActive += s.seatsActive;
    }
    return totalSeatsActive;
  }

  get totalSeatsToBuy(): number {
    if (this.consolidatedSubscriptionUpdate) {
      return this.consolidatedSubscriptionUpdate.seatsToBuy;
    } else {
      return 0;
    }
  }

  get selectedCount(): number {
    if (this.selectedUsers) {
      return this.selectedUsers.length;
    }
    return 0;
  }

  get needsUpgrade(): boolean {
    if (this.consolidatedSubscriptionUpdate) {
      return this.subsUtil.isPaidApp(this.storeApp, null) && this.consolidatedSubscriptionUpdate.seatsToBuy > 0;
    } else {
      return false;
    }
  }

  get secondaryAppGroupOptions(): SelectableGroup[] | null {
    return StoreAppUtils.getSecondaryAppGroupsOfInstalledApp(this.installedApp, this.getGroups);
  }

  get groupsToUpdate(): Group[] {
    const groups: Group[] = [];
    if (this.group && this.group.cn && !ArrayCompare.equals(this.selectedUsers, this.group.uniqueMember)) {
      const group = { ...this.group };
      group.uniqueMember = this.selectedUsers;
      groups.push(group);
    }
    const appGroups: SelectableGroup[] = StoreAppUtils.getSecondaryAppGroupsOfInstalledApp(this.installedApp, this.getGroups) || [];
    for (const nameAndGroup of appGroups) {
      const uniqueMember: string[] = [];
      for (const dn of this.selectedUsers) {
        if (Array.isArray(this.selectedGroups[dn]) && this.selectedGroups[dn].find((g: SelectableGroup) => g.id === nameAndGroup.id)) {
          uniqueMember.push(dn);
        }
      }
      if (nameAndGroup.group && nameAndGroup.group.cn && !ArrayCompare.equals(uniqueMember, nameAndGroup.group.uniqueMember)) {
        const group = { ...nameAndGroup.group };
        group.uniqueMember = uniqueMember;
        groups.push(group);
      }
    }
    return groups;
  }

  get selectedUsers(): string[] {
    const selectedUsers = [...this.selectedUsersInternal];
    const requiredGroups: SelectableGroup[] = (this.secondaryAppGroupOptions || []).filter(o => o.required);
    for (const user of this.getUsers) {
      if (user.dn) {
        if (!this.selectedGroups[user.dn] || !Array.isArray(this.selectedGroups[user.dn])) {
          this.$set(this.selectedGroups, user.dn, []);
        }
        for (let requiredGroup of requiredGroups) {
          if (!this.selectedGroups[user.dn].find((g: SelectableGroup) => g.id === requiredGroup.id)) {
            this.selectedGroups[user.dn].push(requiredGroup);
          }
        }
        if (selectedUsers.indexOf(user.dn) < 0 && this.selectedGroups[user.dn].length > 0) {
          selectedUsers.push(user.dn);
        }
      }
    }
    return selectedUsers;
  }

  set selectedUsers(selectedUsers: string[]) {
    this.selectedUsersInternal = selectedUsers;
    const requiredGroups: SelectableGroup[] = (this.secondaryAppGroupOptions || []).filter(o => o.required);
    for (const user of this.getUsers) {
      if (user.dn && selectedUsers.indexOf(user.dn) < 0) {
        this.$set(this.selectedGroups, user.dn, []);
      } else if (user.dn) {
        if (!this.selectedGroups[user.dn] || !Array.isArray(this.selectedGroups[user.dn])) {
          this.$set(this.selectedGroups, user.dn, []);
        }
        for (let requiredGroup of requiredGroups) {
          if (this.selectedGroups[user.dn] && !this.selectedGroups[user.dn].find((g: SelectableGroup) => g.id === requiredGroup.id)) {
            this.selectedGroups[user.dn].push(requiredGroup);
          }
        }
      }
    }
  }

  show(): void {
    this.upgadeModal.show();
  }

  reset(): void {
    this.upgradeWizard.reset();
    this.upgadeModal.hide();
  }

  setUpgradeWizardStep(current: number, next: number) {
    this.step = next;
  }

  get title(): string {
    if (!this.subsUtil.hasPaymentMethod(this.getInvoiceContact) && this.subsUtil.isPaidApp(this.storeApp, this.installedApp)) {
      return this.buyWizardTitles[0];
    } else {
      return this.buyWizardTitles[this.step + 1];
    }
  }

  get totalAmount(): number | null {
    if (this.consolidatedSubscriptionUpdate) {
      return this.consolidatedSubscriptionUpdate.seatsToBuy * this.consolidatedSubscriptionUpdate.price * (1 + this.consolidatedSubscriptionUpdate.taxRate / 10000);
    } else {
      return 0;
    }
  }

  redirectToCustomerCenter() {
    if (this.getInvoiceContact && this.getInvoiceContact.portalURL) {
      window.open(this.getInvoiceContact.portalURL, '_blank');
    } else {
      this.$snotify.error(this.$pgettext("notification", "Could not get customer center url."));
    }
  }

  upgradeApp(): Promise<any> {
    const storeApp: StoreApp | null = this.storeApp;
    if (storeApp && this.needsUpgrade) {
      const promises: Promise<any>[] = [];
      let toUpgrade = this.consolidatedSubscriptionUpdate;
      if (toUpgrade) {
        if (toUpgrade.seatsToBuy > 0 && toUpgrade.subscription && toUpgrade.subscription.id && toUpgrade.productMapping && toUpgrade.productMapping.product && toUpgrade.productMapping.product.id) {
          promises.push(this.UPGRADE_SERVICE({
            subscriptionId: toUpgrade.subscription.id, planId: toUpgrade.productMapping.product.id, userCount: toUpgrade.seatsRequired
          }));
        } else if (toUpgrade.seatsToBuy > 0 && toUpgrade.product && toUpgrade.product.id) {
          promises.push(this.PURCHASE_SERVICE({
            planId: toUpgrade.product.id, userCount: toUpgrade.seatsRequired
          }));
        }
      }
      return new Promise((resolve, reject) => {
        Promise.all(promises).then(() => {
          this.$snotify.success(this.$pgettext("notification", "Updated subscription."));
        }).catch((error: { response: { data: { message: string; } } }) => {
          reject(error);
          this.$snotify.error(error.response.data.message, this.$pgettext("notification", "Subscription was not updated."));
        }).finally(() => {
          this.saveGroupToServer().finally(() => {
            resolve();
            this.GET_STORE_APPS();
          });
        });
      });
    } else {
      return this.saveGroupToServer();
    }
  }

  saveGroupToServer(): Promise<void> {
    if (this.group && this.group.cn) {
      return Promise.all(this.groupsToUpdate.map((group: Group) => {
        return this.UPDATE_GROUP({ cn: group.cn || '', group: group });
      })).then(() => {
        this.$snotify.success(this.$pgettext("notification", "Group updated."));
      }).catch(error => {
        this.$snotify.error(error.response.data.message, this.$pgettext("notification", "Could not update group"));
      }).finally(() => {
        this.reset();
        this.GET_USERS(); //Update group membership information
        this.GET_GROUPS(); //Update group membership information
      });
    } else {
      this.$snotify.error(this.$pgettext("notification", "Could not load group."));
      return Promise.resolve();
    }
  }

  updateGroupMemberships(): void {
    //Must be set after props are set
    const appGroups: SelectableGroup[] = StoreAppUtils.getSecondaryAppGroupsOfInstalledApp(this.installedApp, this.getGroups) || [];
    for (const user of this.getUsers) {
      if (user.dn) {
        const groupsOfUser: SelectableGroup[] = [];
        for (const appGroup of appGroups) {
          if (appGroup.group && appGroup.group.uniqueMember && appGroup.group.uniqueMember.indexOf(user.dn) >= 0) {
            groupsOfUser.push(appGroup);
          }
        }
        this.$set(this.selectedGroups, user.dn, groupsOfUser);
      }
    }
    //Set this after secondary group memberships have been loaded!
    this.selectedUsers = this.group ? (this.group.uniqueMember || []) : [];
  }

  mounted() {
    this.updateGroupMemberships();
  }
};
