import USBStoragePartition from '../../model/backup/USBStoragePartition';
import BackupDevice from "../../model/backup/BackupDevice";

export default class USBStorageDrive extends BackupDevice {
  deviceName!: string | null;
  model!: string | null;
  serial!: string | null;
  sizeInKBytes!: number | null;
  partitions!: USBStoragePartition[] | null;

  constructor() {
    super();
    this.deviceName = null;
    this.model = null;
    this.serial = null;
    this.sizeInKBytes = null;
    this.partitions = null;
  }
}
