export default class Timers {

  static STATUS: string = 'STATUS'
  static MESSAGES: string = 'MESSAGES'
  static SYSTEM: string = 'SYSTEM'
  static SHARES: string = 'SHARES'
  static SYNC_JOBS: string = 'SYNC_JOBS'

  static timers: Map<string, number> = new Map<string, number>();

  static statusTimer: number | null;
  static messageTimer: number | null;
  static systemTimer: number | null;
  static sharesTimer: number | null;
  static syncJobsTimer: number | null;

  static setInterval(id: string, handler: TimerHandler, timeout?: number): void {
    if (!this.timers.get(id)) {
      this.timers.set(id, setInterval(handler, timeout));
    }
  }

  static clearInterval(id: string): void {
    let timer: number | undefined = this.timers.get(id);
    if (timer) {
      clearInterval(timer);
      this.timers.delete(id);
    }
  }
}
