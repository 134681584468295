











































































































































































import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";

class UiLicense {
  repository!: string;
  name!: string;
  version!: string;
  licenseText!: string;
}

@Component({
  components: {}
})
export default class Licenses extends Vue {
  @Getter getSystemLicenses!: string;
  @Action GET_SYSTEM_LICENSES!: () => Promise<any>;
  uiLicenses: UiLicense[] = [];
  isBackEndChecked: boolean = true;

  repositoryTxt = this.$gettext("Repository");
  homepageTxt = this.$gettext("Hompage");

  get getIsLoadingBackEnd(): boolean {
    if (this.getSystemLicenses) return false;
    return true;
  }

  get getIsLoadingFrontEnd(): boolean {
    if (this.uiLicenses) return false;
    return true;
  }

  setShowBackendLicenses(showBackend: boolean): void {
    this.isBackEndChecked = showBackend;
  }

  created() {
    this.GET_SYSTEM_LICENSES();
    import('../assets/ui-license.json').then((licenses: UiLicense[]) => {
      this.uiLicenses = licenses;
    });
  }
};
