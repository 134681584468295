





































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter, Mutation } from "vuex-class";
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import PasswordResetRequest from "@/model/PasswordResetRequest";
import User from '../model/User';
import type = Mocha.utils.type;

@Component({
  components: { AnimatedInput, LoadingButton }
})
export default class PasswordReset extends Vue {
  @Action RESET_USER_PASSWORD!: ({ userId, request }: { userId: string, request: PasswordResetRequest }) => Promise<any>;

  pukPlaceholder: string = this.$pgettext("placeholder", "Your PUK");
  pwPlaceholder: string = this.$pgettext("placeholder", "Your new password");
  pwRepeatPlaceholder: string = this.$pgettext("placeholder", "Repeat your new password");

  strongRegex: RegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})");
  //at least 8 chars, lower/upper case letters und numbers
  mediumRegex: RegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

  pwStrongText: string = this.$pgettext("password-strength-info", "Password strength: Strong");
  pwOkText: string = this.$pgettext("password-strength-info", "Password strength: OK");
  pwBadText: string = this.$pgettext("password-strength-info", "Password strength: Bad");

  userPuk: string | null = null;
  hasPuk: boolean = false;
  newPw: string = "";
  newPwRepeat: string = "";

  get canSend(): boolean {
    if (this.newPw) return this.newPw === this.newPwRepeat;
    return false;
  }

  get bothPwAreEntered(): boolean {
    return this.newPw !== "" && this.newPwRepeat !== "";
  }

  get needsToEnterPuk(): boolean {
    return !this.$route.query.pin && !this.hasPuk;
  }

  markHasNoPuk(): void {
    this.hasPuk = false;
    this.userPuk = "";
  }

  markHasPuk(): void {
    this.hasPuk = true;
  }

  sendResetRequest(): Promise<any> {
    if (!this.uid || !this.token) {
      return Promise.reject(new Error());
    }

    let resetRequest: PasswordResetRequest = new PasswordResetRequest();
    resetRequest.token = this.token;
    resetRequest.pin = this.pin;
    resetRequest.newPassword = this.newPw;
    resetRequest.newPasswordRepeat = this.newPwRepeat;

    return this.RESET_USER_PASSWORD({
      userId: this.uid,
      request: resetRequest
    }).then(() => {
      this.$snotify.success(this.$pgettext("notification", "Password changed"));
      //Reload page for groupware:
      window.location.href = window.location.protocol + "//" + window.location.host + "/";
    },
    error => {
      this.$snotify.error(this.$pgettext("server_message", error.response.data.message),
      this.$pgettext("notification", "Could not send reset request"));
    });
  }

  get getPwStrength(): string {
    if (this.strongRegex.test(this.newPw)) {
      return this.pwStrongText;
    } else if (this.mediumRegex.test(this.newPw)) {
      return this.pwOkText;
    } else {
      return this.pwBadText;
    }
  }

  get getPwInfoColor(): string {
    let strength: string = this.getPwStrength;
    if (strength === this.pwStrongText) return "success";
    else if (strength === this.pwOkText) return "warning";
    else return "danger";
  }

  get uid(): string | null{
    return this.$route.query.uid as string || null;
  }

  get token(): string | null {
    return this.$route.query.token as string || null;
  }

  get pin(): string | null{
    return this.userPuk || this.$route.query.pin as string || null;
  }

  set pin(puk: string | null) {
    this.userPuk = puk;
  }

  beforeUpdate() {
    if (this.userPuk === "" && this.pin) {
      this.userPuk = this.pin || "";
    }
  }

};
