import { render, staticRenderFns } from "./HostedAppGridItem.vue?vue&type=template&id=327694d1&scoped=true&"
import script from "./HostedAppGridItem.vue?vue&type=script&lang=ts&"
export * from "./HostedAppGridItem.vue?vue&type=script&lang=ts&"
import style0 from "./HostedAppGridItem.vue?vue&type=style&index=0&id=327694d1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327694d1",
  null
  
)

export default component.exports