




































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";
import { MigrationStatus } from "@/util/migrationStatus";
import User from "@/model/User";
import Message from "@/model/Message";
import SystemInformation from "@/model/SystemInformation";

@Component({
  components: {
  }
})
export default class MigrationDisplay extends Vue {

  @Getter currentMigrationState!: MigrationStatus;
  @Getter getUser!: User | null;
  @Getter hasMigrationStatus!: boolean
  @Getter hasAnyMigrationStatus!: boolean
  @Getter getSystem!: SystemInformation | null;

  get groupwareIsEnabled(): boolean {
    const system = this.getSystem;
    return Boolean(system && system.hasOwnProperty('groupware') && system.groupware !== null && system.groupware);
  }

  get migrationStatus(): MigrationStatus {
    return this.currentMigrationState;
  }

  getMigrationStatusForComponent(component: string): string {
    let stateString = "";
    if (this.migrationStatus && this.migrationStatus['migration_state']) {
      const state = this.migrationStatus['migration_state'];
      stateString = state[component];
    }
    return stateString;
  }

  getHasMigrationForComponent(component: string): boolean {
    const stateString = this.getMigrationStatusForComponent(component);
    return stateString === 'RUNNING' || stateString === 'PENDING';
  }

  getMigrationStatusTranslatedForComponent(component: string): string {
    const stateString = this.getMigrationStatusForComponent(component);
    return this.getTranslatedMigrationStatus(stateString);
  }

  getMigrationStatusCodeForComponent(component: string): string {
    const stateString = this.getMigrationStatusForComponent(component);
    return this.getMigrationStatusCode(stateString);
  }

  getMigrationStatusCode(status: string): string {
    let statusCode = "info";
    switch (status) {
      case "PENDING":
        statusCode = "badge-warning";
        break;
      case "RUNNING":
        statusCode = "badge-info";
        break;
      case "DONE":
        statusCode = "badge-success";
        break;
      case "FAILED":
        statusCode = "badge-danger";
        break;
    }
    return statusCode;
  }

  getTranslatedMigrationStatus(status: string): string {
    let translated = "Unbekannt";
    switch (status) {
      case "PENDING":
        translated = "Ausstehend";
        break;
      case "RUNNING":
        translated = "Läuft";
        break;
      case "DONE":
        translated = "Abgeschlossen";
        break;
      case "FAILED":
        translated = "Fehlgeschlagen";
        break;
    }
    return translated;
  }

  getMigrationComponentIcon(component: string): string {
    switch (component) {
      case "wekan":
        return "cil-task cil-fw";
      case "rocketchat":
        return "cil-chat-square cil-fw";
      case "caldav":
        return "cil-calendar cil-fw";
      case "carddav":
        return "cil-group cil-fw";
      case "imap":
        return "cil-mail cil-fw";
    }
    return "cil-applications cil-fw";
  }

  get migrationsToDo(): { title: string, key: string }[] {

    const availableMigrations = [
      {
        title: "Wekan zu Uniki Aufgaben",
        key: "wekan"
      },
      {
        title: "RocketChat zu Uniki Chat",
        key: "rocketchat"
      },
      {
        title: "Kopano Kalender zu Uniki Kalender",
        key: "caldav"
      },
      {
        title: "Kopano Kontakte zu Uniki Kontakte",
        key: "carddav"
      },
      {
        title: "Kopano E-Mails zu Uniki Mail",
        key: "imap"
      }
    ];

    const actualMigrations = [];

    if (this.migrationStatus && this.migrationStatus.migration_state) {
      const options = this.migrationStatus.migration_state;
      for (const [key, value] of Object.entries(options)) {
        const optionName = key;
        const matchingMigration = availableMigrations.find((mig: { title: string, key: string }) => {
          return mig.key === optionName;
        });
        if (matchingMigration) {
          actualMigrations.push(matchingMigration);
        }
      }
    }

    return actualMigrations;
  }

  get myMailMigrationStatus(): string {
    let result = "";
    const myUsername = this.userName;
    if (this.migrationStatus && this.migrationStatus['imap_state']) {
      const imap_state = this.migrationStatus['imap_state'];
      for (const [key, value] of Object.entries(imap_state)) {
        if (key === myUsername) {
          result = value as string;
        }
      }
    }
    return result;
  }

  get myMailMigrationStatusTranslated(): string {
    const status = this.myMailMigrationStatus;
    return this.getTranslatedMigrationStatus(status);
  }

  get myMailMigrationStatusCode(): string {
    const status = this.myMailMigrationStatus;
    return this.getMigrationStatusCode(status);
  }

  get userName(): string {
    if (this.getUser) {
      return this.getUser.uid || "";
    } else {
      return "";
    }
  }
}
